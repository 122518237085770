import { gql } from "@apollo/client";

export const WeaponConfigCreateQGL = gql`
  mutation WeaponConfigCreate(
    $id: String!
    $weaponType: String!
    $weaponSkin: String!
    $nameCounterID: String!
    $season: String!
    $dropType: String!
    $name: String!
    $rarity: Int!
    $skins: [WeaponConfigSkinInput!]
    $minMintLevel: Int!
    $render: WeaponConfigRenderInput
    $randomization: WeaponConfigRandomizationInput!
    $isStandard: Boolean!
  ) {
    weaponConfigCreate(
      input: {
        config: {
          id: $id
          name: $name
          weaponType: $weaponType
          weaponSkin: $weaponSkin
          nameCounterID: $nameCounterID
          season: $season
          dropType: $dropType
          rarity: $rarity
          minMintLevel: $minMintLevel
          skins: $skins
          render: $render
          randomization: $randomization
          isStandard: $isStandard
        }
      }
    ) {
      ok
    }
  }
`;
