import { GetUser_lookup_accounts_resolve } from "pages/UserProfile/query/__generated__/GetUser";
import MilitaryTechIcon from "@mui/icons-material/MilitaryTech";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import FaceIcon from "@mui/icons-material/Face";
import NoAccountsIcon from "@mui/icons-material/NoAccounts";
import ScheduleIcon from "@mui/icons-material/Schedule";
import VideogameAssetIcon from "@mui/icons-material/VideogameAsset";
import moment from "moment";

const formatter = new Intl.NumberFormat("ru-RU");

export const getStatsData = (data: GetUser_lookup_accounts_resolve) => {
  return [
    {
      label: "Experience",
      value: formatter.format(data.overview.gameAccount.xp),
      icon: <MilitaryTechIcon />,
    },
    {
      label: "Level",
      value: formatter.format(data.overview.gameAccount.level),
      icon: <WorkspacePremiumIcon />,
    },
    {
      label: "Total Kills",
      value: formatter.format(data.overview.gameAccount.kills),
      icon: <StarBorderIcon />,
    },
    {
      label: "Total Deaths",
      value: formatter.format(data.overview.gameAccount.deaths),
      icon: <NoAccountsIcon />,
    },
    {
      label: "Total Headshots",
      value: formatter.format(data.overview.gameAccount.headshots),
      icon: <FaceIcon />,
    },
    {
      label: "Total Games Played",
      value: formatter.format(data.overview.gameAccount.gamesPlayed),
      icon: <VideogameAssetIcon />,
    },
    {
      label: "Total Games Won",
      value: formatter.format(data.overview.gameAccount.gamesWon),
      icon: <EmojiEventsIcon />,
    },
    {
      label: "Total Game Time",
      value: moment
        .utc(data.overview.gameAccount.gameTime * 1000)
        .format("HH:mm:ss"),
      icon: <ScheduleIcon />,
    },
  ];
};
