import { useEffect, useCallback, SetStateAction, Dispatch } from "react";
import Avatar from "@mui/material/Avatar";
import {
  DropdonwContainer,
  Filters,
  StyledButton,
  ListItem,
  SearchListContainer,
  EmptyBlock,
} from "./SearchBar.styles";
import { GetUsers_lookup_accounts_search_items } from "components/SearchBar/query/__generated__/GetUsers";
import { useNavigate } from "react-router-dom";
import {
  playerSearchableFields,
  clanSearchableFields,
  FiltersSelection,
} from "./SearchBar";
import { SearchClans_lookup_clans_search } from "components/SearchBar/query/__generated__/SearchClans";

interface DropDownMenuProps {
  playersSearchResult?: GetUsers_lookup_accounts_search_items[];
  clansSearchResult?: SearchClans_lookup_clans_search[];
  playerFiltersSelection: FiltersSelection;
  clanFiltersSelection: FiltersSelection;
  currentSearch: string;
  loading: boolean;
  setPlayerFiltersSelection: Dispatch<SetStateAction<FiltersSelection>>;
  setClanFiltersSelection: Dispatch<SetStateAction<FiltersSelection>>;
  setFocusActive: Dispatch<SetStateAction<boolean>>;
  setSearch: Dispatch<SetStateAction<string>>;
}

function selectGivenField(
  selection: FiltersSelection,
  fieldToSelect: string
): FiltersSelection {
  const result: FiltersSelection = {};
  Object.keys(selection).forEach((field: string) => {
    result[field] = field === fieldToSelect;
  });
  return result;
}

export const DropDownMenu = ({
  playersSearchResult,
  clansSearchResult,
  currentSearch,
  playerFiltersSelection,
  clanFiltersSelection,
  loading,
  setPlayerFiltersSelection,
  setClanFiltersSelection,
  setSearch,
  setFocusActive,
}: DropDownMenuProps) => {
  const nav = useNavigate();

  // listen for clicks outside this component so we can tell parent to hide
  const onClick = useCallback((event: MouseEvent) => {
    const path =
      (event as any).path || (event.composedPath && event.composedPath());
    const isInsideClick = path.find(
      (item: Element) => item?.id === "search"
    )?.id;
    if (!isInsideClick) {
      setFocusActive(false);
      selectPlayerFilter("username");
    }
  }, []);

  useEffect(() => {
    document.addEventListener("click", onClick);
    return () => {
      document.removeEventListener("click", onClick);
    };
  }, []);

  function selectPlayerFilter(fieldToSelect: string) {
    setPlayerFiltersSelection((prev) => selectGivenField(prev, fieldToSelect));
  }
  function selectClanFilter(fieldToSelect: string) {
    setClanFiltersSelection((prev) => selectGivenField(prev, fieldToSelect));
  }

  const displayedPlayers = currentSearch ? playersSearchResult : [];
  const displayedClans = currentSearch ? clansSearchResult : [];
  console.log("currentSearch", currentSearch);
  console.log("displayedPlayers", displayedPlayers);
  console.log("displayedClans", displayedClans);

  return (
    <DropdonwContainer id="search">
      {/* === THIS IS THE PLAYER RESULTS SECTION === */}
      <Filters>
        <div>Player</div>
        <div>
          {playerSearchableFields.map((item) => (
            <StyledButton
              key={item}
              active={playerFiltersSelection[item]}
              onClick={() => selectPlayerFilter(item)}
            >
              {item}
            </StyledButton>
          ))}
        </div>
      </Filters>
      <SearchListContainer isResult={playersSearchResult?.length}>
        {displayedPlayers?.map((item, i) => (
          <ListItem
            key={i}
            onClick={() => {
              setSearch("");
              nav(`users/${item.userId}`);
              setFocusActive(false);
            }}
          >
            <Avatar src={"/"} sx={{ mr: 2, height: "30px", width: "30px" }} />
            {playerFiltersSelection.username &&
              `${item.ident.username}#${item.ident.suffix}`}
            {playerFiltersSelection.email && `${item.personalInfo.email}`}
            {playerFiltersSelection.discord &&
              `${item.overview.discord?.username || "-"}`}
          </ListItem>
        ))}
      </SearchListContainer>

      {/* === THIS IS THE CLAN RESULTS SECTION === */}
      <Filters>
        <div>Clan</div>
        <div>
          {clanSearchableFields.map((item) => (
            <StyledButton
              key={item}
              active={clanFiltersSelection[item]}
              onClick={() => selectClanFilter(item)}
            >
              {item}
            </StyledButton>
          ))}
        </div>
      </Filters>
      <SearchListContainer isResult={clansSearchResult?.length}>
        {displayedClans?.map((item, i) => (
          <ListItem
            key={i}
            onClick={() => {
              setSearch("");
              nav(`/clans/${item.id}`);
              setFocusActive(false);
            }}
          >
            <Avatar src={"/"} sx={{ mr: 2, height: "30px", width: "30px" }} />
            {`${item.name} (ID: ${item.id})`}
          </ListItem>
        ))}
        {!clansSearchResult?.length && currentSearch && (
          <EmptyBlock>
            {loading ? "Search in progress..." : "Nothing found"}
          </EmptyBlock>
        )}
      </SearchListContainer>
    </DropdonwContainer>
  );
};
