import { GearForm } from "./types";

export const generateStatsData = (): any => ({
  name: {
    variations: [
      {
        name: "Agility (+)",
        weight: 1,
        checked: false
      },
      {
        name: "Agility (%)",
        weight: 1,
        checked: false
      },
      {
        name: "Intellect (+)",
        weight: 1,
        __typename: "Variation",
        checked: false
      },
      {
        name: "Intellect (%)",
        weight: 1,
        checked: false
      },
      {
        name: "Luck (+)",
        weight: 1,
        checked: false
      },
      {
        name: "Luck (%)",
        weight: 1,
        checked: false
      },
      {
        name: "Strength (+)",
        weight: 1,
        checked: false
      },
      {
        name: "Strength (%)",
        weight: 1,
        checked: false
      },
      {
        name: "Toughness (+)",
        weight: 1,
        __typename: "Variation",
        checked: false
      },
      {
        name: "Toughness (%)",
        weight: 1,
        checked: false
      },
      {
        name: "Vitality (+)",
        weight: 1,
        checked: false
      },
      {
        name: "Vitality (%)",
        weight: 1,
        checked: false
      }
    ]
  }
});

export const initForm: GearForm = {
  id: "",
  name: "",
  nameCounterID: "",
  season: "",
  minMintLevel: 0,
  dropType: "",
  randomization: null,
};
