import React from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Grid } from "@mui/material";
import { TabContentWeapons } from "pages/UserProfile/components/TopActions/components/RewardsModal/components/ItemsTabs/TabContentWeapons";
import { TabContentShards } from "pages/UserProfile/components/TopActions/components/RewardsModal/components/ItemsTabs/TabContentShards";

interface ItemsTabsProps {
  onSendItem: (id: string) => Promise<unknown>;
  onSendShard: (id: string, amount: number, admin: string) => Promise<unknown>;
}

export const ItemsTabs = ({ onSendItem, onSendShard }: ItemsTabsProps) => {
  const [value, setValue] = React.useState("weapons");

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  return (
    <Grid item xs={12}>
      <TabContext value={value}>
        <Box sx={{ borderColor: "divider" }} mb={2}>
          <TabList onChange={handleChange}>
            <Tab label="Weapons & Outfits" value="weapons" />
            <Tab label="Shards" value="shards" />
          </TabList>
        </Box>
        <TabPanel sx={{ padding: 0 }} value="weapons">
          <TabContentWeapons onSend={onSendItem} />
        </TabPanel>
        <TabPanel sx={{ padding: 0 }} value="shards">
          <TabContentShards onSend={onSendShard} />
        </TabPanel>
      </TabContext>
    </Grid>
  );
};
