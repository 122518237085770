import { gql } from "@apollo/client";

export const queryGetUser = gql`
  query GetUser($id: UUID!) {
    lookup {
      accounts {
        resolve(input: { userId: $id }) {
          userId
          verified
          ident {
            suffix
            username
          }
          overview {
            clan {
              clan {
                id
                name
              }
            }
            bans {
              active {
                id
                startsAt
                expiresAt
              }
              history {
                id
                startsAt
                expiresAt
              }
            }
            inventory {
              items {
                id
                kind
                overview {
                  name
                  category
                  type
                  prototype
                  progress {
                    level
                    points
                    next_level_points
                    curr_level_points
                  }
                  category
                  content {
                    ... on GamedataItemContent {
                      asset {
                        icon
                      }
                    }
                    ... on GeneratedItemContent {
                      asset {
                        icon
                      }
                    }
                  }
                }
              }
            }
            wallet {
              pubKey
            }
            discord {
              username
            }
            gameAccount {
              xp
              level
              kills
              deaths
              headshots
              gamesPlayed
              gamesWon
              gameTime
            }
          }
          personalInfo {
            email
            timeline {
              createdAt
              retiredAt
              verifiedAt
            }
          }
        }
      }
    }
    lookup {
      offchainWallets {
        allHolderAccounts(input: { holderID: $id }) {
          id
          type
          amount
        }
      }
    }
  }
`;
