import { styled } from "@mui/system";

export const FilterItem = styled("div")<{active: boolean}>(({active}) => ({
  padding: "3px 15px",
  backgroundColor: active ? "gray" : "#fff",
  borderRadius: "4px",
  cursor: "pointer",
  marginRight: "10px",
  fontSize: "14px",

  "&:hover": {
    backgroundColor: "#DCDCDC"
  }
}));

export const Container = styled("div")({
   display: "flex",
   marginBottom: "10px",
  });