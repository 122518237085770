import { useNavigate } from "react-router-dom";
import { FormWeapon } from "../FormWeapon";
import {
  formatDataWeaponConfigCreate, formatDataWeaponConfigCreateV2,
} from "../FormWeapon/contstants";
import { IFormWeapon } from "../FormWeapon/types";
import { useWeaponConfigCreate } from "./hooks";

export const NewWeaponV2: React.FC = () => {
  const { weaponConfigCreate, error } = useWeaponConfigCreate();
  const navigate = useNavigate();

  let errorMessage: string | undefined;

  if (error) {
    errorMessage = error?.message;
  }

  const onSubmit = async (values: IFormWeapon) => {
    try {
      console.log("v222",formatDataWeaponConfigCreateV2(values));
      await weaponConfigCreate({
        variables: formatDataWeaponConfigCreateV2(values),
      });
      alert("Сonfiguration of weapon has been created");
      navigate("..");
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <FormWeapon type="newv2" onSubmit={onSubmit} errorMessage={errorMessage} />
  );
};
