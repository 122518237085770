import { useGetLoadWeapons } from "pages/UserProfile/components/TopActions/components/RewardsModal/components/ItemsTabs/UseGetLoadWeapons";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { getToastPromiseConfig } from "utils/toast";
import Box from "@mui/material/Box";
import AsyncSelect from "react-select/async";
import { Button } from "@mui/material";

export interface TabContentProps {
  onSend: (id: string) => Promise<unknown>;
}

export const TabContentWeapons = ({ onSend }: TabContentProps) => {
  const loadOptions = useGetLoadWeapons();
  const [selectedItemId, setSelectedItemId] = useState<string>();
  const handleClick = () => {
    toast.promise(onSend(selectedItemId!), getToastPromiseConfig());
  };

  return (
    <Box gap={1} display="flex" flexDirection="column" alignItems="flex-end">
      <Box my={1}>
        You can send weapons and outfits. To send outfits correctly, please
        enter valid name in search field.
      </Box>
      <AsyncSelect
        defaultOptions
        placeholder="Item id"
        isClearable
        menuPortalTarget={document.body}
        styles={{
          container: (base) => ({ ...base, width: "100%" }),
          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
          menuList: (base) => ({ ...base, maxHeight: 200 }),
        }}
        onChange={(event) => {
          setSelectedItemId(event?.value);
        }}
        loadOptions={loadOptions}
      />

      <Button
        disabled={!selectedItemId}
        onClick={handleClick}
        color="secondary"
        variant="contained"
        sx={{ width: 100 }}
      >
        Send item
      </Button>
    </Box>
  );
};
