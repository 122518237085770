import { gql } from "@apollo/client";

export const getConfigsQuery = gql`
  query getConfigs($query: String!, $itemType: ItemType!) {
    lookup {
      itemConfigs {
        resolve(
          input: {
            query: $query
            itemType: $itemType
            pagination: { limit: 10, offset: 0 }
          }
        ) {
          ... on MintableItemConfig {
            configID
          }
          ... on GamedataItemConfig {
            itemID
            name
          }
        }
      }
    }
  }
`;
