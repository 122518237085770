import { Container } from "./HeaderCard.styles";
import { Typography } from "@mui/material";
import { SvgIconComponent } from "@mui/icons-material";

export const HeaderCard = ({
  title,
  subtitle,
  icon: Icon,
}: {
  title: string;
  subtitle?: number;
  icon: SvgIconComponent;
}) => {
  return (
    <Container>
      <Icon
        sx={{
          color: "#D9D9D9",
          height: "60px",
          width: "60px",
          marginInlineEnd: "23px",
        }}
      />
      <div>
        <Typography color="#545454" sx={{ mb: "2px" }}>
          {title}
        </Typography>
        <Typography color="#333" fontSize="39px" fontWeight={700}>
          {subtitle || "-"}
        </Typography>
      </div>
    </Container>
  );
};
