import { gql } from "@apollo/client";

export const GET_EVENT = gql`
  {
    lookup {
      clanEvents {
        events {
          id
          status
          name
          mainPageBannerVertical {
            backgroundImageURL
            iconURL
          }
          mainPageBannerHorizontal {
            backgroundImageURL
            iconURL
          }
          startAt
          endAt
          visibleAfter 
          raidDurationSeconds
          raidMinRewardPercent
          raidLevels {
            difficulties {
              difficulty
              rewardPoints
              quest {
                id
                description
                time {
                  start
                  end
                }
                tasks {
                  id
                  description
                  progress {
                    target
                  }
                  expression {
                    operators {
                      id
                      op
                      value
                    }
                  }
                }
              }
            }
            price
          }
          rewards {
            placeFromInclusive
            placeToExclusive
            reward {
              __typename
              ... on ClanEventRewardMoney {
                amount
              }
              ... on ClanEventRewardOffchain {
                amount
                account
              }
              ... on ClanEventRewardInventory {
                itemID
                amount
              }
              ... on ClanEventRewardToken {
                configID
                level
                amount
              }
            }
          }
          individualRewards {
            placeFromInclusive
            placeToExclusive
            reward {
              __typename
              ... on ClanEventRewardMoney {
                amount
              }
              ... on ClanEventRewardOffchain {
                amount
                account
              }
              ... on ClanEventRewardInventory {
                itemID
                amount
              }
              ... on ClanEventRewardToken {
                configID
                level
                amount
              }
            }
          }
          missionProgressBoosts {
            nftAttributes {
              title
              description
              specs {
                title
                description
                percent
                attribute {
                  name
                  value
                }
              }
            }
          }
        }
      }
    }
  }
`;
