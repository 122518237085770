import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  Button,
  Dialog,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  TextField,
} from "@mui/material";
import { GetClan_lookup_clans_clan_treasury_balances_offchainFunds } from "pages/ClanPage/query/__generated__/GetClan";
import { useState } from "react";

type Props = {
  row: GetClan_lookup_clans_clan_treasury_balances_offchainFunds;
};

export const ActionCell = ({ row }: Props) => {
  const deletionLoading = false;
  // const [deleteItemMutation, { loading: deletionLoading }] = useMutation(clanOffchainFundsDelete, ...);
  // const [editItemMutation, { loading: editLoading }] = useMutation(clanOffchainFundsEdit, ...);

  // Dropdown menu state and handlers
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const onDropdownExpand = (e: any) => setAnchorEl(e.currentTarget);
  const onDropdownCollapse = () => setAnchorEl(null);

  // Dialog for editing the row
  const [editDialogInputValue, setEditDialogInputValue] = useState<string>(
    row.quantity.toString()
  );
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const onEdit = () => {
    setIsEditDialogOpen(true);
    onDropdownCollapse();
  };
  const onEditCancel = () => {
    setIsEditDialogOpen(false);
  };

  const onDelete = () => {
    console.log("TODO: implement deleting clan offchain funds items");
  };
  const onEditApply = () => {
    console.log("TODO: implement changing clan offchain funds quantities", {
      row,
      newVal: +editDialogInputValue,
    });
  };

  return (
    <>
      <IconButton onClick={onDropdownExpand}>
        <MoreVertIcon />
      </IconButton>

      <Menu open={!!anchorEl} anchorEl={anchorEl} onClose={onDropdownCollapse}>
        <MenuItem onClick={onEdit}>Edit</MenuItem>
        <MenuItem
          onClick={onDelete}
          sx={{ color: "#f44336" }}
          disabled={deletionLoading}
        >
          Delete
        </MenuItem>
      </Menu>

      <Dialog
        open={isEditDialogOpen}
        onClose={() => setIsEditDialogOpen(false)}
      >
        <Paper
          sx={{ p: 5, display: "flex", flexDirection: "column", rowGap: 2 }}
        >
          <TextField
            type="number"
            label={row.accountType}
            defaultValue={row.quantity}
            onChange={(e) => setEditDialogInputValue(e.currentTarget.value)}
          />
          <Button variant="contained" onClick={onEditApply}>
            Apply
          </Button>
          <Button onClick={onEditCancel}>Cancel</Button>
        </Paper>
      </Dialog>
    </>
  );
};
