import { gql } from "@apollo/client";

export const GearConfigCreateQGL = gql`
   mutation GearConfigCreate(
    $id: String!
    $name: String!
    $nameCounterID: String!
    $season: String!
    $minMintLevel: Int!
    $dropType: String!
    $randomization: GearConfigRandomizationInput!
  ) {
    gearConfigCreate(
      input: {
        config: {
          id: $id
          name: $name
          minMintLevel: $minMintLevel
          nameCounterID: $nameCounterID
          season: $season
          dropType: $dropType
          randomization: $randomization
        }
      }
    ) {
      ok
    }
  }
`;
