import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { getColumns } from "./consts";
import { useLocalSort } from "utils/mui/useLocalSort";
import { SortCell } from "components/SortCell";
import TableCell from "@mui/material/TableCell";
import { GetClan_lookup_clans_clan_treasury_balances_offchainFunds } from "pages/ClanPage/query/__generated__/GetClan";
import { useMemo } from "react";

type Props = {
  offchainFunds: GetClan_lookup_clans_clan_treasury_balances_offchainFunds[];
  search: string;
};

export const OffchainFundsTable = ({ offchainFunds, search }: Props) => {
  const columns = getColumns();
  const { sortedData, handleRequestSort, order, getOrderBy } = useLocalSort({
    items: offchainFunds,
  });
  const filteredData = useMemo(
    () =>
      sortedData.filter((item) =>
        item.accountType.toLowerCase().includes(search)
      ),
    [sortedData, search]
  );

  return (
    <TableContainer sx={{ height: 405 }} component={Paper}>
      <Table size="small" aria-label="simple table">
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <SortCell
                key={column.key}
                column={column}
                getOrderBy={getOrderBy}
                order={order}
                handleRequestSort={handleRequestSort}
              />
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredData.map((item) => (
            <TableRow key={item.accountType}>
              {columns.map((el) => (
                <TableCell {...el.rowCellProps} key={el.key}>
                  {el.renderCell(item)}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
