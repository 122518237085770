import * as yup from "yup";

function hasWhiteSpace(str?: string) {
  if (!str) return false;

  return !/\s/g.test(str);
}

export const getSchema = () => {
  return yup.object({
    id: yup
      .string()
      .test("without space", "Fill in the field without spaces", hasWhiteSpace),
    nameCounterID: yup
      .string()
      .test("without space", "Fill in the field without spaces", hasWhiteSpace),
    season: yup
      .string()
      .test("without space", "Fill in the field without spaces", hasWhiteSpace),
    dropType: yup
      .string()
      .test("without space", "Fill in the field without spaces", hasWhiteSpace),
    minMintLevel: yup.number().required("Fill the field"),
  });
};
