import { gql } from "@apollo/client";

export const rewardPushShardsMutation = gql`
  mutation rewardPushShards(
    $userId: UUID!
    $reasonId: UUID!
    $shardId: String!
    $amount: Int!
    $admin: String!
  ) {
    rewardPushReward(
      input: {
        userID: $userId
        reason: { id: $reasonId, description: $admin }
        rewards: {
          currency: { arbitraryCurrency: { type: $shardId, amount: $amount } }
        }
      }
    ) {
      ok
    }
  }
`;
