import React, { useState } from "react";
import { Grid, Paper, Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import { GetUser_lookup_offchainWallets_allHolderAccounts } from "pages/UserProfile/query/__generated__/GetUser";
import { ShardsTable } from "pages/UserProfile/components/Inventory/components/ShardsTable";

type ShardsCardProps = {
  label: string;
  shards: GetUser_lookup_offchainWallets_allHolderAccounts[];
  onItemSelect: (item: string) => void;
};

export const ShardsCard = ({
  label,
  shards,
  onItemSelect,
}: ShardsCardProps) => {
  const [search, setSearch] = useState("");

  return (
    <Grid key={label} mt={3} item xs={5}>
      <Paper sx={{ p: 1 }}>
        <Grid container alignItems="center">
          <Grid item xs={4}>
            <Typography align="center" color="rgba(119, 119, 119, 1)">
              {label}{" "}
              <Typography color="black" fontWeight={700} component="span">
                {" "}
                ( {shards.length} )
              </Typography>
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <TextField
              size="small"
              label="Search"
              variant="outlined"
              fullWidth
              onChange={(e) => setSearch(e.target.value)}
            />
          </Grid>
          <Grid mt={2} item xs={12}>
            <ShardsTable
              onItemSelect={onItemSelect}
              search={search}
              shards={shards}
            />
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};
