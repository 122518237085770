import { styled } from "@mui/system";

export const Container = styled("div")({
  padding: "15px",
  backgroundColor: "#454545",
  width: "110px",
  boxSizing: "border-box",
  div: {
    "marginBottom": "11px",
    "cursor": "pointer",
    "color": "#fff",
    "fontSize": "14px",
    "position": "relative",
    "&:last-child": {
      marginBottom: "0",
    },
    "&:hover": {
      color: "lightGrey",
    },
  },
});
