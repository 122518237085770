import React from "react";
import { Box, TableSortLabel } from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import TableCell from "@mui/material/TableCell";
import { TableColumn } from "components/SortCell/types";
import { GetOrderBy, Order } from "utils/mui/sort";

type SortCellProps<T> = {
  column: TableColumn<T>;
  getOrderBy: GetOrderBy<T> | null;
  order: Order;
  handleRequestSort: (newGetOrderBy: GetOrderBy<T>) => void;
};

export const SortCell = <T,>({
  column,
  getOrderBy,
  order,
  handleRequestSort,
}: SortCellProps<T>) => {
  return (
    <TableCell
      sortDirection={
        getOrderBy && column.getOrderBy === getOrderBy ? order : false
      }
      {...column.headerCellProps}
    >
      {column.getOrderBy ? (
        <TableSortLabel
          active={getOrderBy === column.getOrderBy}
          direction={getOrderBy === column.getOrderBy ? order : "asc"}
          onClick={() => handleRequestSort(column.getOrderBy!)}
        >
          {column.label}
          {column.getOrderBy === getOrderBy ? (
            <Box component="span" sx={visuallyHidden}>
              {order === "desc" ? "sorted descending" : "sorted ascending"}
            </Box>
          ) : null}
        </TableSortLabel>
      ) : (
        column.label
      )}
    </TableCell>
  );
};
