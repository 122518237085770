import { Checkbox, FormControl, FormControlLabel } from "@mui/material";
import { useField } from "formik";
import React from "react";

type IProps = {
  name: string;
  label: string;
};

export function CheckboxWrapper(props: IProps) {
  const [field, meta, helpers] = useField(props.name);
  const { value } = meta;
  const { setValue } = helpers;

  return (
    <FormControl fullWidth>
      <FormControlLabel
        control={<Checkbox value={value} onChange={() => setValue(!value)} />}
        label={props.label}
      />
    </FormControl>
  );
}
