import { gql } from "@apollo/client";

export const queryGetClan = gql`
  query GetClan($id: UUID!) {
    lookup {
      clans {
        clan(input: $id) {
          id
          name
          slug
          description
          size
          capacity
          createdAt
          links {
            discord
            twitter
          }
          joinType
          leader {
            userId
            ident {
              username
              suffix
            }
          }
          clanEventStats {
            ongoingEvent {
              event {
                id
                name
              }
              rank
              militaryPoints
              economicPoints
            }
            history(input: { limit: 5 }) {
              event {
                id
                name
              }
              rank
              militaryPoints
              economicPoints
            }
          }
          members {
            account {
              userId
              ident {
                username
                suffix
              }
              verified
              personalInfo {
                email
              }
            }
            role
            joinedAt
            lastMatchAt
          }
          treasury {
            balances(
              input: {
                butter: true
                enrichedOrbs: true
                gamedataItems: true
                mintableTokens: true
                shards: true
              }
            ) {
              butter
              enrichedOrbs
              gamedataItems {
                id
                quantity
              }
              mintableTokens {
                configID
                level
                quantity
              }
              offchainFunds {
                accountType
                quantity
              }
            }
          }
        }
      }
    }
  }
`;
