import { useQuery } from "@apollo/client";
import { queryGetAvailableData } from "./availableWeapon.graphql";
import { GetAvailableData } from "./__generated__/GetAvailableData";

export const useGetAvailableData = () => {
  const { data, loading, error } = useQuery<GetAvailableData>(
    queryGetAvailableData
  );

  return {
    availableWeaponTypes: data?.lookup.cgsAdmin?.attributes?.availableWeaponTypes ?? [],
    availableSeasons: data?.lookup.cgsAdmin?.attributes?.availableSeasons ?? [],
    availableDropTypes: data?.lookup.cgsAdmin?.attributes?.availableDropTypes ?? [],
    availableNameCounters: data?.lookup.cgsAdmin?.attributes?.availableNameCounters ?? [],
    loading,
    error,
  };
};
