import { styled } from "@mui/system";
import { UserCard } from "components/UserCard";
import { Link } from "react-router-dom";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";

export function getServerColor() {
  let server = localStorage.getItem("server");
  if (!server) {
    localStorage.setItem(
      "server",
      "https://staging-server.miniroyale.io/graphql/api"
    );
    server = "https://staging-server.miniroyale.io/graphql/api";
  }
  if (server?.includes("dev")) return "rgba(203,238,175,0.94)";
  if (server?.includes("staging")) return "rgba(238,230,175,0.94)";
  if (server?.includes("prod")) return "rgba(239,138,131,0.94)";
  return "rgba(239,138,131,0.94)";
}

export function getServerType() {
  let server = localStorage.getItem("server");
  if (!server) {
    localStorage.setItem(
      "server",
      "https://staging-server.miniroyale.io/graphql/api"
    );
    server = "https://staging-server.miniroyale.io/graphql/api";
  }

  if (server.includes("dev")) return "dev";
  if (server.includes("staging")) return "staging";
  return "prod";
}

export const ListItem = styled(Link)({
  "display": "flex",
  "alignItems": "center",
  "gap": "10px",
  "padding": "10px 14px 11px 14px",
  "position": "relative",
  "cursor": "pointer",
  "borderRadius": "4px",
  "margin": "0 18px 0 17px",
  "fontWeight": 500,
  "fontSize": "14px",
  "textDecoration": "none",
  "color": "black",
  "&:hover": {
    backgroundColor: "#EDEDED",
  },
  "&:visited": {
    color: "black",
  },
});

export const UserCardStyled = styled(UserCard)({
  margin: "16px 14px 12px 17px",
});

export const StyledDoubleArrow = styled(KeyboardDoubleArrowLeftIcon)({
  width: "30px",
  height: "30px",
  color: "#000",
  opacity: 0.5,
});

export const Title = styled("div")({
  width: "200px",
  fontSize: "22px",
  fontWeight: 700,
});

export const ServerContainer = styled("div")({
  marginLeft: "20px",
  marginBottom: "6px",
  padding: "5px",
  backgroundColor: getServerColor(),
  width: "60px",
  textAlign: "center",
  borderRadius: "6px",
});
