import { v4 as uuidv4 } from "uuid";
import {
  // MaterialParamsInput,
  PerkSlotInput,
  // TextureInput,
  // WeaponConfigUploadResourcesInput,
} from "graphql-global-types/types";
import {
  GetPerksAndConditions_lookup_cgsAdmin_attributes_defaultWeaponConditions,
  GetPerksAndConditions_lookup_cgsAdmin_attributes_defaultWeaponPerksSlots,
} from "../hooks/useGetPerksAndConditions/__generated__/GetPerksAndConditions";

import {
  IFormWeapon,
  IPerk,
  IPerkSlot,
  IWeaponMaterial,
  RarityEnum,
  Skin,
} from "./types";

export const generateMaterialData = (): IWeaponMaterial => ({
  id: uuidv4(),
  name: "",
  withNormalMap: false,
  withEmissionMap: false,
  withAmbientOcclusion: false,
  textures: [],
  useSpecificParams: false,
});

export const generateSkinData = (): Skin => ({
  id: "",
  marketName: "",
});

export const initForm: IFormWeapon = {
  id: "",
  weaponType: "",
  weaponSkin: "",
  nameCounterID: "",
  season: "",
  minMintLevel: 0,
  dropType: "",
  skins: [generateSkinData()],
  name: "",
  rarity: RarityEnum.COMMON,
  useSpecificModel: false,
  materials: [generateMaterialData()],
  perksSlot: [],
  conditions: [],
  marketNameSkin: "",
  isStandard: false,
  isEmptyPerks: false,
};

export const initFormV2: IFormWeapon = {
  id: "",
  name: "",
  nameCounterID: "",
  weaponType: "",
  season: "",
  minMintLevel: 0,
  dropType: "",
  rarity: RarityEnum.COMMON,
  skins: [generateSkinData()],
  perksSlot: [],
  weaponSkin: "",
  conditions: [],
  useSpecificModel: false,
  materials: [generateMaterialData()],
  marketNameSkin: "",
  isStandard: false,
  isEmptyPerks: false,
};

export const formatPerksToFileds = (
  defaultWeaponPerksSlots: GetPerksAndConditions_lookup_cgsAdmin_attributes_defaultWeaponPerksSlots[]
) =>
  defaultWeaponPerksSlots.map(({ __typename, ...perk }) => {
    const customPerk = { ...perk } as unknown as IPerkSlot;
    const newPerks: IPerk[] = perk.perks.map((item) => {
      const newItem: IPerk = { ...item, checked: true };

      return newItem;
    });

    customPerk.perks = newPerks;

    return customPerk;
  });

export const formatConditionsToFileds = (
  defaultPerks: GetPerksAndConditions_lookup_cgsAdmin_attributes_defaultWeaponConditions[]
) =>
  defaultPerks.map(({ __typename, ...perk }) => {
    const customPerk = { ...perk } as unknown as IPerk;
    customPerk.checked = true;

    return customPerk;
  });

export const formatDataWeaponConfigCreate = (values: IFormWeapon) => ({
  id: values.id,
  nameCounterID: values.nameCounterID,
  weaponType: values.weaponType,
  name: "",
  weaponSkin: values.weaponSkin,
  minMintLevel: +values.minMintLevel,
  season: values.season,
  dropType: values.dropType,
  rarity: values.rarity,
  isStandard: values.isStandard,
  render: {
    useSpecificModel: values.useSpecificModel,
    materials: values.materials.map(
      ({
        name,
        withAmbientOcclusion,
        withEmissionMap,
        withNormalMap,
        useSpecificParams,
      }) => ({
        name,
        withAmbientOcclusion,
        withEmissionMap,
        withNormalMap,
        useSpecificParams,
      })
    ),
  },
  randomization: {
    perksSlots: values.perksSlot.map((slot) => {
      const newSlot = { ...slot } as PerkSlotInput;
      newSlot.perks = slot.perks
        .filter((item) => item.checked)
        .map(({ weight, name }) => ({ weight, name }));

      return newSlot;
    }),
    conditions: values.conditions
      .filter((item) => item.checked)
      .map(({ weight, name }) => ({ weight, name })),
  },
});

export const formatDataWeaponConfigCreateV2 = (values: IFormWeapon) => ({
  id: values.id,
  nameCounterID: values.nameCounterID,
  weaponType: values.weaponType,
  minMintLevel: +values.minMintLevel,
  season: values.season,
  name: values.name || "",
  dropType: values.dropType,
  rarity: values.rarity,
  weaponSkin: "",
  isStandard: values.isStandard,
  randomization: {
    perksSlots: values.isEmptyPerks ? [] : values.perksSlot.map((slot) => {
      const newSlot = { ...slot } as PerkSlotInput;
      newSlot.perks = slot.perks
        .filter((item) => item.checked)
        .map(({ weight, name }) => ({ weight, name }));

      return newSlot;
    }),
    conditions: values.isEmptyPerks ? [] : values.conditions
      .filter((item) => item.checked)
      .map(({ weight, name }) => ({ weight, name })),
  },
  skins: values.skins?.map((skin) => ({
    id: skin.id,
    marketName: (skin as Skin).marketName,
  })),
});
