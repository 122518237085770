import { client } from "apolloClient";
import { getAllShardsQuery } from "pages/UserProfile/query/getAllShards.graphql";
import { getAllShards } from "pages/UserProfile/query/__generated__/getAllShards";

export const useGetLoadShards = (filter?: string) => {
  return async () => {
    const { data } = await client.query<getAllShards>({
      query: getAllShardsQuery,
    });

    return data.lookup.shards
      .allRecipes!.filter((el) => {
        if (!filter) return true;
        return el.id.toLowerCase().includes(filter.toLowerCase());
      })
      .map((el) => ({
        value: el.id,
        label: el.id,
      }));
  };
};
