import { Button, Popover } from "@mui/material";
import { useStyles } from "pages/CgsServise/Cgs.style";
import { useState } from "react";

interface IpopoverProps {
  anchorEl: Element | null;
  handleClose: () => void;
  onClick: () => void;
  title: string;
}

export const CustomPopover = ({
  anchorEl,
  title,
  handleClose,
  onClick,
}: IpopoverProps) => {
  const classes = useStyles();
  const [inputValue, setValue] = useState("");

  const handleClick = () => {
    onClick();
    handleClose();
  };

  return (
    <Popover
      open={!!anchorEl}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
    >
      {title !== "Lock" && <input type="text" value={inputValue} onChange={(e) => setValue(e.target.value)} className={classes.checkWord} />}
      <div className={classes.popup}>
        <Button variant="contained" color="success" onClick={handleClick} disabled={title !== "Lock" && inputValue.toLocaleLowerCase() !== "delete"}>
          {title}
        </Button>
        <Button variant="contained" color="error" onClick={handleClose}>
          Cancel
        </Button>
      </div>
    </Popover>
  );
};
