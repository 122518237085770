import { useState } from "react";

import { Box, Button, Drawer, Typography } from "@mui/material";
import { useFormikContext } from "formik";
import { GearForm } from "./types";
import { Perk, SecondaryStats, PrimaryStat } from "./components/Perk";

export const RandomizationDrawer = () => {
  const [show, setShow] = useState(false);
  const { values } = useFormikContext<GearForm>();

  const randomizatonValues = values.randomization && Object.keys(values.randomization).filter(name => name !== "primaryStat" && name !== "secondaryStats" && name !== "__typename");
  return (
    <>
      <Button variant="outlined" size="small" onClick={() => setShow(true)}>
        Customize
      </Button>
      <Drawer
        anchor="right"
        open={show}
        onClose={() => setShow(false)}
        title="Perks and Conditions Drawer"
      >
        <Box width={600} padding={4}>
          <Typography variant="h6" gutterBottom component="div" mb={2}>
            Randomization
          </Typography>
          {randomizatonValues?.map((item, i) => <Perk key={i} item={item} />)}
          <PrimaryStat />
          <SecondaryStats />
        </Box>
      </Drawer>
    </>
  );
};
