import { defaultServer } from "./constants";
import { ApolloClient, InMemoryCache } from "@apollo/client";
import { offsetLimitPagination } from "@apollo/client/utilities";

let server = localStorage.getItem("server");
if (!server) {
  localStorage.setItem("server", defaultServer);
  server = defaultServer;
}

export const client = new ApolloClient({
  uri: server,
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          resolve: offsetLimitPagination(),
        },
      },
    },
  }),
  credentials: "include",
});
