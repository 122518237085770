import { Typography } from "@mui/material";
import { PerkWrapperStyled } from "./styled";
import { useFormikContext } from "formik";
import { GearForm, Slot } from "../../types";
import { PerkItem } from "./PerkItem";


export const PrimaryStat = () => {
  const { values, handleChange } = useFormikContext<GearForm>();

  return (
    <PerkWrapperStyled>
      <Typography variant="body2" mb={2}>
        Primary Stat
      </Typography>

      {values.randomization?.primaryStat.name.variations?.map((perk: Slot, perkItemIndex: number) => {
        return (
          <PerkItem
            perk={perk}
            key={perk.name}
            name={`randomization.primaryStat.name.variations.${perkItemIndex}`}
            handleChange={handleChange}
          />
        );
      })}
    </PerkWrapperStyled>
  );
};
