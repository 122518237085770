import { useState } from "react";

import { Alert, Box, Button, CircularProgress, TextField } from "@mui/material";

import { useGetListGearsConfig } from "./hooks";
import { InView } from "react-intersection-observer";
import { GearsCard } from "./GearsCard";
import { useDebounce } from "use-debounce";
import { useMutation } from "@apollo/client";
import { DEPLOY_GEARS } from "../mutation/GearConfigDeploy.graphql";
import { useNavigate } from "react-router-dom";
import { DEPLOY_EMOTES } from "../mutation/EmotesConfigDeploy.graphql copy";

const LIMIT = 32;

export const GearsList = () => {
  const [query, setQuery] = useState("");
  const [debouncedQuery] = useDebounce(query, 500);
  const navigate = useNavigate();
  const [isLast, setIsLast] = useState(false);
  const { data, loading, error, fetchMore } = useGetListGearsConfig({
    query: debouncedQuery,
    limit: LIMIT,
    pageToken: "",
  });

  const [deployGears] = useMutation(DEPLOY_GEARS);

  const [deployEmotes] = useMutation(DEPLOY_EMOTES);

  const fetchNextData = () => {
    fetchMore({
      variables: {
        query: "",
        limit: LIMIT,
        pageToken: data.nextPageToken,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;

        const oldItems = prev.lookup.cgsAdmin?.gear?.search.configs || [];
        const newItems =
          fetchMoreResult.lookup.cgsAdmin?.gear?.search.configs || [];

        if (!data?.nextPageToken) {
          setIsLast(true);
        }

        return {
          lookup: {
            __typename: "Lookup",
            cgsAdmin: {
              __typename: "CGSAdmin",
              gear: {
                __typename: "GearLookup",
                search: {
                  __typename: "SearchGearResult",
                  configs: oldItems.concat(newItems),
                  nextPageToken:
                    fetchMoreResult.lookup.cgsAdmin?.gear?.search
                      .nextPageToken || "",
                },
              },
            },
          },
        };
      },
    });
  };
  const clallBackInview = async (inView: boolean) => {
    if (isLast || !data.nextPageToken) {
      return;
    }

    if (inView && !error && !loading) {
      fetchNextData();
    }
  };

  return (
    <div>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <TextField
          value={query}
          onChange={(ev) => setQuery(ev.target.value)}
          label="Config name"
          sx={{ width: 300 }}
        />
        <Button
          onClick={() =>
            deployEmotes()
              .then(() => alert("successfull"))
              .catch((e: Error) => alert(e.message))
          }
          variant="contained"
        >
          Deploy Emotes
        </Button>
        <Button
          onClick={() =>
            deployGears()
              .then(() => alert("successfull"))
              .catch((e: Error) => alert(e.message))
          }
          variant="contained"
        >
          Deploy Gears
        </Button>
        <Box display="flex" gap={1}>
          <Button onClick={() => navigate("new")} variant="contained">
            New config
          </Button>
          <Button onClick={() => navigate("generate")} variant="contained">
            Generate
          </Button>
        </Box>
      </Box>

      <Box display="flex" flexWrap="wrap" mx={-2} mt={2}>
        {data?.configs?.map((item) => {
          return <GearsCard id={item.id} key={item.id} />;
        })}
      </Box>
      <InView onChange={clallBackInview}>
        {(props) => {
          let content = null;

          if (loading) {
            content = (
              <Box display="flex" justifyContent="center">
                <CircularProgress />
              </Box>
            );
          }

          if (error) {
            content = (
              <Alert severity="error">
                Error! Сause: <b>{error.message}</b>.{" "}
                <Button onClick={fetchNextData}>Refetch</Button>
              </Alert>
            );
          }

          if (isLast && !error) {
            content = <Alert severity="success">Configs are all loaded</Alert>;
          }

          return <Box ref={props.ref}>{content}</Box>;
        }}
      </InView>
    </div>
  );
};
