import { useState } from "react";
import { useMutation } from "@apollo/client";
import { CREATE_COLLECTION, UPDATE_COLLECTION } from "mutations/cgsCollections";
import Modal from "@mui/material/Modal";
import { useStyles } from "pages/MiniroyaleImages/MiniroyaleImages.style";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { ICgsModalProps } from "./Cgs.types";
import { CustomSelect } from "./components/Select";
import { useGetAvailableData } from "pages/Weapons/FormWeapon/hooks";

export const CgsModal = ({
  isOpen,
  setOpen,
  collection,
  refetchCollections,
  setCollection,
}: ICgsModalProps) => {
  if (!collection) return null;

  const isCreate = typeof collection === "string";
  const classes = useStyles();

  const [collectionId, setCollectionId] = useState(
    !isCreate ? collection?.id : ""
  );
  const [userText, setUserText] = useState(
    !isCreate ? collection?.userText : ""
  );
  const [name, setName] = useState(!isCreate ? collection?.params?.name : "");
  const [family, setFamily] = useState(
    !isCreate ? collection?.params?.family : ""
  );
  const [description, setDescription] = useState(
    !isCreate ? collection?.params?.description : ""
  );
  const [nameCounter, setNameCounter] = useState(
    !isCreate ? collection?.params?.nameCounter : ""
  );
  const [season, setSeason] = useState(
    !isCreate ? collection?.params?.season : ""
  );
  const [dropType, setDropType] = useState(
    !isCreate ? collection?.params?.dropType : ""
  );
  const [tokenNameTemplate, setTokenNameTemplate] = useState(
    !isCreate ? collection?.params?.tokenNameTemplate : ""
  );

  const { availableDropTypes, availableNameCounters, availableSeasons } = useGetAvailableData();

  const [createCgsCollection] = useMutation(CREATE_COLLECTION);
  const [updateCgsCollection] = useMutation(UPDATE_COLLECTION);

  const variables = {
    variables: {
      id: collectionId,
      userText,
      name,
      family,
      description,
      nameCounter,
      season,
      dropType,
      tokenNameTemplate,
    },
  };

  const closeModal = () => {
    setOpen(false);
    setCollection && setCollection("");
    setCollectionId("");
    setUserText("");
    setName("");
    setFamily("");
    setDescription("");
    setNameCounter("");
    setSeason("");
    setDropType("");
    setTokenNameTemplate("");
  };

  const fetchResult = () =>
    (isCreate ? createCgsCollection(variables) : updateCgsCollection(variables))
      .then(() => {
        refetchCollections();
        closeModal();
      })
      .catch((e: Error) => alert(e.message));

  return (
    <Modal
      open={isOpen}
      onClose={() => {
        setOpen(false);
        closeModal();
      }}
      aria-labelledby="child-modal-title"
      aria-describedby="child-modal-description"
    >
      <div className={classes.modalContainer}>
        <div style={{ textAlign: "center", marginBottom: "5px" }}>
          {isCreate ? "New" : "Edit"} Collection
        </div>
        <TextField
          id="outlined-basic"
          label="Collection ID"
          variant="outlined"
          value={collectionId}
          disabled={!isCreate}
          className={classes.modalInput}
          onChange={(e) => setCollectionId(e.target.value)}
        />
        <TextField
          id="outlined-basic"
          label="User Text"
          variant="outlined"
          value={userText}
          className={classes.modalInput}
          onChange={(e) => setUserText(e.target.value)}
        />
        <CustomSelect
          name="season-id"
          label="season"
          opts={availableSeasons}
          className={classes.modalInput}
          value={season}
          setFn={setSeason}
          style={{ marginBottom: "15px" }}
        />
        <CustomSelect
          name="droptype-id"
          label="Drop Type"
          opts={availableDropTypes}
          value={dropType}
          setFn={setDropType}
          style={{ marginBottom: "15px" }}
        />
        <CustomSelect
          name="namecounter-id"
          label="Name Counter"
          opts={availableNameCounters}
          className={classes.modalInput}
          value={nameCounter}
          setFn={setNameCounter}
          style={{ marginBottom: "15px" }}
        />
        <TextField
          id="outlined-basic"
          label="Name"
          variant="outlined"
          value={name}
          className={classes.modalInput}
          onChange={(e) => setName(e.target.value)}
        />
        <TextField
          id="outlined-basic"
          label="Family"
          variant="outlined"
          value={family}
          className={classes.modalInput}
          onChange={(e) => setFamily(e.target.value)}
        />
        <TextField
          id="outlined-basic"
          label="Description"
          variant="outlined"
          value={description}
          className={classes.modalInput}
          onChange={(e) => setDescription(e.target.value)}
        />
        <TextField
          id="outlined-basic"
          label="tokenNameTemplate"
          variant="outlined"
          value={tokenNameTemplate}
          className={classes.modalInput}
          onChange={(e) => setTokenNameTemplate(e.target.value)}
        />
        <Button
          variant="contained"
          style={{ margin: "0 auto", display: "block" }}
          onClick={fetchResult}
        >
          {isCreate ? "Create Collection" : "Update Collection"}
        </Button>
      </div>
    </Modal>
  );
};
