import { gql } from "@apollo/client";

export const clanUpdateFields = gql`
  mutation clanUpdate(
    $clanID: UUID!
    $name: String
    $slug: String
    $description: String
  ) {
    clanUpdate(
      input: {
        clanID: $clanID
        name: $name
        slug: $slug
        description: $description
      }
    ) {
      id
      name
      slug
      description
      size
      capacity
      createdAt
    }
  }
`;
