import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { queryGetClan } from "pages/ClanPage/query/getClan.graphql";
import { CircularProgress, Container, Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import React from "react";
import { ClanInfoContext } from "pages/ClanPage/context";
import { GetClan } from "pages/ClanPage/query/__generated__/GetClan";
import { ClanBaseInfo } from "pages/ClanPage/components/ClanBaseInfo";

export const ClanPage = () => {
  const { clanId } = useParams();
  const {
    data: clanRawData,
    loading,
    error,
    refetch,
  } = useQuery<GetClan>(queryGetClan, {
    variables: {
      id: clanId,
    },
  });

  if (loading) {
    return <CircularProgress />;
  }
  if (error || !clanRawData) {
    return (
      <Grid container>
        <Typography color="error">Error: ${error?.message}</Typography>
      </Grid>
    );
  }
  const data = clanRawData.lookup.clans.clan!;
  console.log("data", data);

  return (
    <ClanInfoContext.Provider value={{ data, refetch }}>
      <Container maxWidth="lg">
        <Grid container>
          <Grid container item xs={12}>
            <Grid container item xs={6} spacing={2} alignItems="center">
              <Grid item>
                <Typography color="rgba(84, 84, 84, 1)">Clans</Typography>
              </Grid>
              <Grid item>
                <Typography color="rgba(84, 84, 84, 0.5)">{data.id}</Typography>
              </Grid>
            </Grid>
          </Grid>
          <ClanBaseInfo />
        </Grid>
      </Container>
    </ClanInfoContext.Provider>
  );
};
