import { TabContentProps } from "pages/UserProfile/components/TopActions/components/RewardsModal/components/ItemsTabs/TabContentWeapons";
import { useGetLoadWeapons } from "pages/UserProfile/components/TopActions/components/RewardsModal/components/ItemsTabs/UseGetLoadWeapons";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { getToastPromiseConfig } from "utils/toast";
import Box from "@mui/material/Box";
import AsyncSelect from "react-select/async";
import { Button, TextField } from "@mui/material";
import { useGetLoadShards } from "pages/UserProfile/components/TopActions/components/RewardsModal/components/ItemsTabs/UseGetLoadShards";

export const TabContentShards = ({
  onSend,
}: {
  onSend: (id: string, amount: number, admin: string) => any;
}) => {
  const { profileObj } = JSON.parse(sessionStorage.getItem("auth") || "{}");
  const loadOptions = useGetLoadShards();
  const [selectedItemId, setSelectedItemId] = useState<string>();
  const [amount, setAmount] = useState(0);
  const admin = "admin panel manual reward by " + profileObj.email;

  const handleClick = () => {
    toast.promise(
      onSend(selectedItemId!, amount!, admin),
      getToastPromiseConfig()
    );
  };

  return (
    <Box gap={1} display="flex" flexDirection="column" alignItems="flex-end">
      <Box gap={1} display="flex" flexDirection="row" alignSelf="stretch">
        <AsyncSelect
          defaultOptions
          placeholder="Item id"
          isClearable
          menuPortalTarget={document.body}
          styles={{
            container: (base) => ({ ...base, width: "100%" }),
            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
            menuList: (base) => ({ ...base, maxHeight: 200 }),
          }}
          onChange={(event) => {
            setSelectedItemId(event?.value);
          }}
          loadOptions={loadOptions}
        />

        <TextField
          value={amount}
          onChange={(e) => setAmount(+e.target.value)}
          sx={{ width: "80%", background: "white" }}
          label={"Amount"}
          variant="outlined"
          type="number"
          size="small"
        />
      </Box>
      <Button
        disabled={!selectedItemId || !amount}
        onClick={handleClick}
        color="secondary"
        variant="contained"
        sx={{ width: 100 }}
      >
        Send item
      </Button>
    </Box>
  );
};
