import {
  FetchResult,
  useLazyQuery,
  useMutation,
  useQuery,
} from "@apollo/client";
import DeleteIcon from "@mui/icons-material/Delete";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import {
  Autocomplete,
  Button,
  Grid,
  IconButton,
  Input,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  tooltipClasses,
} from "@mui/material";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Tooltip, { TooltipProps } from "@mui/material/Tooltip";
import { GetUsers_lookup_accounts_search_items } from "components/SearchBar/query/__generated__/GetUsers";
import { debounce } from "lodash";
import { ADD_GAMEDATA_REWARD, ADD_MINTABLE_REWARD } from "mutations/addRewar";
import { useGetLoadShards } from "pages/UserProfile/components/TopActions/components/RewardsModal/components/ItemsTabs/UseGetLoadShards";
import { rewardPushMutation } from "pages/UserProfile/mutations/rewardPush.graphql";
import { rewardPushShardsMutation } from "pages/UserProfile/mutations/rewardPushShards.graphql";
import { getAllShards } from "pages/UserProfile/query/__generated__/getAllShards";
import { getAllShardsQuery } from "pages/UserProfile/query/getAllShards.graphql";
import { GET_GAMEDATA_ITEMS, GET_MINTABLE_ITEMS } from "query/getConfigItem";
import { GET_ALL_USERS } from "query/users";
import React, { ChangeEvent, useCallback, useRef, useState } from "react";
import AsyncSelect from "react-select/async";
import { toast } from "react-toastify";
import { getToastPromiseConfig } from "utils/toast";
import { v4 as uuidv4 } from "uuid";
import s from "./RewardGroup.module.css";

type IGDItem = { id: string; key: string; label: string };

export const RewardGroup = () => {
  const blurRef = useRef<any>(null);

  const [userInput, setUserInput] = useState("");
  const [userListInput, setUserListInput] = useState("");
  const [users, setUsers] = useState<GetUsers_lookup_accounts_search_items[]>(
    []
  );
  const [showSearchResults, setShowSearchResults] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState<
    GetUsers_lookup_accounts_search_items[]
  >([]);
  const [shardListInput, setShardListInput] = useState("");
  const [mintableInput, setMintableInput] = useState("");
  const [mintableListInput, setMintableListInput] = useState("");
  const [gamedataInput, setGamedataInput] = useState("");

  const [selectedMintableEntries, setSelectedMintableEntries] = useState<
    { item: string; amount: number }[]
  >([]);
  const [selectedGamedataEntries, setSelectedGamedataEntries] = useState<
    { item: IGDItem; amount: number }[]
  >([]);
  const [selectedShardEntries, setSelectedShardEntries] = useState<
    { id: string; amount: number }[]
  >([]);

  const [shardsFilter, setShardsFilter] = useState("");
  const [searchUsers, { error: eUser }] = useLazyQuery(GET_ALL_USERS);
  const runSearchUsers = useCallback(
    debounce(async () => {
      const res = await searchUsers({
        variables: {
          username: userInput,
        },
      });
      setUsers(res?.data.lookup?.accounts?.search?.items);
      setShowSearchResults(true);
    }, 1000),
    [userInput]
  );

  const runSearchUserList = async () => {
    if (userListInput.length === 0) return;
    const usersToSearch = userListInput.split(",").map((i) => i.trim());
    const res = [];
    for (const user of usersToSearch) {
      const data = await searchUsers({
        variables: {
          username: user.split("#")[0],
        },
      });
      res.push(data);
    }

    res
      .map((i) => i.data.lookup.accounts.search.items)
      .flat()
      .forEach((user: GetUsers_lookup_accounts_search_items) => {
        const name = user.ident.username + "#" + user.ident.suffix;
        if (userListInput.includes(name))
          setSelectedUsers((state) => {
            // if item already exist will add amount
            const newState = state.map((i) => i);
            // if not exist will add it
            if (!newState.find((i) => i.userId === user.userId))
              newState.push(user);
            return newState;
          });
      });
  };

  const [searchMintable, { data: mintables }] = useLazyQuery(
    GET_MINTABLE_ITEMS,
    {
      variables: {
        query: mintableInput,
      },
    }
  );

  const [searchGamedata, { data: gamedataItems }] = useLazyQuery(
    GET_GAMEDATA_ITEMS,
    {
      variables: {
        query: mintableInput,
      },
    }
  );

  const loadShards = useGetLoadShards(shardsFilter);
  const { data: allShardsRaw } = useQuery<getAllShards>(getAllShardsQuery);
  const allShards = allShardsRaw?.lookup?.shards?.allRecipes;
  console.log("allShards", allShards);

  const [addMintableReward] = useMutation(ADD_MINTABLE_REWARD);
  const [addGameDataReward] = useMutation(ADD_GAMEDATA_REWARD);
  const pushMintableToUser = (userId: string, mintable: string) =>
    addMintableReward({
      variables: {
        userId,
        reasonId: uuidv4(),
        fromDesc: "added from group rewards",
        configID: mintable,
      },
    });

  const pushGamedataToUser = (userId: string, gd: IGDItem) =>
    addGameDataReward({
      variables: {
        userId,
        reasonId: uuidv4(),
        fromDesc: "added from group rewards",
        itemID: gd.id,
      },
    });

  const addUserToSelectedGroup = (
    userToAdd: GetUsers_lookup_accounts_search_items
  ) => {
    setSelectedUsers((previousSelection) => {
      const alreadyContains = previousSelection.some(
        (item) => item.userId === userToAdd.userId
      );
      if (alreadyContains) {
        return previousSelection;
      } else {
        return [...previousSelection, userToAdd];
      }
    });
  };

  const removeUserFromSelectedGroup = (
    userToRemove: GetUsers_lookup_accounts_search_items
  ) => {
    setSelectedUsers(
      (previousSelection: GetUsers_lookup_accounts_search_items[]) => {
        return previousSelection.filter(
          (u) => u.userId !== userToRemove.userId
        );
      }
    );
  };
  const onUserSelected = (user: GetUsers_lookup_accounts_search_items) => {
    setUserInput("");
    addUserToSelectedGroup(user);
    setShowSearchResults(false);
  };

  const peoplePlural =
    selectedUsers.length +
    " " +
    (selectedUsers.length === 1 ? "person" : "people");
  const currencies = ["orbs", "enrichedOrbs", "butter"] as const; // these are used as object keys in reward push requests
  const [selectedRewardAmounts, setSelectedRewardAmounts] = useState({
    orbs: 0,
    enrichedOrbs: 0,
    butter: 0,
  });
  const selectRewardAmount = (currencyName: string, amount: number) => {
    setSelectedRewardAmounts((prev) => ({ ...prev, [currencyName]: amount }));
  };
  const selectMintableAmount = (item: string, amount: number) => {
    setSelectedMintableEntries((prev) => {
      return prev.map((entry) => {
        if (entry.item !== item) return entry;
        else return { item, amount };
      });
    });
  };
  const selectGamedataAmount = (item: IGDItem, amount: number) => {
    setSelectedGamedataEntries((prev) => {
      return prev.map((entry) => {
        if (entry.item.id !== item.id) return entry;
        else return { item, amount };
      });
    });
  };
  const selectShardAmount = (id: string, amount: number) =>
    setSelectedShardEntries((prev) => {
      return prev.map((entry) => {
        if (entry.id !== id) return entry;
        else return { id, amount };
      });
    });
  const deleteShardEntry = (id: string) => {
    setSelectedShardEntries((old) => old.filter((entry) => entry.id !== id));
  };
  const findAmountsSum = (entries: { amount: number }[]) =>
    entries.reduce((sum, entry) => sum + entry.amount, 0);

  const runSearchMintable = useCallback(
    debounce(() => searchMintable(), 500),
    []
  );

  const selectedRewardAmountArr = Object.entries(selectedRewardAmounts).map(
    ([key, val]) => ({ currency: { [key]: val } })
  );

  const [rewardPush] = useMutation(rewardPushMutation);
  const pushRewardToUser = (userId: string) =>
    rewardPush({
      variables: {
        userId,
        reasonId: uuidv4(),
        rewards: selectedRewardAmountArr,
      },
    });

  const { profileObj } = JSON.parse(sessionStorage.getItem("auth") || "{}");
  const admin = "admin panel manual reward by " + profileObj?.email;
  const [rewardPushShards] = useMutation(rewardPushShardsMutation);
  const pushShardToUser = (userId: string, shardId: string, amount: number) =>
    rewardPushShards({
      variables: {
        userId,
        shardId,
        amount,
        admin,
        reasonId: uuidv4(),
      },
    });

  // useEffect(() => {
  //   if (usersResult) {
  //     setUsers(usersResult?.lookup?.accounts?.search?.items);
  //   }
  // }, [usersResult]);

  const handleUserInput = (value: string) => {
    setUserInput(value);
    setShowSearchResults(true);
    runSearchUsers(value); // when not using request stub, do not pass 'value'
  };

  const handleSendCurrencies = () => {
    toast.promise(
      Promise.all(selectedUsers.map((u) => u.userId).map(pushRewardToUser)),
      getToastPromiseConfig()
    );
    setSelectedRewardAmounts({ orbs: 0, enrichedOrbs: 0, butter: 0 });
  };

  const handleSendMintables = () => {
    const allPromises: Promise<FetchResult>[] = [];
    selectedUsers.forEach(({ userId }) => {
      selectedMintableEntries.forEach(({ item, amount }) => {
        for (let i = 0; i < amount; i++)
          allPromises.push(pushMintableToUser(userId, item)); // где-то повесился graphql
      });
    });
    toast.promise(Promise.all(allPromises), getToastPromiseConfig());
  };

  const handleSendGamedata = () => {
    const allPromises: Promise<FetchResult>[] = [];
    selectedUsers.forEach(({ userId }) => {
      selectedGamedataEntries.forEach(({ item, amount }) => {
        for (let i = 0; i < amount; i++)
          allPromises.push(pushGamedataToUser(userId, item));
      });
    });
    toast.promise(Promise.all(allPromises), getToastPromiseConfig());
  };

  const handleSendShards = () => {
    const userIds = selectedUsers.map((u) => u.userId);
    toast.promise(
      Promise.all(
        userIds.reduce((acc, userId) => {
          return acc.concat(
            selectedShardEntries.map(({ id: shardId, amount }) =>
              pushShardToUser(userId, shardId, amount)
            )
          );
        }, [])
      ),
      getToastPromiseConfig()
    );
  };

  //AK47-Quasar_Gas#10,MK18-Andromeda#20
  // we search items and if one exist add it to current list
  const runSearchMintableList = async () => {
    if (mintableListInput.length === 0) return;
    const mintablesToSearch = mintableListInput
      .trim()
      .replace(/,+$/, "")
      .split(",")
      .map((i) => i.trim());
    const res = [];
    for (const item of mintablesToSearch) {
      const data = await searchMintable({
        variables: {
          query: item.split("#")[0],
        },
      });
      res.push(data);
    }

    res
      .map((i) => i.data.lookup.itemConfigs.resolve)
      .flat()
      .forEach((item) => {
        const configID = item.configID;
        const found = mintablesToSearch.find((i) => i.includes(configID));
        if (found) {
          const amount = +found.split("#")[1] || 1;

          setSelectedMintableEntries((state) => {
            // if item already exist will add amount
            const newState = state.reduce((acc, item) => {
              if (item.item !== configID) return acc.concat(item);
              else
                return acc.concat({
                  item: configID,
                  amount: item.amount + amount,
                });
            }, [] as { item: string; amount: number }[]);
            // if not exist will add it
            if (!newState.find((i) => i.item === configID))
              newState.push({ item: configID, amount: amount });
            return newState;
          });
        }
      });
  };

  const runSearchShardList = async () => {
    if (shardListInput.length === 0) return;
    console.log("shardListInput", shardListInput);
    console.log("selectedShardEntries", selectedShardEntries);

    const shardToSearch = shardListInput
      .trim()
      .replace(/,+$/, "")
      .split(",")
      .map((i) => i.trim());

    shardToSearch.forEach((item) => {
      const name = item.split("#")[0];
      const amount = +item.split("#")[1] || 1;
      const found = allShards!.find((i) => i.id === name);
      if (found) {
        setSelectedShardEntries((state) => {
          // if item already exist will add amount
          const newState = state.reduce((acc, item) => {
            if (found.id !== item.id) return acc.concat(item);
            else
              return acc.concat({
                id: item.id,
                amount: item.amount + amount,
              });
          }, [] as { id: string; amount: number }[]);
          // if not exist will add it
          if (!newState.find((i) => i.id === name))
            newState.push({ id: found.id, amount: amount });
          return newState;
        });
      }
    });
  };

  const mintableItemOptions = mintables
    ? mintables.lookup.itemConfigs.resolve.map((i: any) => i.configID)
    : [];

  const handleMintInputChange = (val: string) => {
    setMintableInput(val);
    if (val) runSearchMintable();
  };

  const handleMintSelect = (val: string) => {
    setSelectedMintableEntries((prev) => {
      const alreadyAdded = prev.some((entry) => entry.item === val);
      if (!alreadyAdded) prev = [...prev, { item: val, amount: 1 }];
      return prev;
    });
    blurRef.current.focus();
  };

  const handleShardSelect = (id: string) => {
    setSelectedShardEntries((prev) => {
      const alreadyAdded = prev.some((entry) => entry.id === id);
      if (!alreadyAdded) prev = [...prev, { id, amount: 1 }];
      return prev;
    });
  };

  const handleShardInputChange: React.ComponentProps<
    typeof AsyncSelect
  >["onInputChange"] = (value) => {
    setShardsFilter(value);
  };

  const gamedataItemOptions: IGDItem[] = gamedataItems
    ? gamedataItems.lookup.itemConfigs.resolve.map((i: any) => ({
        label: i.name + " " + i.itemID,
        id: i.itemID,
        key: i.itemID,
      }))
    : [];
  const runSearchGamedata = useCallback(
    debounce(() => searchGamedata(), 500),
    []
  );

  const handleGamedataInputChange = (val: string) => {
    setGamedataInput(val);
    if (val) runSearchGamedata();
  };
  const handleGamedataSelect = (val: IGDItem | null, reason: string | null) => {
    if (reason !== "selectOption" || val === null) return;
    setSelectedGamedataEntries((prev) => {
      const alreadyAdded = prev.some((entry) => entry.item.id === val.id);
      if (!alreadyAdded) prev = [...prev, { item: val, amount: 1 }];
      return prev;
    });
  };

  if (eUser) return <div>unauthenticated</div>;

  return (
    <>
      <div className={s.invisible}>
        <input ref={blurRef} />
      </div>
      <Grid container gap={2}>
        <Grid item xs={5}>
          <Box
            component={Paper}
            display="flex"
            flexDirection="column"
            rowGap={2}
            p={2}
            position="relative"
          >
            <Box display="flex" justifyContent="space-between">
              <b>Players</b>
              <HtmlTooltip
                title={
                  <>
                    To add list of players, fill input: <br />
                    <br />
                    <b>
                      <em>atata#4YB1P0,testxx10#WWHG4W</em>
                    </b>
                    <br />
                    <br />
                    Dont forget to write hash
                  </>
                }
              >
                <IconButton aria-label="delete" size="small">
                  <QuestionMarkIcon />
                </IconButton>
              </HtmlTooltip>
            </Box>

            <TextField
              size="small"
              id="outlined"
              value={userInput}
              label="User Name"
              variant="outlined"
              fullWidth
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleUserInput(e.target.value)
              }
            />
            <Box display={"flex"} mt={1} gap={1}>
              <LocalInput
                label="User List"
                placeholder="aaa10#Y4BCFM,aaa12#68ZIRC"
                value={userListInput}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setUserListInput(e.target.value)
                }
              />
              <Button
                variant="contained"
                size="small"
                onClick={runSearchUserList}
              >
                Add
              </Button>
            </Box>
            <div className={s.userList}>
              {showSearchResults &&
                users.map(
                  (item: GetUsers_lookup_accounts_search_items, i: number) => (
                    <div
                      onClick={() => onUserSelected(item)}
                      className={s.userItem}
                      key={i}
                    >
                      {item?.ident?.username}
                      <span>{item?.ident?.suffix}</span>
                    </div>
                  )
                )}
            </div>
            <TableContainer>
              <Table size="small" aria-label="simple table">
                <TableBody>
                  {selectedUsers.map((user, i) => (
                    <TableRow key={i}>
                      <TableCell>
                        <IconButton
                          onClick={() => removeUserFromSelectedGroup(user)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                      <TableCell>
                        {user.ident.username}#{user.ident.suffix}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            {selectedUsers.length > 0 && (
              <a className={s.link} onClick={() => setSelectedUsers([])}>
                clear
              </a>
            )}
          </Box>
        </Grid>
        <Grid item xs={5}>
          <Box
            component={Paper}
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            p={2}
          >
            <b>Currencies</b>
            <Box>
              <TableContainer>
                <Table size="small" aria-label="simple table">
                  <TableBody>
                    {currencies.map((currency, i) => (
                      <TableRow key={i}>
                        <TableCell>{currency}</TableCell>
                        <TableCell>
                          <Input
                            type="number"
                            value={selectedRewardAmounts[currency]}
                            onChange={(e) =>
                              selectRewardAmount(
                                currency,
                                Number(e.target.value)
                              )
                            }
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
            <Button
              variant="contained"
              onClick={handleSendCurrencies}
              disabled={
                !selectedUsers.length ||
                (!selectedRewardAmounts.orbs &&
                  !selectedRewardAmounts.enrichedOrbs &&
                  !selectedRewardAmounts.butter)
              }
              sx={{ marginTop: "1em" }}
            >
              Send to {peoplePlural}
            </Button>
          </Box>
        </Grid>
        <Grid item xs={5}>
          <Box
            component={Paper}
            display="flex"
            flexDirection="column"
            rowGap={2}
            p={2}
            position="relative"
          >
            <Box display="flex" justifyContent="space-between">
              <b>Mintable Items</b>{" "}
              <HtmlTooltip
                title={
                  <>
                    For example: <br />
                    <br />
                    <b>
                      <em>AK47-Quasar_Gas#10,MK18-Andromeda</em>
                    </b>
                    <br />
                    <br />
                    will add
                    <br />
                    <br />
                    <b>AK47-Quasar_Gas - 10</b>
                    <br />
                    <b>MK18-Andromeda - 1 </b>
                  </>
                }
              >
                <IconButton aria-label="delete" size="small">
                  <QuestionMarkIcon />
                </IconButton>
              </HtmlTooltip>
            </Box>
            <Autocomplete
              size="small"
              disablePortal
              autoHighlight
              options={mintableItemOptions}
              fullWidth
              renderInput={(params) => (
                <TextField {...params} label="ConfigId" />
              )}
              onInputChange={(e, value) => handleMintInputChange(value)}
              value={mintableInput}
              renderOption={(_, option) => {
                console.log("props", option);

                return (
                  <div
                    className={s.option}
                    onClick={() => handleMintSelect(option)}
                  >
                    {option}
                  </div>
                );
              }}
            />
            <Box display={"flex"} mt={1} gap={1}>
              <LocalInput
                label="Mintable List"
                placeholder="AK47-Quasar_Gas#10,MK18-Andromeda"
                value={mintableListInput}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setMintableListInput(e.target.value)
                }
              />
              <Button
                variant="contained"
                size="small"
                onClick={runSearchMintableList}
              >
                Add
              </Button>
            </Box>

            <TableContainer>
              <Table size="small" aria-label="simple table">
                <TableBody>
                  {selectedMintableEntries.map(({ item: mintable, amount }) => (
                    <TableRow key={mintable}>
                      <TableCell>
                        <IconButton
                          onClick={() =>
                            setSelectedMintableEntries((prev) =>
                              prev.filter((entry) => entry.item !== mintable)
                            )
                          }
                        >
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>

                      <TableCell>{mintable}</TableCell>

                      <TableCell width="100px">
                        <TextField
                          value={amount}
                          onChange={(e) =>
                            selectMintableAmount(
                              mintable,
                              Math.max(+e.target.value, 1)
                            )
                          }
                          sx={{ background: "white" }}
                          label={"Amount"}
                          variant="outlined"
                          type="number"
                          size="small"
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            {selectedMintableEntries.length > 0 && (
              <a
                className={s.link}
                onClick={() => setSelectedMintableEntries([])}
              >
                clear
              </a>
            )}
            <Button
              variant="contained"
              onClick={handleSendMintables}
              disabled={
                !selectedUsers.length || !selectedMintableEntries.length
              }
            >
              {`Send ${findAmountsSum(selectedMintableEntries)} items of ${
                selectedMintableEntries.length
              } types
                to ${
                  selectedUsers.length >= 2 ? "each of" : ""
                } ${peoplePlural}`}
            </Button>
          </Box>
        </Grid>
        <Grid item xs={5}>
          <Box
            component={Paper}
            display="flex"
            flexDirection="column"
            rowGap={2}
            p={2}
            position="relative"
          >
            <b>Gamedata Items</b>
            <Autocomplete
              disablePortal
              options={gamedataItemOptions}
              fullWidth
              autoHighlight
              renderInput={(params) => <TextField {...params} label="ItemId" />}
              onChange={(e, value, reason) => {
                handleGamedataSelect(value, reason);
              }}
              onInputChange={(e, value) => handleGamedataInputChange(value)}
              value={gamedataInput as unknown as any}
            />

            <TableContainer>
              <Table size="small" aria-label="simple table">
                <TableBody>
                  {selectedGamedataEntries.map(({ item, amount }) => (
                    <TableRow key={item.key}>
                      <TableCell>
                        <IconButton
                          onClick={() =>
                            setSelectedGamedataEntries((prev) =>
                              prev.filter(
                                (prevEntry) => prevEntry.item.id !== item.id
                              )
                            )
                          }
                        >
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>

                      <TableCell>{item.label}</TableCell>

                      <TableCell width="100px">
                        <TextField
                          value={amount}
                          onChange={(e) =>
                            selectGamedataAmount(
                              item,
                              Math.max(+e.target.value, 1)
                            )
                          }
                          sx={{ background: "white" }}
                          label={"Amount"}
                          variant="outlined"
                          type="number"
                          size="small"
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            {selectedGamedataEntries.length > 0 && (
              <a
                className={s.link}
                onClick={() => setSelectedGamedataEntries([])}
              >
                clear
              </a>
            )}
            <Button
              variant="contained"
              onClick={handleSendGamedata}
              disabled={
                !selectedUsers.length || !selectedGamedataEntries.length
              }
            >
              {`Send ${findAmountsSum(selectedGamedataEntries)} items of ${
                selectedGamedataEntries.length
              } types
                to ${
                  selectedUsers.length >= 2 ? "each of" : ""
                } ${peoplePlural}`}
            </Button>
          </Box>
        </Grid>
        <Grid item xs={5}>
          <Box
            component={Paper}
            display="flex"
            flexDirection="column"
            rowGap={2}
            p={2}
            position="relative"
          >
            <b>Shards</b>

            <Box gap={1} display="flex" flexDirection="row" alignSelf="stretch">
              <AsyncSelect
                defaultOptions
                placeholder="Item id"
                isClearable
                menuPortalTarget={document.body}
                styles={{
                  container: (base) => ({ ...base, width: "100%" }),
                  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                  menuList: (base) => ({ ...base, maxHeight: 200 }),
                }}
                onChange={(e) => {
                  handleShardSelect(e!.value);
                }}
                loadOptions={loadShards}
                onInputChange={handleShardInputChange}
              />
            </Box>
            <Box display={"flex"} mt={1} gap={1}>
              <LocalInput
                label="Shards List"
                placeholder="ShardName#10, ShardAnyname#15"
                value={shardListInput}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setShardListInput(e.target.value)
                }
              />
              <Button
                variant="contained"
                size="small"
                onClick={runSearchShardList}
              >
                Add
              </Button>
            </Box>

            <Box gap={1} display="flex" flexDirection="row" alignSelf="stretch">
              <TableContainer>
                <Table size="small" aria-label="simple table">
                  <TableBody>
                    {selectedShardEntries.map(({ id, amount }) => (
                      <TableRow key={id}>
                        <TableCell>
                          <IconButton onClick={() => deleteShardEntry(id)}>
                            <DeleteIcon />
                          </IconButton>
                        </TableCell>

                        <TableCell>{id}</TableCell>

                        <TableCell width="100px">
                          <TextField
                            value={amount}
                            onChange={(e) =>
                              selectShardAmount(
                                id,
                                Math.max(+e.target.value, 1)
                              )
                            }
                            sx={{ background: "white" }}
                            label={"Amount"}
                            variant="outlined"
                            type="number"
                            size="small"
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
            {selectedShardEntries.length > 0 && (
              <a className={s.link} onClick={() => setSelectedShardEntries([])}>
                clear
              </a>
            )}
            <Button
              variant="contained"
              onClick={handleSendShards}
              disabled={!selectedUsers.length || !selectedShardEntries.length}
            >
              {`Send ${findAmountsSum(selectedShardEntries)} shards of ${
                selectedShardEntries.length
              } types
                to ${
                  selectedUsers.length >= 2 ? "each of" : ""
                } ${peoplePlural}`}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

function LocalInput(props: {
  onChange: any;
  value: string;
  label: string;
  placeholder?: string;
}) {
  return (
    <TextField
      size="small"
      variant="outlined"
      label={props.label}
      placeholder={props.placeholder}
      fullWidth
      value={props.value}
      onChange={props.onChange}
    />
  );
}

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 300,
    fontSize: 14,
    border: "1px solid #dadde9",
    padding: 10,
  },
}));
