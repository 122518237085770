import { useState } from "react";
import { Button, Grid, Switch } from "@mui/material";
import { REVERT_LOG } from "./mutation/revertLog";
import { useMutation } from "@apollo/client";

export const RevertLog = ({ id }: { id: string }) => {
  const [batch, setBatch] = useState(false);
  const [revertLog] = useMutation(REVERT_LOG);

  return (
    <Grid display="flex" justifyContent="flex-end" alignItems="center">
      <Switch checked={batch} onChange={() => setBatch(!batch)} />
      <Button
        variant="contained"
        color="secondary"
        onClick={() =>
          revertLog({
            variables: {
              logID: id,
              batch,
            },
          })
        }
      >
        Revert
      </Button>
    </Grid>
  );
};
