import { ToastPromiseParams } from "react-toastify/dist/core/toast";
import { ApolloError } from "@apollo/client";

export const getToastPromiseConfig = (): ToastPromiseParams => {
  return {
    pending: "Loading...",
    success: "Success!",
    error: {
      render({ data }) {
        console.error(JSON.stringify(data));
        if (data instanceof ApolloError) {
          if (data.clientErrors.length) {
            return JSON.stringify(data.clientErrors);
          }
          if (data.graphQLErrors.length) {
            return JSON.stringify(data.graphQLErrors);
          }
          if (data.networkError) {
            return JSON.stringify(data.networkError);
          }
          return JSON.stringify(data);
        }
        if (data instanceof Error) {
          return data.message;
        }
      },
    },
  };
};
