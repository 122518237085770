import React from "react";
import { getStatsData } from "./utils";
import { Grid, Paper, Typography } from "@mui/material";
import { useUserProfileContext } from "pages/UserProfile/context";

export const StatsInfo = () => {
  const { data } = useUserProfileContext();
  const statsData = getStatsData(data);
  return (
    <Grid mt={3} container item xs={12} spacing={2}>
      {statsData.map((el) => (
        <Grid key={el.label} item xs={3}>
          <Paper sx={{ p: 2 }}>
            <Grid container>
              <Grid
                mb={4}
                item
                xs={12}
                display="flex"
                justifyContent="flex-end"
              >
                {el.icon}
              </Grid>
              <Grid item xs={12}>
                <Typography fontSize={30}>{el.value}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography color="rgba(119, 119, 119, 1)" fontSize={14}>
                  {el.label}
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      ))}
    </Grid>
  );
};
