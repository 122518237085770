import { useEffect, useState } from "react";
import { useLazyQuery, useQuery } from "@apollo/client";
import { GET_LOGS } from "./query/logs/getLogs.graphql";
import { GET_COLLECTION_IDS } from "./query/collectionIds/getCollectionsIds.graphql";
import { GET_PRESETS_IDS } from "./query/presetsIds/getPresetsIds.graphql";
import { PresetsIds } from "./query/presetsIds/__generated__/PresetsIds";
import {
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Button,
  Grid,
  SelectChangeEvent,
} from "@mui/material";
import { GetLogs } from "./query/logs/__generated__/GetLogs";
import { CollectionIds } from "./query/collectionIds/__generated__/CollectionIds";
import { RevertLog } from "./RevertLog";
import InfiniteScroll from "react-infinite-scroll-component";

import { Title, UpdatesContainer } from "./Logs.tyles";
import { CGSLogAction } from "./../../graphql-global-types/types";

export const Logs = () => {
  const [getLogs, { data }] = useLazyQuery<GetLogs>(GET_LOGS, {
    fetchPolicy: "no-cache",
  });
  const [getPresetsIds, { data: presetIds }] =
    useLazyQuery<PresetsIds>(GET_PRESETS_IDS);
  const { data: collections } = useQuery<CollectionIds>(GET_COLLECTION_IDS, {
    fetchPolicy: "no-cache",
  });

  const logs = data?.lookup?.cgsAdmin?.characters?.logs.logs;

  const [collectionID, setColectionId] = useState("");
  const [presetID, setPresetId] = useState("");
  const [action, setAction] = useState("");
  const [isNext, setNext] = useState("");
  const [logsList, setLogs] = useState<any>([]);

  useEffect(() => {
    data?.lookup?.cgsAdmin?.characters?.logs.nextID &&
      setNext(data?.lookup?.cgsAdmin?.characters?.logs.nextID);
  }, [data?.lookup?.cgsAdmin?.characters?.logs.nextID]);

  useEffect(() => {
    if (!logsList.length) logs && setLogs(logs);
  }, [logs]);

  const handleChange = (e: SelectChangeEvent<string>) => {
    setLogs([]);
    setColectionId(e.target.value);
    getPresetsIds({
      variables: {
        id: e.target.value,
      },
    });
  };

  const loadMore = () => {
    getLogs({
      variables: {
        offsetID: data?.lookup?.cgsAdmin?.characters?.logs.nextID || "",
        limit: 20,
        action,
        presetID,
        collectionID,
      },
    });
    logs && setLogs((prev: any) => [...prev, ...logs]);
  };

  return (
    <div>
      <Grid container alignItems="center">
        <Button
          variant="contained"
          sx={{ mr: 3, width: "180px" }}
          onClick={() => {
            getLogs({
              variables: {
                offsetID: "",
                limit: 20,
                action,
                presetID,
                collectionID,
              },
            });
          }}
        >
          show logs
        </Button>
        <FormControl>
          <InputLabel id="demo-simple-select-label">Collection ID</InputLabel>
          <Select
            style={{ width: "300px", marginRight: "40px" }}
            label="Collection ID"
            value={collectionID}
            onChange={handleChange}
          >
            {collections?.lookup?.cgsAdmin?.characters?.collections?.map(({ id }) => ( //characters
              <MenuItem key={id} value={id}>
                {id}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl>
          <InputLabel id="preset-id">Preset ID</InputLabel>
          <Select
            style={{ width: "300px", marginRight: "30px" }}
            disabled={!collectionID}
            label="Preset ID"
            value={presetID}
            onChange={(e) => {
              setLogs([]);
              setPresetId(e.target.value);
            }}
          >
            {presetIds?.lookup?.cgsAdmin?.characters?.collection?.presets?.map(({ id }) => (
              <MenuItem key={id} value={id}>
                {id}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl>
          <InputLabel id="Action">Action</InputLabel>
          <Select
            style={{ width: "300px" }}
            label="Action"
            value={action}
            onChange={(e) => {
              setLogs([]);
              setAction(e.target.value);
            }}
          >
            {Object.values(CGSLogAction).map((item) => (
              <MenuItem key={item} value={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <div>
        <InfiniteScroll
          dataLength={10} //This is important field to render the next data
          next={() => loadMore()}
          hasMore={!!isNext}
          loader={<h4 />}
        >
          <Grid
            container
            columnGap={2}
            rowGap={2}
            sx={{ mt: 4 }}
            justifyContent="center"
          >
            {logsList?.map((item: any) => (
              <Grid
                xs={5}
                sx={{ pb: 5, bgcolor: "#fafafa", p: 4, borderRadius: 4 }}
                key={item.logID}
                display="flex"
                justifyContent="space-between"
                flexDirection="column"
              >
                <div>
                  <div>
                    <Title>Action:</Title> {item.action}
                  </div>
                  <div>
                    <Title>BatchId:</Title> {item.batchID}
                  </div>
                  <div>
                    <Title>CollectionID:</Title> {item.collectionID}
                  </div>
                  <div>
                    <Title>PresetId:</Title> {item.presetID}
                  </div>
                  <div>
                    <Title>Date:</Title> {item.date}
                  </div>
                  <div>
                    <Title>LogID:</Title> {item.logID}
                  </div>
                  <div>
                    <Title>Updates:</Title>
                    {item.updates.map((updt: any) => (
                      <UpdatesContainer key={updt.key}>
                        <div>
                          <Title>Key:</Title> {updt.key}
                        </div>
                        <div>
                          <Title>Value:</Title>
                          {updt.val}
                        </div>
                      </UpdatesContainer>
                    ))}
                  </div>
                </div>
                <RevertLog id={item.logID} />
              </Grid>
            ))}
            {logs?.length === 0 && <div>Nothing found</div>}
          </Grid>
        </InfiniteScroll>
      </div>
    </div>
  );
};
