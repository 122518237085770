import React from "react";
import EditIcon from "@mui/icons-material/Edit";
import { IconButton, Typography } from "@mui/material";
import { Wrapper } from "./BaseInfoItem.styles";
import { SvgIconComponent } from "@mui/icons-material";

type BaseInfoItemProps = {
  label: string;
  value: string;
  icon: SvgIconComponent;
  onEdit?: React.MouseEventHandler;
};

export const BaseInfoItem = ({
  label,
  value,
  icon: Icon,
  onEdit,
}: BaseInfoItemProps) => {
  return (
    <Wrapper sx={{ display: "flex" }}>
      <Icon sx={{ color: "#D9D9D9", mr: 1 }} />
      <div style={{ flexGrow: 1 }}>
        <Typography fontSize={12} color="rgba(119, 119, 119, 1)">
          {label}
        </Typography>
        <Typography fontSize={14}>{value}</Typography>
      </div>
      {onEdit && (
        <IconButton onClick={onEdit} sx={{ ml: 1 }}>
          <EditIcon sx={{ color: "#777777" }} />
        </IconButton>
      )}
    </Wrapper>
  );
};
