import { Alert, Box, CircularProgress } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { FormWeapon } from "../FormWeapon";
import {
  // formatDataFiles,
  formatDataWeaponConfigCreate, formatDataWeaponConfigCreateV2,
} from "../FormWeapon/contstants";
import { WeaponFormWrapper } from "../FormWeapon/styles";
import { IFormWeapon } from "../FormWeapon/types";
import {
  useGetPerksAndConditions,
  useMarketNameUpdate,
  // useUploadFiles,
} from "../hooks";
import { convertToInitData } from "./constants";
import { useGetMarketName, useGetWeaponConfig } from "./hooks";
import { useWeaponConfigUpdate } from "./hooks/useWeaponConfigUpdate";

export const EditWeaponCofing: React.FC = () => {
  const { id = "" } = useParams();
  const { config, loading, error, refetch } = useGetWeaponConfig(id);
  const {
    marketName,
    loading: loadingMarketName,
    error: errorMarketName,
  } = useGetMarketName(config?.lookup.cgsAdmin?.weapons?.single.weaponSkin);
  const {
    defaultWeaponConditions,
    defaultWeaponPerksSlots,
    loading: loadingPerks,
  } = useGetPerksAndConditions();
  const navigate = useNavigate();

  // const { uploadFiles, error: errorUpload } = useUploadFiles();
  const { updateMarketName, error: errorUpdateMarketName } =
    useMarketNameUpdate();

  const { weaponUpdateConfig, error: errorUpdateConfig } =
    useWeaponConfigUpdate();

  let errorMessageSubmit: string | undefined;

  // if (errorUpload) {
  //   errorMessageSubmit = errorUpload.message;
  // }
  const isV2 = window.location.href.includes("v2");
  if (errorUpdateMarketName) {
    errorMessageSubmit = errorUpdateMarketName.message;
  }

  if (errorUpdateConfig) {
    errorMessageSubmit = errorUpdateConfig.message;
  }

  const onSubmit = async (values: IFormWeapon) => {
    try {
      if(values.skins?.length){
        await weaponUpdateConfig({
          variables: formatDataWeaponConfigCreateV2(values),
        });
      }else {
        await updateMarketName({
          variables: {
            input: {
              attribute: "WeaponSkin",
              marketName: values.marketNameSkin,
              value: values.weaponSkin,
            },
          },
        });
  
        await weaponUpdateConfig({
          variables: formatDataWeaponConfigCreate(values),
        });
      }
      await refetch();
      alert("Сonfiguration of weapon has been changed");
      navigate("..");
    } catch (err) {
      console.log(err);
    }
  };

  const errorLoadedData = error?.message || errorMarketName?.message;

  if (errorLoadedData) {
    return <Alert severity="error">{errorLoadedData}</Alert>;
  }

  if (
    loading ||
    (loadingMarketName )||
    // (!isV2 && !marketName) ||
    !config?.lookup?.cgsAdmin?.weapons?.single ||
    loadingPerks
  ) {
    return (
      <WeaponFormWrapper>
        <Box display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      </WeaponFormWrapper>
    );
  }

  return (
    <FormWeapon
      type="edit"
      initConfigWeaponProps={convertToInitData(
        config.lookup.cgsAdmin.weapons.single,
        isV2 ? "" : marketName?.name || "",
        defaultWeaponConditions,
        defaultWeaponPerksSlots
      )}
      onSubmit={onSubmit}
      errorMessage={errorMessageSubmit}
    />
  );
};
