import { GetUser_lookup_offchainWallets_allHolderAccounts } from "pages/UserProfile/query/__generated__/GetUser";
import { TableColumn } from "components/SortCell/types";
import React from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useGetSendActions } from "pages/UserProfile/components/TopActions/components/RewardsModal";
import { toast } from "react-toastify";
import { getToastPromiseConfig } from "utils/toast";

type GetColumnsParams = {
  handleSetAmount: (type: string, amount: number) => void;
  amount: Record<string, number>;
};

export const getColumns = ({
  amount,
  handleSetAmount,
}: GetColumnsParams): TableColumn<GetUser_lookup_offchainWallets_allHolderAccounts>[] => [
  // {
  //   key: "icon",
  //   label: "",
  //   headerCellProps: {
  //     width: 30,
  //   },
  //   renderCell: (row) => (
  //     <Box
  //       width={30}
  //       component="img"
  //       sx={{
  //         borderRadius: 1,
  //       }}
  //       // src={row?.overview?.content?.asset?.icon || ""}
  //     />
  //   ),
  // },
  {
    key: "name",
    label: "Name",
    getOrderBy: (data) => data?.type,
    headerCellProps: {
      align: "left",
    },
    rowCellProps: {
      align: "left",
    },
    renderCell: (row) => row?.type,
  },
  {
    key: "amount",
    label: "Amount",
    getOrderBy: (data) => data?.amount,
    headerCellProps: {
      align: "left",
    },
    rowCellProps: {
      align: "left",
    },
    renderCell: (row) => row?.amount,
  },
  {
    key: "count",
    label: "Number to add/remove",
    renderCell: (row) => (
      <TextField
        type="number"
        size="small"
        InputProps={{
          inputProps: {
            min: 0,
          },
        }}
        label=""
        value={amount[row?.type]}
        onChange={(e) => handleSetAmount(row.type, +e.target.value)}
      />
    ),
  },
  {
    key: "add",
    label: "",
    renderCell: (row) => <AddButton type={row?.type} num={amount[row?.type]} />,
  },
  {
    key: "remove",
    label: "",
    renderCell: (row) => (
      <RemoveButton
        type={row?.type}
        num={amount[row?.type]}
        currentAmount={row?.amount}
      />
    ),
  },
];

function AddButton(props: { num: number; type: string }) {
  const { sendEnrichedOrbs, sendButter, sendOrbs } = useGetSendActions();

  const handleClick = () => {
    let sendReward;
    if (props.type === "ORB") sendReward = sendOrbs;
    if (props.type === "ENRICHED_ORB") sendReward = sendEnrichedOrbs;
    if (props.type === "BUTTER") sendReward = sendButter;
    if (!sendReward) return;
    toast.promise(sendReward(props.num), getToastPromiseConfig());
  };
  return (
    <Button variant="outlined" onClick={handleClick}>
      Add
    </Button>
  );
}

function RemoveButton(props: {
  num: number;
  type: string;

  currentAmount: number;
}) {
  const { removeCurrency } = useGetSendActions();

  const handleClick = () => {
    toast.promise(
      removeCurrency(-props.num, props.type),
      getToastPromiseConfig()
    );
  };
  const isDisabled = props.num > props.currentAmount;
  return (
    <Button variant="outlined" onClick={handleClick} disabled={isDisabled}>
      Remove
    </Button>
  );
}
