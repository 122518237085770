import React, { useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { Box, Drawer, IconButton, List, Toolbar } from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import { SearchBar } from "components/SearchBar";

import {
  UserCardStyled,
  ListItem,
  StyledDoubleArrow,
  Title,
  ServerContainer,
  getServerType,
} from "./Layout.styles";
import { links } from "./constants";

const widthSide = "260px";

export const Layout: React.FC = () => {
  const nav = useNavigate();
  const [currPage, setPage] = useState("Dashboard");

  const logOut = () => {
    sessionStorage.clear();
    nav("/");
  };

  const { profileObj } = JSON.parse(sessionStorage.getItem("auth") || "{}");

  return (
    <Box style={{ display: "flex" }}>
      <Drawer
        sx={{
          "width": widthSide,
          "& .MuiDrawer-paper": {
            width: widthSide,
            boxSizing: "border-box",
          },
        }}
        variant="permanent"
      >
        <UserCardStyled
          name={profileObj?.givenName}
          email={profileObj?.email}
          src={profileObj?.imageUrl}
        />
        <ServerContainer>{getServerType()}</ServerContainer>
        <List>
          {links.map(({ name, path, icon: Icon }) => (
            <ListItem to={path} key={path} onClick={() => setPage(name)}>
              <>
                <Icon sx={{ color: "#6E6E6E" }} />
                {name}
              </>
            </ListItem>
          ))}
        </List>
      </Drawer>
      <Box width="100%">
        <Box
          display="flex"
          justifyContent="space-between"
          height="70px"
          alignItems="center"
        >
          <Toolbar>
            <StyledDoubleArrow />
            <Title>{currPage}</Title>
            <SearchBar />
          </Toolbar>
          <IconButton
            sx={{ color: "black" }}
            aria-label="upload picture"
            component="label"
            onClick={logOut}
          >
            <LogoutIcon />
          </IconButton>
        </Box>
        <Box
          sx={{ bgcolor: "#D3D3D3", p: 3, minHeight: "calc(100vh - 140px)" }}
        >
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
};
