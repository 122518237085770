import LoginPage from "pages/LoginPage";
import { Layout } from "components/Layout";

import { Route, Routes, Navigate, Outlet } from "react-router-dom";
import { RewardUser } from "pages/RewardUser/RewardUser";
import { Settings } from "pages/Settings/Settings";
import { MiniroyaleImagesPage } from "pages/MiniroyaleImages/MiniroyaleImages";
import { ClanEvents } from "pages/Events/ClanEvents";
import { CgsPage } from "pages/CgsServise/CgsServise";
import { Weapons } from "pages/Weapons";
import { Gears } from "pages/Gears";
import { UserProfile } from "pages/UserProfile";
import { Dashboard } from "pages/Dashboard";
import { Logs } from "pages/Logs";
import { RewardGroup } from "pages/RewardGroup/RewardGroup";
import { ClanPage } from "pages/ClanPage";
import { Makeovers } from "pages/Makeovers";
import { ChangeClanEventPeriod } from "pages/Events/ChangeClanEventPeriod";

function RequireAuth() {
  const auth = JSON.parse(sessionStorage.getItem("auth") || "{}");
  if (auth?.profileObj?.email.includes("faraway.gg")) {
    return <Outlet />;
  } else return <Navigate to="/" />;
}

const App = () => {
  return (
    <Routes>
      <Route path="/" element={<LoginPage />} />
      <Route element={<RequireAuth />}>
        <Route element={<Layout />}>
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/weapons/*" element={<Weapons />} />
          <Route path="/gears/*" element={<Gears />} />
          <Route path="/rewardUser" element={<RewardUser />} />
          <Route path="/miniroyaleImages" element={<MiniroyaleImagesPage />} />
          <Route path="/cgsService" element={<CgsPage />} />
          <Route path="/cgsService/logs" element={<Logs />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/clanEvents" element={<ClanEvents />} />
          <Route
            path="/changeEventPeriod"
            element={<ChangeClanEventPeriod />}
          />
          <Route path="/users/:userId" element={<UserProfile />} />
          <Route path="/clans/:clanId" element={<ClanPage />} />
          <Route path="/rewardGroup" element={<RewardGroup />} />
          <Route path="/makeovers" element={<Makeovers />} />
        </Route>
      </Route>
    </Routes>
  );
};

export default App;
