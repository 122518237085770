import { GearForm, GearSettings, Randomization, Slot } from "../GearForm/types";
import { GearConfigUpdateVariables } from "./hooks/useWeaponConfigUpdate/__generated__/GearConfigUpdate";


export const convertToInitData = (
  {
    randomization,
    ...props
  }: GearForm,
  availableGearRarities: string[],
  availableGearAlignments: string[],
  availableGearTypes: string[],
  availableGearStatNames: string[],
  availableGearAllegiances: string[],
  availableGearSets: string[] 
): GearForm => {

  const getConditions = (defaultConditions: string[], type: "rarity" | "gearType" | "setType" | "alignment" | "allegiance") => {
    return defaultConditions.map((itemRarity) => {
      const includedSlot = randomization?.[type].variations.find(
        (item) => item.name === itemRarity
      );

      if (includedSlot) {
        return {...includedSlot, checked: true };
      } else return { name: itemRarity, weight: 1, checked: type === "allegiance" };
    });
  };

  const newPrimaryStatVariation = availableGearStatNames.map((primStat) => {
    const includedSlot = randomization?.primaryStat.name.variations.find(
      (item) => item.name === primStat
    );

    if (includedSlot) {
      return {...includedSlot, checked: true };
    } else return { name: primStat, weight: 1, checked: false };
  });

  const getNewSecondaryStats = (currentVariations: Slot[]) => {
    return availableGearStatNames.map((primStat) => {
      const includedSlot = currentVariations.find(
        (item) => item.name === primStat
      );
  
      if (includedSlot) {
        return {...includedSlot, checked: true };
      } else return { name: primStat, weight: 1, checked: false };
    });
  };

  
  const newRarity: GearSettings = {...randomization?.rarity, variations: getConditions(availableGearRarities, "rarity")};
  const newGearType: GearSettings = {...randomization?.gearType, variations: getConditions(availableGearTypes, "gearType")};
  const newSetType: GearSettings = {...randomization?.setType, variations: getConditions(availableGearSets, "setType")};
  const newAlignment: GearSettings = {...randomization?.alignment, variations: getConditions(availableGearAlignments, "alignment")};
  const newAllegiance: GearSettings = {...randomization?.allegiance, variations: getConditions(availableGearAllegiances, "allegiance")};

  const newPrimaryStat: {name: GearSettings } = {...randomization?.primaryStat, name: {...randomization?.primaryStat.name, variations: newPrimaryStatVariation}};

  const newSecondaryStats: {name: GearSettings }[] = randomization?.secondaryStats.map((item) => ({...item, name: {...item.name, variations: getNewSecondaryStats(item.name.variations)}})) || [];


  const newRandomization: Randomization = {...randomization,
    rarity: newRarity,
    gearType: newGearType,
    setType: newSetType,
    alignment: newAlignment,
    allegiance: newAllegiance,
    primaryStat: newPrimaryStat,
    secondaryStats: newSecondaryStats,
  };

  return {
    ...props,
    randomization: newRandomization
  };
};

export const editDataBeforeRequest = (
  {
    randomization,
    ...props
  }: GearForm
): GearConfigUpdateVariables => {
  
  const newRarity: GearSettings = {...randomization?.rarity, variations: randomization?.rarity?.variations.filter(item => item.checked).map(({checked, ...rest}) => rest) || []};
  const newGearType: GearSettings = {...randomization?.gearType, variations: randomization?.gearType?.variations?.filter(item => item.checked).map(({checked, ...rest}) => rest) || []};
  const newSetType: GearSettings = {...randomization?.setType, variations: randomization?.setType?.variations?.filter(item => item.checked).map(({checked, ...rest}) => rest) || []};
  const newAlignment: GearSettings = {...randomization?.alignment, variations: randomization?.alignment?.variations?.filter(item => item.checked).map(({checked, ...rest}) => rest) || []};
  const newAllegiance: GearSettings = {...randomization?.allegiance, variations: randomization?.allegiance?.variations?.filter(item => item.checked).map(({checked, ...rest}) => rest) || []};

  const newPrimaryStatVariations = randomization?.primaryStat?.name.variations?.filter(item => item.checked).map(({checked, ...rest}) => rest);
  const newPrimaryStat: {name: GearSettings } = {...randomization?.primaryStat, name: {...randomization?.primaryStat.name, variations: newPrimaryStatVariations || []}};

  const newSecondaryStats: {name: GearSettings }[] = randomization?.secondaryStats.map(item => ({...item, name: {variations: item.name.variations?.filter(item => item.checked).map(({checked, ...rest}) => rest)}})) || [];

  const newRandomization: Randomization = {...randomization,
    rarity: newRarity,
    gearType: newGearType,
    setType: newSetType,
    alignment: newAlignment,
    allegiance: newAllegiance,
    primaryStat: newPrimaryStat,
    secondaryStats: newSecondaryStats,
  };

  const isNew = props.id.includes("Gear-");

  return {
    ...props,
    minMintLevel: +props.minMintLevel,
    id: isNew ? props.id : `Gear-${props.id}`,
    randomization: newRandomization
  };
};