import { useState } from "react";
import { Typography } from "@mui/material";
import { useQuery } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { GET_NEW_USERS } from "pages/Dashboard/query/getNewUsers.graphql";
import { StyledPopup } from "components/Popup";

import { GetNewUsers } from "pages/Dashboard/query/__generated__/GetNewUsers";

import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  HeaderContanier,
  StyledButton,
  StyledArrow,
} from "./PlayersTable.styles";
import {
  Paper,
  TableRow,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  TableHead,
} from "@mui/material";

export const PlayersTable = () => {
  const nav = useNavigate();

  const [anchorEl, setAnchorEl] = useState<SVGSVGElement | null>(null);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickPopoverClick = (
    event: React.MouseEvent<SVGSVGElement, MouseEvent>
  ) => {
    setAnchorEl(event.currentTarget);
  };

  const { data } = useQuery<GetNewUsers>(GET_NEW_USERS);

  const users = data?.lookup.accounts.search?.items;

  return (
    <TableContainer
      component={Paper}
      sx={{ padding: "0 10px", boxSizing: "border-box" }}
    >
      <HeaderContanier>
        <Typography fontSize="18px" fontWeight={700}>
          {" "}
          New players
        </Typography>
        {/* <StyledButton onClick={() => nav("/players")} size="large">View all <StyledArrow  /></StyledButton> */}
      </HeaderContanier>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Email</TableCell>
            <TableCell>Username</TableCell>
            <TableCell>Suffix</TableCell>
            <TableCell>Discord name</TableCell>
            <TableCell>Verification</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {users?.map((item, i) => (
            <TableRow
              key={i}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                "backgroundColor": `${i % 2 === 0 ? "#fafbfa" : "#fff"}`,
              }}
            >
              <TableCell
                onClick={() => nav(`/users/${item.userId}`)}
                component="th"
                scope="row"
                sx={{ cursor: "pointer" }}
              >
                {item.personalInfo.email}
              </TableCell>
              <TableCell>{item.ident.username}</TableCell>
              <TableCell>{item.ident.suffix}</TableCell>
              <TableCell>{item.overview.discord?.username}</TableCell>
              <TableCell align="right">
                <MoreVertIcon
                  onClick={handleClickPopoverClick}
                  sx={{ cursor: "pointer" }}
                />
              </TableCell>
              <StyledPopup
                onClick={() => undefined}
                handleClose={handleClose}
                anchorEl={anchorEl}
              >
                <>
                  <div onClick={() => nav(`/users/${item.userId}`)}>View</div>
                  <div onClick={() => nav(`/users/${item.userId}?edit=true`)}>
                    Edit
                  </div>
                </>
              </StyledPopup>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
