import { gql } from "@apollo/client";

export const queryGetAvailableData = gql`
  query GetAvailableData {
    lookup {
      cgsAdmin {
        attributes {
          availableWeaponTypes
          availableDropTypes
          availableSeasons
          availableNameCounters
        }
      }
    }
  }
`;
