import { Button, Box } from "@mui/material";
import { PerkWrapperStyled } from "./styled";
import { useFormikContext, FieldArray } from "formik";
import { GearForm, Slot } from "../../types";
import { PerkItem } from "./PerkItem";
import { generateStatsData } from "../../contstants";


export const SecondaryStats = () => {
  const { values, handleChange } = useFormikContext<GearForm>();

  return (
    <PerkWrapperStyled>
      <FieldArray
        name="randomization.secondaryStats"
        render={({ push }) => {
          return (
            <div>
              {values.randomization?.secondaryStats.map((sStat, i) => {
                return <Box key={i} sx={{mt: 5}}>
                  SecondaryStat {i + 1}
                  {sStat.name.variations?.map((perk: Slot, perkItemIndex: number) => (
                    <PerkItem
                      perk={perk}
                      key={perk.name}
                      name={`randomization.secondaryStats.${i}.name.variations.${perkItemIndex}`}
                      handleChange={handleChange}
                    />
                  ))}</Box>;
              })}
              <Button
                variant="contained"
                size="small"
                onClick={() => push(generateStatsData())}
              >
                    Add Secondary Stats
              </Button>
            </div>
          );
        }}
      />
    </PerkWrapperStyled>
  );
};
