import {
  Box,
  Card,
  CardActions,
  CardContent,
  Typography,
  Button,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

interface GearsCardProps {
  id: string;
}

export const GearsCard = ({ id }: GearsCardProps) => {
  const navigate = useNavigate();

  return (
    <Box width="25%" py={1}>
      <Box px={2}>
        <Card variant="outlined" sx={{ height: "128px", display: "flex", flexDirection: "column", justifyContent: "space-between"}}>
          <CardContent>
            <Typography variant="body1" component="div">
              {id}
            </Typography>
          </CardContent>
          <CardActions
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            <Button size="small" onClick={() => navigate(id)}>
              Edit config
            </Button>
            <Button size="small" onClick={() => navigate(`duplicate/${id}`)}>
              Duplicate config
            </Button>
          </CardActions>
        </Card>
      </Box>
    </Box>
  );
};
