import { gql } from "@apollo/client";

export const offchainWalletsChangeBalanceMutation = gql`
  mutation offchainWalletsChangeBalance(
    $userId: UUID!
    $reasonId: UUID!
    $amount: Int!
    $type: String!
  ) {
    offchainWalletsChangeBalance(
      input: {
        holderID: $userId
        idempotencyKey: $reasonId
        amount: $amount
        accountType: $type
      }
    ) {
      amount
      type
      createdAt
      updatedAt
    }
  }
`;
