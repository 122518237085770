import { styled } from "@mui/system";

export const Title = styled("span")({
  display: "inline-block",
  marginRight: "6px",
  color: "grey",
  padding: "4px",
});

export const UpdatesContainer = styled("div")({
  marginLeft: "16px",
  padding: "6px",
});
