import { useEffect, useState } from "react";
import { TextField } from "@mui/material";
import moment from "moment";

type Props = {
  name: string;
  value: number;
  onChange: (value: number) => any;
};
const dateMaxLength = 10;
const timeMaxLength = 5;
const dateRegex = /^\d\d\d\d-\d\d-\d\d$/;
const timeRegex = /^\d\d:\d\d$/;

// Interpret date and time as a timestamp in UTC+0 and return unix seconds or undefined
function parseTimestamp(date: string, time: string): number | undefined {
  if (!date.match(dateRegex)) return; // check match for "2023-01-02" format
  if (!time.match(timeRegex)) return; // check match for "15:59" format
  const m = moment(`${date} ${time} +00:00`, "YYYY-MM-DD HH:mm Z", true);

  return m.isValid() ? m.unix() : undefined;
}

export const TimestampPicker = ({ name, value, onChange }: Props) => {
  const [dateString, setDateString] = useState("");
  const [timeString, setTimeString] = useState("");
  const [parsingFailed, setParsingFailed] = useState(false);

  // If the user tries to delete characters, prevent auto-insertion of dashes and colons
  const [isBackspaceUsed, setIsBackspaceUsed] = useState(false);
  const onKeyDown = (e: any) => setIsBackspaceUsed(e.code === "Backspace");

  // When 'value' changes, update the text fields
  useEffect(() => {
    const m = moment(value * 1000).utc();
    setDateString(m.format("YYYY-MM-DD"));
    setTimeString(m.format("HH:mm"));
  }, [value]);

  // When something is typed in, try to parse it and call 'onChange'
  useEffect(() => {
    const unix = parseTimestamp(dateString, timeString);
    if (unix) onChange(unix);
    setParsingFailed(!unix);

    // Auto format user input
    setDateString((v) => autoFormatDate(v, isBackspaceUsed));
    setTimeString((v) => autoFormatTime(v, isBackspaceUsed));
  }, [dateString, timeString]);

  return (
    <div style={{ display: "flex", gap: "12px" }}>
      <TextField
        label={`${name} date`}
        title="Use the YYYY-MM-DD format (in UTC+0)"
        error={parsingFailed}
        value={dateString}
        onChange={(e: any) => setDateString(e.target.value)}
        onKeyDown={onKeyDown}
      />
      <TextField
        label={`${name} time`}
        title="Use the HH:MM format (in UTC+0)"
        error={parsingFailed}
        value={timeString}
        onChange={(e: any) => setTimeString(e.target.value)}
        onKeyDown={onKeyDown}
      />
    </div>
  );
};

// Auto format functions that add separators and remove invalid characters
function autoFormatDate(date: string, isBackspaceUsed: boolean): string {
  // Remove everything that is not digit (\d) or dash (-)
  date = date.replaceAll(/[^\d-]/g, "");
  // Remove trailing extra characters
  if (date.length > dateMaxLength) date = date.substring(0, dateMaxLength);
  // Do not hinder user from deleting dashes
  if (isBackspaceUsed) return date;
  // Auto add dash after YYYY
  if (date.match(/^\d\d\d\d$/)) date += "-";
  // Auto add dash after YYYY-MM
  if (date.match(/^\d\d\d\d-\d\d$/)) date += "-";
  return date;
}

function autoFormatTime(time: string, isBackspaceUsed: boolean): string {
  // Remove everything that is not digit (\d) or colon (:)
  time = time.replaceAll(/[^\d:]/g, "");
  // Remove trailing extra characters
  if (time.length > timeMaxLength) time = time.substring(0, timeMaxLength);
  // Do not hinder user from deleting the colon
  if (isBackspaceUsed) return time;
  // Auto add colon after HH
  if (time.match(/^\d\d$/)) time += ":";
  return time;
}
