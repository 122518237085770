import { useQuery } from "@apollo/client";
import { getListMintableItemConfigGraphql } from "./getListMintableItemConfig.graphql";
import {
  GetListMintableItemConfigGraphql,
  GetListMintableItemConfigGraphqlVariables,
} from "./__generated__/GetListMintableItemConfigGraphql";

export const useGetListMintableItemConfig = (
  input: GetListMintableItemConfigGraphqlVariables
) => {
  const { data, loading, error, fetchMore, networkStatus } = useQuery<
    GetListMintableItemConfigGraphql,
    GetListMintableItemConfigGraphqlVariables
  >(getListMintableItemConfigGraphql, {
    variables: {
      ...input,
    },
    notifyOnNetworkStatusChange: true,
  });

  return {
    data: data?.lookup.cgsAdmin?.weapons?.search || { configs: [], nextPageToken: "" },
    loading,
    error,
    fetchMore,
    networkStatus,
  };
};
