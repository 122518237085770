import React from "react";
import { Button, Typography } from "@mui/material";
import { useMutation } from "@apollo/client";
import moment from "moment";
import { download } from "utils/downloadGlb";

import { HeaderContanier, Img, DownloadIconStyled, Box , Cell, ModalContainer, List, TextureTitle} from "./Makeovers.style";
import {
  Paper,
  TableRow,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  TableHead,
} from "@mui/material";
import { CHANGE_MAKEOVER_STATUS } from "pages/Makeovers/mutations/changeStatus/makeoverChangeStatus";
import { GetMakeovers_lookup_ugc_makeovers_search_makeovers } from "pages/Makeovers/query/__generated__/GetMakeovers";
import { client } from "apolloClient";
import { GET_MAKEOVERS } from "pages/Makeovers/query/getMakeovers.graphql";
import { MakeoverStatus } from "graphql-global-types/types";
import { copyContent } from "utils/copyText";
import { textCut, textCutfromEnd } from "utils/textCutter";
import Modal from "@mui/material/Modal";
import { useState } from "react";
import CropOriginalIcon from "@mui/icons-material/CropOriginal";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Tooltip from "@mui/material/Tooltip";
import { EditFields } from "./EditFields";

interface TableProps {
  data?: (GetMakeovers_lookup_ugc_makeovers_search_makeovers | null)[] | null
}

export const getColorByStatus = (status?: MakeoverStatus) => {
  switch(status){
    case (MakeoverStatus.Valid): return "#cbd938";
    case (MakeoverStatus.Approved): return "#55c439";
    case (MakeoverStatus.Deployed): return "#1a5910";
    default: return "#8c3535";
  }
};

export const MakeoversTable = ({data}: TableProps) => {

  const [isOpen, setOpen] = useState(false);
  const [currenttextures, setTextures] = useState<GetMakeovers_lookup_ugc_makeovers_search_makeovers | null>(null);
  const [ changeStatus ] = useMutation(CHANGE_MAKEOVER_STATUS);

  const changeMakeoverStatus = (status: MakeoverStatus, id?: string) => {
    changeStatus({ variables: {
      status: status,
      makeoverId: id || "",
    }}).then( () => {
     client.refetchQueries({
        include: [GET_MAKEOVERS],
      }).then(() => window.location.reload());
    });
  };

  const openModal = (item: GetMakeovers_lookup_ugc_makeovers_search_makeovers) => {
    setOpen(true);
    setTextures(item);
  };

  return (
    <>
    <TableContainer
      component={Paper}
      sx={{ padding: "0 10px", boxSizing: "border-box" }}
    >
      <HeaderContanier>
        <Typography fontSize="18px" fontWeight={700}>
          Makeovers
        </Typography>
      </HeaderContanier>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Id</TableCell>
            <TableCell>Date</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Author</TableCell>
            <TableCell>Price</TableCell>
            <TableCell>ID</TableCell>
            <TableCell>Status</TableCell>
            <TableCell align="center">Textures</TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data && data?.map((item, i: number) => {
            return (
            <TableRow
              key={i}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                "backgroundColor": `${i % 2 === 0 ? "#fafbfa" : "#fff"}`,
                height: "75px"
              }}
            >
              <Cell style={{ cursor: "context-menu"}}  onClick={() => copyContent(item?.id)}>
               {textCut(item?.id)}
              </Cell>
              <TableCell>{moment(item?.createdAt).format("MMM DD YYYY")}</TableCell>
              {item && <EditFields item={item} />}
              <TableCell>{textCutfromEnd(item?.modelId)}</TableCell>
              <TableCell 
                sx={{ backgroundColor: getColorByStatus(item?.status), borderRadius: "25px"}}
              >
                <Box sx={{width: "100px", margin: "0 auto"}}>
                  <div>{item?.status}</div>
                  {item?.status === MakeoverStatus.Invalid && item.invalidityReason}
                </Box>
              </TableCell>
              <TableCell align="center" onClick={() => item && openModal(item)}>
                {item?.previewIcon ? 
                <Tooltip title={<img src={item?.previewIcon} width={150} height={150} />}>
                  <img src={item?.previewIcon || ""} width={30} height={30} alt="icon" />
                </Tooltip> : <CropOriginalIcon /> }
                </TableCell>
              <TableCell>
                  <DownloadIconStyled onClick={() => item && download(item.previewModel)} />
                </TableCell>
                <TableCell>
                <VisibilityIcon onClick={() => window.open(`https://playcanvas.com/viewer?load=${encodeURIComponent(item?.previewModel as string)}`)} />
                </TableCell>
              <TableCell>
                {(item?.status === MakeoverStatus.Rejected || item?.status === MakeoverStatus.Valid) && <Button 
                variant="contained"
                 color="success" 
                 onClick={() => changeMakeoverStatus(MakeoverStatus.Approved, item?.id)}
                 >
                  Approve
                  </Button>}
                </TableCell>
              <TableCell>
               {[MakeoverStatus.Valid, MakeoverStatus.Approved, MakeoverStatus.Deployed].includes(item?.status as MakeoverStatus) && <Button
                 variant="contained" 
                 color="error" 
                 onClick={() => changeMakeoverStatus(MakeoverStatus.Rejected, item?.id)}
                 >
                  Reject
                  </Button>}
                  </TableCell>
            </TableRow>
          );})}
        </TableBody>
      </Table>
    </TableContainer>
      <Modal 
        open={isOpen}
        onClose={() => setOpen(false)} >
        <ModalContainer>
        <TextureTitle>{currenttextures?.name}</TextureTitle>
          <List>
            {currenttextures?.resources.map((texture) => 
            <React.Fragment key={texture.name}>
              <Img
              src={texture.address}
                alt="texture"
                />
            </React.Fragment>
              )}
              </List>
        </ModalContainer>
      </Modal>
    </>
  );
};
