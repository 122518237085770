import React, { useState } from "react";
import moment from "moment";
import Button from "@mui/material/Button";
import { Dialog, Input, Menu, MenuItem, Paper } from "@mui/material";
import { Box } from "@mui/system";
import { useMutation, useQuery } from "@apollo/client";
import { CLAN_EVENT_UPDATE_PERIOD } from "mutations/event";
import { toast } from "react-toastify";
import { getToastPromiseConfig } from "utils/toast";
import { GET_EVENT } from "query/event";
import {
  formatDateForServer,
  formatPeriod,
  getInitialDate,
  validateRange,
} from "../../utils/dateTimeHelper";
import { TimestampPicker } from "./components/TimestampPicker";

export const ChangeClanEventPeriod = () => {
  const [uuidString, setUuidString] = useState("");
  // Dates are stored as unix seconds, to prevent bugs with mutable Moment objects
  const [startDate, setStartDate] = useState(getInitialDate);
  const [endDate, setEndDate] = useState(getInitialDate);

  // Dropdown that allows selecting an existing event's period
  const [dropdownAnchorEl, setDropdownAnchorEl] = useState<HTMLElement | null>(
    null
  );
  const closeDropdown = () => setDropdownAnchorEl(null);
  function onDropdownItemClick(clanEvent: any) {
    closeDropdown();
    setUuidString(clanEvent.id);
    setStartDate(moment(clanEvent.startAt).unix());
    setEndDate(moment(clanEvent.endAt).unix());
  }

  // validationStatus is either the warning string or null if all OK
  const [validationStatus, setValidationStatus] = useState<string | null>(null);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);

  const uuidInputProps = {
    value: uuidString,
    onChange: handleUuidInputChange,
  };
  const cancelDialog = () => setShowConfirmDialog(false);

  // API interaction
  const [sendUpdateRequest, { error: requestError }] = useMutation(
    CLAN_EVENT_UPDATE_PERIOD,
    {
      variables: {
        id: uuidString,
        startAt: formatDateForServer(startDate),
        endAt: formatDateForServer(endDate),
      },
    }
  );
  const { data: eventData } = useQuery(GET_EVENT, { fetchPolicy: "no-cache" });

  // Click handler
  function handleUpdate() {
    const validationError = validateRange(startDate, endDate);
    if (validationError) {
      setValidationStatus(validationError);
      return;
    }
    setValidationStatus(null);
    setShowConfirmDialog(true);
  }

  // When an existing event UUID is entered, put its start and end time into inputs
  function handleUuidInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    const uuid = e.target.value;
    setUuidString(uuid);

    const events = eventData?.lookup.clanEvents?.events;
    const matchedEvent = events?.find((ev: any) => ev.id === uuid);
    if (matchedEvent) {
      setStartDate(moment(matchedEvent.startAt).unix());
      setEndDate(moment(matchedEvent.endAt).unix());
    }
  }

  // Once user types "confirm", close dialog and send the update request
  function handleConfirmInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.value.toLowerCase() !== "confirm") return;
    setShowConfirmDialog(false);
    toast.promise(sendUpdateRequest(), getToastPromiseConfig());
  }

  return (
    <Box
      component={Paper}
      display="flex"
      flexDirection="column"
      rowGap={2}
      m={2}
      p={2}
      maxWidth={600}
    >
      <p>Change Event Period</p>

      <Input placeholder="Event UUID" {...uuidInputProps} />

      <Button onClick={(e) => setDropdownAnchorEl(e.currentTarget)}>
        Select from an existing event…
      </Button>
      <Menu
        anchorEl={dropdownAnchorEl}
        open={!!dropdownAnchorEl}
        onClose={closeDropdown}
      >
        {eventData?.lookup.clanEvents?.events.map((event: any) => (
          <MenuItem onClick={() => onDropdownItemClick(event)} key={event.id}>
            {event.name}:{" "}
            {formatPeriod(moment(event.startAt), moment(event.endAt))}
          </MenuItem>
        ))}
      </Menu>

      <TimestampPicker name="Start" value={startDate} onChange={setStartDate} />
      <TimestampPicker name="End" value={endDate} onChange={setEndDate} />
      <Button variant="contained" disabled={!uuidString} onClick={handleUpdate}>
        UPDATE
      </Button>
      <div style={{ color: "red" }}>{validationStatus}</div>
      {requestError?.graphQLErrors && (
        <div style={{ fontFamily: "monospace", color: "red" }}>
          {requestError?.graphQLErrors?.map((i) => i.message)}
        </div>
      )}

      <Dialog open={showConfirmDialog} style={{ backdropFilter: "blur(5px)" }}>
        <Box
          component={Paper}
          p={5}
          display="flex"
          flexDirection="column"
          rowGap={2}
        >
          <div>Please type &quot;confirm&quot; to update clan event</div>
          <Input placeholder="confirm" onChange={handleConfirmInputChange} />
          <Button variant="contained" onClick={cancelDialog}>
            Cancel
          </Button>
        </Box>
      </Dialog>
    </Box>
  );
};
