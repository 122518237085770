import { useMutation } from "@apollo/client";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import cl from "clsx";
import { Box } from "components/Box";
import { useClanContext } from "pages/ClanPage/context";
import { DialogChangeRole } from "pages/ClanPage/dialogs/DialogChangeRole";
import { clanKickMember } from "pages/ClanPage/mutations/clanKickMember";
import { GetClan_lookup_clans_clan_members } from "pages/ClanPage/query/__generated__/GetClan";
import { HeaderContanier } from "pages/Dashboard/components/PlayersTable/PlayersTable.styles";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { getToastPromiseConfig } from "utils/toast";
import s from "../ClanPage.module.css";

export function MembersTable(props: {
  members: GetClan_lookup_clans_clan_members[];
}) {
  const nav = useNavigate();
  const { refetch } = useClanContext();
  const [kickMember] = useMutation(clanKickMember);

  const [anchorEl, setAnchorEl] = useState<SVGSVGElement | null>(null);
  const [filter, setFilter] = useState("");
  const [selectedMember, setSelectedMember] =
    useState<GetClan_lookup_clans_clan_members>();
  const [isChangeRoleOpen, setIsChangeRoleOpen] = useState(false);

  const onClickKick = (member: GetClan_lookup_clans_clan_members) => {
    if (!member) return;
    toast.promise(
      kickMember({
        variables: { id: member?.account.userId },
      }),
      getToastPromiseConfig()
    );
    setTimeout(refetch, 500);
    setAnchorEl(null);
  };

  const onChangeRoleClick = (member: GetClan_lookup_clans_clan_members) => {
    setSelectedMember(member);
    setIsChangeRoleOpen(true);
    setAnchorEl(null);
  };

  const filteredMembers = filter
    ? props.members.filter((member) => {
        if (
          member.account.userId.toLowerCase().startsWith(filter.toLowerCase())
        )
          return true;
        if (
          member.account.personalInfo.email
            .toLowerCase()
            .startsWith(filter.toLowerCase())
        )
          return true;
        if (
          member.account.ident.username
            .toLowerCase()
            .startsWith(filter.toLowerCase())
        )
          return true;
        if (
          member.account.ident.suffix
            .toLowerCase()
            .startsWith(filter.toLowerCase())
        )
          return true;
        return false;
      })
    : props.members || [];
  console.log("anchorEl", anchorEl);
  console.log("selectedMember", selectedMember);

  return (
    <>
      <TableContainer
        component={Paper}
        sx={{ padding: "10px", boxSizing: "border-box", marginTop: 2 }}
      >
        <Box alignItems="center" justifyContent="space-between">
          <Typography fontSize={16} fontWeight={700}>
            Clan Members
          </Typography>
          <TextField
            size="small"
            placeholder="filter"
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
          />
        </Box>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Email</TableCell>
              <TableCell>Username</TableCell>
              <TableCell>Suffix</TableCell>
              <TableCell>Role</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredMembers.map((member, i) => (
              <TableRow
                key={member.account.userId}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                  "backgroundColor": `${i % 2 === 0 ? "#fafbfa" : "#fff"}`,
                }}
              >
                <TableCell
                  onClick={() => nav(`/users/${member.account.userId}`)}
                  component="th"
                  scope="row"
                  sx={{ cursor: "pointer" }}
                >
                  <div className={cl(s.pointer, s.underline)}>
                    {member.account.personalInfo.email}
                  </div>
                </TableCell>
                <TableCell>{member.account.ident.username}</TableCell>
                <TableCell>{member.account.ident.suffix}</TableCell>
                <TableCell>{member.role}</TableCell>
                <ActionCell
                  member={member}
                  onChangeRoleClick={onChangeRoleClick}
                  onClickKick={onClickKick}
                />
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {isChangeRoleOpen && (
        <DialogChangeRole
          onClose={() => setIsChangeRoleOpen(false)}
          member={selectedMember}
        />
      )}
    </>
  );
}

type ActionCellProps = {
  member: GetClan_lookup_clans_clan_members;
  onChangeRoleClick: any;
  onClickKick: any;
};

export const ActionCell = (props: ActionCellProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const nav = useNavigate();
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <TableCell>
      <IconButton onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      {open && (
        <Menu id="basic-menu" anchorEl={anchorEl} open onClose={handleClose}>
          <MenuItem
            key="edit"
            onClick={() => nav(`/users/${props.member.account.userId}`)}
          >
            View
          </MenuItem>

          {props.member.role !== "LEADER" && (
            <MenuItem
              key="edit"
              onClick={() => props.onChangeRoleClick(props.member)}
            >
              Change Role
            </MenuItem>
          )}

          {props.member.role !== "LEADER" && (
            <MenuItem
              sx={{ color: "#f44336" }}
              key="delete"
              onClick={() => props.onClickKick(props.member)}
            >
              Kick
            </MenuItem>
          )}
        </Menu>
      )}
    </TableCell>
  );
};
