import { useEffect } from "react";
import { useLazyQuery } from "@apollo/client";
import { queryGetAvailableSkin } from "./availableSkins.graphql";
import { GetAvailableSkin } from "./__generated__/GetAvailableSkin";
import { useField } from "formik";

export const useGetAvailableSkinData = (index?: number) => {
  const [fetchSkins, { data, loading, error }] = useLazyQuery<GetAvailableSkin>(
    queryGetAvailableSkin
  );
  const [season] = useField("season");
  const [weaponType] = useField("weaponType");
  const [model] = useField(`skins.${index}.model`);
  const [skin] = useField(`skins.${index}.skin`);

  useEffect(() => {
    fetchSkins({
      variables: { input: { season: season.value, weaponType: weaponType.value, model: model.value, skin: skin.value } },
      fetchPolicy: "no-cache",
    });
  }, [season.value, weaponType.value, skin.value, model.value]);

  return {
    availableModels: data?.lookup.cgsAdmin?.weapons?.availableSkins.filters.models ?? [],
    availableSkins: data?.lookup.cgsAdmin?.weapons?.availableSkins.filters.skins ?? [],
    availableSkinIds: data?.lookup.cgsAdmin?.weapons?.availableSkins.skins ?? [],
    data,
    loading,
    error,
  };
};
