import { gql } from "@apollo/client";

export const CREATE_COLLECTION = gql`
  mutation createCgsCollection(
    $id: String!
    $season: CGSSeason!
    $nameCounter: String!
    $userText: String!
    $name: String!
    $family: String!
    $description: String!
    $tokenNameTemplate: String!
    $dropType: CGSDropType!
  ) {
    cgsAdminCollectionCreate(
      input: {
        id: $id
        userText: $userText
        params: {
          name: $name
          family: $family
          description: $description
          tokenNameTemplate: $tokenNameTemplate
          nameCounter: $nameCounter
          season: $season
          dropType: $dropType
        }
      }
    ) {
      ok
    }
  }
`;

export const UPDATE_COLLECTION = gql`
  mutation updateCgsCollection(
    $id: String!
    $name: String!
    $userText: String!
    $family: String!
    $description: String!
    $tokenNameTemplate: String!
    $season: CGSSeason!
    $nameCounter: String!
    $dropType: CGSDropType!
  ) {
    cgsAdminCollectionUpdate(
      input: {
        id: $id
        userText: $userText
        params: {
          name: $name
          family: $family
          description: $description
          tokenNameTemplate: $tokenNameTemplate
          season: $season
          nameCounter: $nameCounter
          dropType: $dropType
        }
      }
    ) {
      ok
    }
  }
`;

export const CREATE_PRESET = gql`
  mutation createCgsPreset(
    $collectionID: String!
    $presetID: String!
    $attributes: [CGSRawAttributeInput!]
    $name: String!
    $baseRarity: CGSAllocationRarity!
    $minMintLevel: Int!
    $renderMode: String!
    $tokenAmount: Int!
    $background: CGSBackground!
  ) {
    cgsAdminPresetCreateFull(
      input: {
        collectionID: $collectionID
        presetID: $presetID
        attributes: $attributes
        name: $name
        baseRarity: $baseRarity
        renderMode: $renderMode
        minMintLevel: $minMintLevel
        background: $background
        tokenAmount: $tokenAmount
      }
    ) {
      ok
    }
  }
`;

export const UPDATE_PRESET = gql`
  mutation updatePreset(
    $id: String!
    $name: String!
    $baseRarity: CGSAllocationRarity!
    $minMintLevel: Int!
    $iconTokenID: String!
    $iconsAttempt: String!
    $collectionID: String!
    $renderMode: String!
    $makeoverTokenID: String
    $tokenAmount: Int!
    $allowCleanup: Boolean!
    $requireRPM: Boolean!
    $background: CGSBackground!
    $attributes: [CGSAttributeInput!]
  ) {
    cgsAdminPresetUpdate(
      input: {
        id: $id
        collectionID: $collectionID
        name: $name
        baseRarity: $baseRarity
        iconTokenID: $iconTokenID
        iconsAttempt: $iconsAttempt
        minMintLevel: $minMintLevel
        renderMode: $renderMode
        background: $background
        tokenAmount: $tokenAmount
        attributes: $attributes
        allowCleanup: $allowCleanup
        requireRPM: $requireRPM
        makeoverTokenID: $makeoverTokenID
      }
    ) {
      ok
    }
  }
`;

export const REGENERATE_TOKENS = gql`
  mutation regenerateTokens($collectionID: String!, $presetID: String!) {
    cgsAdminRegenerateTokens(
      input: { collectionID: $collectionID, presetID: $presetID }
    ) {
      ok
    }
  }
`;

export const DELETE_PRESET = gql`
  mutation deletePreset($id: String!, $collectionID: String!) {
    cgsAdminPresetDelete(input: { id: $id, collectionID: $collectionID }) {
      ok
    }
  }
`;

export const DELETE_COLLECTION = gql`
  mutation deleteCollection($id: String!) {
    cgsAdminCollectionDelete(input: { id: $id }) {
      ok
    }
  }
`;

export const UPDATE_MARKETING_NAMES = gql`
  mutation updateMarketingNames($values: [CGSMarketingNameInput!]) {
    cgsAdminUpdateMarketingNames(input: { values: $values }) {
      ok
    }
  }
`;

export const DEPLOY_MARKETING_NAMES = gql`
  mutation deployMarketingNames($collectionID: String!) {
    cgsAdminDeployMarketingNames(input: { collectionID: $collectionID }) {
      ok
    }
  }
`;

export const GRAPHIC_STATUS = gql`
  mutation getGraphicStatus($collectionID: String!, $presetID: String!) {
    cgsAdminGetGraphicsStatus(
      input: { collectionID: $collectionID, presetID: $presetID }
    ) {
      ok
    }
  }
`;

export const UPDATE_HIDDEN_ATTRIBUTES = gql`
  mutation updateHiddenAttributes($collectionID: String!, $csvFile: String!) {
    cgsAdminUpdateHiddenAttributes(
      input: { collectionID: $collectionID, csvFile: $csvFile }
    ) {
      ok
    }
  }
`;

export const DEPLOY_COLLECTIONS = gql`
  mutation deployCollections($collectionID: String!) {
    cgsAdminDeployCollection(input: { collectionID: $collectionID }) {
      ok
    }
  }
`;

export const LOCK_PRESET = gql`
  mutation lockPreset($collectionID: String!, $id: String!) {
    cgsAdminPresetLock(input: { collectionID: $collectionID, id: $id }) {
      ok
    }
  }
`;

export const OLD_CHARACTERS = gql`
mutation deployOldCaracters {
  cgsAdminDeployOldCharacters {
      ok
  }
}
`;