import { useQuery } from "@apollo/client";
import { queryGetGearConfig } from "./queryGetGearConfig.graphql";
import {
  GetGearConfig,
  GetGearConfigVariables,
} from "./__generated__/GetGearConfig";

export const useGetGearConfig = (id: string) => {
  const { data, loading, error, refetch } = useQuery<
    GetGearConfig,
    GetGearConfigVariables
  >(queryGetGearConfig, {
    variables: {
      input: {
        id,
      },
    },
    fetchPolicy: "no-cache",
  });

  return { config: data, loading, error, refetch };
};
