import { useEffect, useState } from "react";
import { TableCell } from "@mui/material";
import { useMutation } from "@apollo/client";
import { UPDATE_DETAILS } from "pages/Makeovers/mutations/updateDetails/makeoverUpdateDetails.graphql";
import { useNavigate } from "react-router-dom";
import { textCut } from "utils/textCutter";
import { toast } from "react-toastify";
import { getToastPromiseConfig } from "utils/toast";
import { Cell, FlexBox, StyledInput } from "./Makeovers.style";

import EditIcon from "@mui/icons-material/Edit";
import EditOffIcon from "@mui/icons-material/EditOff";

import { GetMakeovers_lookup_ugc_makeovers_search_makeovers } from "pages/Makeovers/query/__generated__/GetMakeovers";

interface EditFiledsProps {
    item: GetMakeovers_lookup_ugc_makeovers_search_makeovers
}

export const EditFields = ({ item }: EditFiledsProps) => {
    const [isEdit, setEdit] = useState("");
    const [currentInput, setInput] = useState({
      name: item.name,
      price: `${item.price}`,
    });
    const nav = useNavigate();
    const isOpen = item?.id !== isEdit;

    useEffect(() => {
        setInput({
            name: item.name,
            price: `${item.price}`,
          });
    }, [item]);

    const [ updateDetails ] = useMutation(UPDATE_DETAILS);

    const handleClick = () => {
        toast.promise(
            updateDetails({ variables: {
                makeoverId: isEdit,
                name: currentInput.name,
                price: +currentInput.price,
              }}),
            getToastPromiseConfig()
          );
        setEdit("");
    };
    return (
        <>
            <TableCell>
                <FlexBox>
                    {!isOpen ? <EditOffIcon onClick={handleClick} /> : <EditIcon onClick={() => setEdit(item?.id)} />}
                    <StyledInput 
                        disabled={isOpen}
                        width="70px"
                        type="text" 
                        value={currentInput.name}
                        onChange={(e) => setInput((prev) => ({...prev, name: e?.target?.value}))}
                    />
                </FlexBox>
            </TableCell>
            <Cell sx={{cursor: "pointer"}}  onClick={() => nav(`/users/${item?.authorId}`)}>{textCut(item?.authorId)}</Cell>
            <TableCell>
                <StyledInput
                    disabled={isOpen}
                    width="45px" 
                    type="text"
                    value={currentInput.price} 
                    onChange={(e) => setInput((prev) => ({...prev, price: e?.target?.value}))}
                />
                SOL
            </TableCell>
        </>
  );
};