import { gql } from "@apollo/client";

export const queryGetAvailableData = gql`
  query GetGearAvailableData {
    lookup {
      cgsAdmin {
        attributes {
          availableGearTypes
          availableSeasons
          availableGearRarities
          availableGearAlignments
          availableGearStatNames
          availableGearAllegiances
          availableGearSets
          availableDropTypes
        }
      }
    }
  }
`;
