import { gql } from "@apollo/client";

export const getListGearsConfig = gql`
  query GetListGearsConfig(
    $query: String
    $limit: Int
    $pageToken: String
  ) {
    lookup {
      cgsAdmin {
        gear {
          search(
            input: {
              query: $query
              pagination: { limit: $limit, pageToken: $pageToken }
            }
          ) {
            configs {
              id
              name
              nameCounterID
              dropType
              minMintLevel
              # randomization
            }
            nextPageToken
          }
        }
      }
    }
  }
`;
