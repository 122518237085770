import { Alert, Box, CircularProgress } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { getToastPromiseConfig } from "utils/toast";

import { FormGear } from "../GearForm";
import { useGetGearConfig } from "./hooks";
import { convertToInitData, editDataBeforeRequest } from "./constants";
import { useGearConfigUpdate } from "./hooks/useWeaponConfigUpdate";
import { useGearConfigCreate } from "../NewGear/hooks";
import { useGetGearAvailableData } from "../GearForm/hooks/useGetAvailableData";
import { GearForm } from "../GearForm/types";

import { GearConfigUpdateVariables } from "./hooks/useWeaponConfigUpdate/__generated__/GearConfigUpdate";

export const EditGearCofing: React.FC = () => {
  const { id = "" } = useParams();
  const { config, loading, error, refetch } = useGetGearConfig(id);

  const {
    availableGearRarities,
    availableGearAlignments,
    availableGearStatNames,
    availableGearAllegiances,
    availableGearSets,
    availableGearTypes,
  } = useGetGearAvailableData();

  const navigate = useNavigate();

  const { gearUpdateConfig, error: errorUpdateConfig } =
  useGearConfigUpdate();

  const { gearConfigCreate } = useGearConfigCreate();

  const isDuplicate = window.location.href.includes("duplicate");

  let errorMessageSubmit: string | undefined;

  if (errorUpdateConfig) {
    errorMessageSubmit = errorUpdateConfig.message;
  }

  const fetchGear = (data: GearConfigUpdateVariables) => isDuplicate ?  gearConfigCreate({
    variables: data,
  }) : gearUpdateConfig({
    variables: data,
  });

  const onSubmit = async (values: GearForm) => {
    const data = editDataBeforeRequest(values);
    toast
      .promise(
        fetchGear(data),
        getToastPromiseConfig()
      )
      .then(() => navigate(".."));
  };

  const errorLoadedData = error?.message;

  if (errorLoadedData) {
    return <Alert severity="error">{errorLoadedData}</Alert>;
  }

  if (loading || !config?.lookup?.cgsAdmin?.gear?.single) {
    return (
      <div>
        <Box display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      </div>
    );
  }

  return (
    <FormGear
      type="edit"
      initConfigWeaponProps={convertToInitData(
        config?.lookup?.cgsAdmin?.gear?.single,
        availableGearRarities,
        availableGearAlignments,
        availableGearTypes,
        availableGearStatNames,
        availableGearAllegiances,
        availableGearSets)}
      onSubmit={onSubmit}
      errorMessage={errorMessageSubmit}
    />
  );
};
