import {
  Typography,
  Button,
  Box,
  Alert,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { GearFormWrapper } from "./styles";
import { Formik, Form } from "formik";

import { TextFieldWrapper } from "./components";
import { SelectWrapper } from "./components/SelectWrapper";
import {
  initForm,
} from "./contstants";

import { RandomizationDrawer } from "./RandomizationDrawer";
import { getSchema } from "./validation";

import { FormGearProps } from "./types";
import { useGetGearAvailableData } from "./hooks/useGetAvailableData";
import { convertToInitData } from "../EditGearConfig/constants";

export const FormGear: React.FC<FormGearProps> = ({
  type = "new",
  onSubmit,
  errorMessage,
  initConfigWeaponProps,
}) => {
  const {
    availableGearRarities,
    availableGearAlignments,
    availableGearStatNames,
    availableGearAllegiances,
    availableGearSets,
    availableGearTypes,
    availableSeasons,
    availableDropTypes,
    loading
  } = useGetGearAvailableData();

  const isDuplicate = window.location.href.includes("duplicate");

  const newFrom = convertToInitData(
    initForm,
    availableGearRarities,
    availableGearAlignments,
    availableGearTypes,
    availableGearStatNames,
    availableGearAllegiances,
    availableGearSets,
  );

  if (loading) {
    return (
      <GearFormWrapper>
        <Box display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      </GearFormWrapper>
    );
  }

  return (
    <Formik
      initialValues={initConfigWeaponProps ?? newFrom}
      onSubmit={onSubmit}
      validationSchema={getSchema()}
    >
      {({ isSubmitting }) => {
        return (
          <GearFormWrapper>
            <Form>
              <TextFieldWrapper
                name="id"
                label="Id"
                disabled={type === "edit" && !isDuplicate}
              />
              <TextFieldWrapper
                label="Name"
                name="name"
              />
              <TextFieldWrapper name="nameCounterID" label="Name Counter ID" />
              <TextFieldWrapper name="minMintLevel" label="Min Mint Level" />
              <SelectWrapper
                opts={availableSeasons}
                label="Season"
                name="season"
              />
              <SelectWrapper
                opts={availableDropTypes}
                label="Drop Type"
                name="dropType"
              />

              <Box display="flex" justifyContent="space-between">
                <Typography variant="h6" gutterBottom component="div">
                  Randomization
                </Typography>
                <RandomizationDrawer />
              </Box>

              {errorMessage && !isSubmitting ? (
                <Alert severity="error">{errorMessage}</Alert>
              ) : null}

              <Box display="flex" justifyContent="end">
                <Button
                  type="submit"
                  variant="contained"
                  disabled={isSubmitting}
                >
                  Send
                </Button>
              </Box>
            </Form>
          </GearFormWrapper>
        );
      }}
    </Formik>
  );
};
