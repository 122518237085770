import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import { UPDATE_HIDDEN_ATTRIBUTES } from "mutations/cgsCollections";
import { Accordion, AccordionSummary, AccordionDetails } from "./Accordion";
import Button from "@mui/material/Button";
import { useStyles } from "pages/CgsServise/Cgs.style";
import { IHiddenAttrProps } from "./Cgs.types";

export const HiddenAttributes = ({
  hiddenAttributes,
  refetchPresets,
  collectionID,
}: IHiddenAttrProps) => {
  const [expanded, setExpanded] = useState<string | boolean>("");
  const [expandedValues, setExpandedValues] = useState<string | boolean>("");
  const [csvFile, setFile] = useState<string>("");
  const classes = useStyles();

  const handleAccordionChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  const handleValuesAccordionChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpandedValues(newExpanded ? panel : false);
    };

  const [updateHiddenAttributes] = useMutation(UPDATE_HIDDEN_ATTRIBUTES);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e?.target?.files?.[0];
    const reader = new FileReader();
    reader.onload = function (event) {
      setFile(event?.target?.result as string); // the CSV content as string
    };

    file && reader.readAsText(file);
  };

  return (
    <Accordion
      TransitionProps={{ unmountOnExit: true }}
      expanded={expanded === `${"market-names"}`}
      onChange={handleAccordionChange(`${"market-names"}`)}
      style={{ marginBottom: "8px" }}
    >
      <AccordionSummary
        aria-controls="panel1d-content"
        id="panel1d-header"
        style={{ width: "100%", backgroundColor: "LightSlateGrey" }}
      >
        <div>Hidden Attributes</div>
      </AccordionSummary>
      <AccordionDetails>
        <div>
          <div className={classes.hiddenAttrHeader}>
            <input
              id="file-upload"
              type="file"
              onChange={handleChange}
              accept=".csv"
              style={{ visibility: "hidden", width: "0" }}
            />
            <label htmlFor="file-upload" className={classes.uploader}>
              Upload CSV
            </label>
            <Button
              disabled={!csvFile}
              variant="contained"
              onClick={() =>
                updateHiddenAttributes({
                  variables: {
                    collectionID,
                    csvFile: csvFile,
                  },
                })
                  .then(() => {
                    refetchPresets({ variables: { id: collectionID } });
                    alert("successfull");
                  })
                  .catch((e: Error) => alert(e.message))
              }
            >
              send
            </Button>
          </div>
          {hiddenAttributes?.map((item, i: number) => (
            <React.Fragment key={i}>
              <Accordion
                TransitionProps={{ unmountOnExit: true }}
                expanded={expandedValues === `${i}`}
                onChange={handleValuesAccordionChange(`${i}`)}
              >
                <AccordionSummary
                  aria-controls="panel2d-content"
                  id="panel2d-header"
                >
                  <div>{item.name}</div>
                </AccordionSummary>
                <AccordionDetails style={{ backgroundColor: "white" }}>
                  <div className={classes.hiddenAttrHeader}>
                    <span>Name</span>
                    <span>Weight</span>
                  </div>
                  {item.values.map((valueItem, k: number) => (
                    <div key={k} className={classes.attributeContent}>
                      <span>{valueItem.name}</span>
                      <span>{valueItem.weight}</span>
                    </div>
                  ))}
                </AccordionDetails>
              </Accordion>
            </React.Fragment>
          ))}
        </div>
      </AccordionDetails>
    </Accordion>
  );
};
