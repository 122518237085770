export interface ILookupData {
  lookup: {
    cgsAdmin: {
      characters: {
        collections: ICollection[];
        files: ITreeData[];
        collection: ICollection;
        availableRenderModes: string[];
      };
      tasks: {
        currentTaskProgress: ICurrentTaskProgress;
        tasks: ITask[];
      };
    };
  };
}

export interface ICurrentTaskProgress {
  task: ITask;
  totalTasksInQueue: number;
}

export interface ITask {
  id: string;
  number: number;
  description: string;
  status: TaskStatus;
  error: string;
  progressNow: number;
  progressMax: number;
  nftContentSha: string;
  createDate: string;
  duration: string;
}

export enum TaskStatus {
  TaskStatus_Pending = "TaskStatus_Pending",
  TaskStatus_InProgress = "TaskStatus_InProgress",
  TaskStatus_Finished = "TaskStatus_Finished",
  TaskStatus_Failed = "TaskStatus_Failed",
}

export interface ITreeData {
  name: string;
  children?: ITreeData[];
  attributeName: string;
  attributeValue: string;
  id: string;
  errors: string[] | null;
  selected?: boolean;
  path: string;
}

export interface ICollection {
  id: string;
  userText: string;
  params: {
    name: string;
    family: string;
    description: string;
    tokenNameTemplate: string;
    nameCounter: string;
    season: string;
    dropType: DropType;
  };
  presets: IPreset[];
  preset: IPreset;
  marketingNames: IMarkentingNames[] | null;
  hiddenAttributes: IHiddenAttributes[];
  iconAttempts: string[];
}

export enum DropType {
  LootCollection = "LootCollection",
  Premium = "Premium",
  Special = "Special",
}

export interface IHiddenAttributes {
  name: string;
  probability: number;
  values: IAttrValue[];
}

export interface IMarkentingNames {
  attributeName: string;
  attributeValue: string;
  marketingName: string;
}

export interface IPreset {
  id?: string;
  collectionID?: string;
  name?: string;
  baseRarity?: string;
  minMintLevel?: number;
  iconTokenID?: string;
  renderMode?: string;
  makeoverTokenID?: string; 
  background?: Background;
  iconsAttempt?: string;
  allowCleanup?: boolean;
  generationLock?: boolean;
  RequireRPM?: boolean;
  icons?: {
    tokenID: string;
    imgData: string;
  }[];
  tokenAmount?: number;
  currentTokenAmount?: number;
  attributes?: IAttribute[] | null;
}

export enum Background {
  Random = "Random",
  S3_Blue = "S3_Blue",
  S3_Green = "S3_Green",
  S3_Magenta = "S3_Magenta",
  S3_Yellow = "S3_Yellow",
  S3_God_Blue = "S3_God_Blue",
  S3_God_Green = "S3_God_Green",
  S3_God_Magenta = "S3_God_Magenta",
  S3_God_Yellow = "S3_God_Yellow",
  Random_Sank = "Random_Sank",
  Random_S4 = "Random_S4",
}

export interface IAttribute {
  name: string;
  probability: number;
  values: IAttrValue[];
}

export interface IAttrValue {
  name: string;
  weight: number;
}

export interface ICgsModalProps {
  isOpen: boolean;
  setOpen: (val: boolean) => void;
  collection?: ICollection | string;
  refetchCollections: () => void;
  setCollection?: (val: string) => void;
}

export interface ICollections {
  collections?: ILookupData;
  setData: (filesTree: ITreeData[]) => void;
  TreeRender: (items: ITreeData[]) => JSX.Element[];
  filesData: ITreeData[];
  setDataFromPreset: (data: ISelectedDataFromPreset[]) => void;
  selectedFiles: ITreeData[];
  setItems: React.Dispatch<React.SetStateAction<ITreeData[]>>;
  refetchCollections: () => void;
  errorsList: {
    errors: string[] | null;
    path: string;
  }[];
}

export interface IPresetProps {
  handlePresetEditClick: (collection: ICollection) => void;
  collection: ICollection;
  refetchPresets: (variables: IVariables) => void;
  presets?: IPreset[];
  getPreset: (variables: IVariables) => void;
  presetData?: IPreset;
  refetchPreset: () => void;
  isLoading: boolean;
}

export interface IModalProps {
  isOpen: { status: boolean; isNew: boolean };
  setData: (filesTree: ITreeData[]) => void;
  setOpen: (val: { status: boolean; isNew: boolean }) => void;
  TreeRender: (items: ITreeData[]) => JSX.Element[];
  filesData: ITreeData[];
  collectionId: string;
  setItems: (val: any) => void;
  selectedFiles: ITreeData[];
  setDataFromPreset: (data: any) => void;
  preset?: IPreset;
  refetchCollections: (variables: IVariables) => void;
  refetchPreset: (variables: IVariables) => void;
  errorsList: {
    errors: string[] | null;
    path: string;
  }[];
}

export interface IEditedPreset {
  name: string;
  probability: number;
  values: {
    name: string;
    weight: number;
  }[];
}

export interface ISelectedDataFromPreset {
  attributeName: string;
  attributeValue: string;
}

export interface IErrorlsList {
  errors: string[] | null;
  path: string;
}

export interface IMrapketNamesProps {
  marketNames?: IMarkentingNames[] | null;
  collectionID: string;
  refetchCollections: () => void;
}

export interface IVariables {
  variables: {
    id: string;
    presetID?: string;
  };
}

export interface IHiddenAttrProps {
  hiddenAttributes?: IHiddenAttributes[];
  refetchPresets: (variables: IVariables) => void;
  collectionID: string;
}

export interface IPresetHeaderProps {
  collectionID: string;
  presetID: string;
  isLocked?: boolean;
}
