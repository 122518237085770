import { Typography } from "@mui/material";
import { PerkWrapperStyled } from "./styled";
import { useFormikContext } from "formik";
import { GearForm, Slot } from "../../types";
import { PerkItem } from "./PerkItem";

interface SlotViewProps {
  item: string
}

function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const Perk = ({ item }: SlotViewProps) => {
  const { values, handleChange } = useFormikContext<GearForm>();

  return (
    <PerkWrapperStyled>
      <Typography variant="body2" mb={2}>
        {capitalizeFirstLetter(item)}
      </Typography>

      {(values.randomization as any)?.[item].variations?.map((perk: Slot, perkItemIndex: number) => {
        return (
          <PerkItem
            perk={perk}
            key={perk.name}
            name={`randomization.${item}.variations.${perkItemIndex}`}
            handleChange={handleChange}
          />
        );
      })}
    </PerkWrapperStyled>
  );
};
