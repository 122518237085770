import { gql } from "@apollo/client";

export const clanMemberChangeRole = gql`
  mutation clanChangeRole($playerID: UUID!, $newRole: String!) {
    clanChangeRole(input: { playerID: $playerID, newRole: $newRole }) {
      ok
    }
  }
`;

// newRole: "MEMBER" | "OFFICER", "LEADER"
