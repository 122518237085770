import { HeaderCard } from "./components/HeaderCard";
import { PlayersTable } from "./components/PlayersTable";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import ShieldIcon from "@mui/icons-material/Shield";
import { Container } from "./Dashboard.styles";

export const Dashboard = () => {
  return (
    <>
      <Container>
        <HeaderCard title="Total players" icon={PeopleAltIcon} />
        <HeaderCard title="Total clans" icon={ShieldIcon} />
      </Container>
      <PlayersTable />
    </>
  );
};
