import { useState } from "react";
import { Grid, Paper, Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import { OffchainFundsTable } from "./OffchainFundsTable";
import { GetClan_lookup_clans_clan_treasury_balances_offchainFunds } from "pages/ClanPage/query/__generated__/GetClan";

type Props = {
  offchainFunds: GetClan_lookup_clans_clan_treasury_balances_offchainFunds[];
};

export const OffchainFundsCard = ({ offchainFunds }: Props) => {
  const [search, setSearch] = useState("");

  return (
    <Grid item xs={6}>
      <Paper sx={{ p: 1 }}>
        <Grid container alignItems="center">
          <Grid item xs={4}>
            <Typography align="center" color="rgba(119, 119, 119, 1)">
              {"Shards "}
              <Typography color="black" fontWeight={700} component="span">
                ( {offchainFunds.length} )
              </Typography>
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <TextField
              label="Search"
              size="small"
              variant="outlined"
              fullWidth
              onChange={(e) => setSearch(e.target.value)}
            />
          </Grid>
          <Grid mt={2} item xs={12}>
            <OffchainFundsTable {...{ search, offchainFunds }} />
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};
