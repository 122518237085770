import { useMutation } from "@apollo/client";
import { Box, Button } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Form, Formik, FormikProps } from "formik";
import {
  SelectWrapper,
  TextFieldWrapper,
} from "pages/Gears/GearForm/components";
import { MultiSelectChipsWrapper } from "MultiSelectChipsWrapper/MultiSelectChipsWrapper";
import { useGetGearAvailableData } from "pages/Gears/GearForm/hooks/useGetAvailableData";
import { GearFormWrapper } from "pages/Gears/GearForm/styles";
import { gearsGenerationCheckMatches } from "pages/Gears/Generate/gql/gearsGenerationCheckMatches.graphql";
import { gearsGenerationExecute } from "pages/Gears/Generate/gql/gearsGenerationExecute.graphql";
import * as React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const initialValues = {
  nameCounterID: "",
  minMintLevel: 1,
  season: "",
  dropType: "",
  alignment: [],
  type: [],
  rarity: [],
  set: [],
};

type IValues = typeof initialValues;

export const Generate = () => {
  const {
    availableGearRarities,
    availableGearAlignments,
    availableGearStatNames,
    availableGearAllegiances,
    availableGearSets,
    availableGearTypes,
    availableSeasons,
    availableDropTypes,
    loading,
  } = useGetGearAvailableData();
  const navigate = useNavigate();
  const [matches, setMatches] = useState<string[]>([]);

  const [chechMatches, { loading: isChecking }] = useMutation(
    gearsGenerationCheckMatches
  );
  const [execute, { loading: isExecuting }] = useMutation(
    gearsGenerationExecute
  );

  const handleCheckMatches = (props: FormikProps<typeof initialValues>) => {
    const { values } = props;
    props.setSubmitting(true);
    setMatches([]);
    chechMatches({
      variables: {
        nameCounterID: values.nameCounterID,
        season: values.season,
        dropType: values.dropType,
        minMintLevel: +values.minMintLevel,
        alignmentValues: values.alignment,
        setValues: values.set,
        typeValues: values.type,
        rarityValues: values.rarity,
      },
    })
      .then((res) => {
        if (res.data?.gearsGenerationCheckMatches?.configIDs?.length > 0) {
          setMatches(res.data?.gearsGenerationCheckMatches?.configIDs);
          toast.success("Success");
        } else {
          toast.success("Nothing found");
        }
      })
      .catch((e) => toast.error(e.graphQLErrors[0].message))
      .finally(() => {
        props.setSubmitting(false);
      });
  };

  const handleExecute = (props: FormikProps<typeof initialValues>) => {
    const { values } = props;
    props.setSubmitting(true);
    setMatches([]);
    execute({
      variables: {
        nameCounterID: values.nameCounterID,
        season: values.season,
        dropType: values.dropType,
        minMintLevel: +values.minMintLevel,
        alignmentValues: values.alignment,
        setValues: values.set,
        typeValues: values.type,
        rarityValues: values.rarity,
      },
    })
      .then((res) => {
        if (res) {
          const affected = res?.data?.gearsGenerationExecute?.affectedCount;
          toast.success("Affected count: " + affected);
          navigate(-1);
        } else {
          toast.success("Nothing found");
        }
      })
      .catch((e) => toast.error(e.graphQLErrors[0].message))
      .finally(() => {
        props.setSubmitting(false);
      });
  };

  if (loading) {
    return (
      <GearFormWrapper>
        <Box display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      </GearFormWrapper>
    );
  }
  return (
    <>
      <GearFormWrapper>
        {isExecuting ||
          (isChecking && (
            <div
              style={{
                zIndex: 10,
                position: "absolute",
                inset: 20,
                background: "#ffffff",
                opacity: 0.5,
              }}
            >
              <span></span>
            </div>
          ))}
        <Formik
          initialValues={initialValues}
          // validationSchema={getSchema()}
          onSubmit={() => undefined}
        >
          {(props) => {
            return (
              <Box component={Paper} padding={2}>
                <Form>
                  <TextFieldWrapper
                    name="nameCounterID"
                    label="Name Counter ID"
                  />
                  <TextFieldWrapper
                    name="minMintLevel"
                    label="Min Mint Level"
                  />
                  <SelectWrapper
                    opts={availableSeasons}
                    label="Season"
                    name="season"
                  />
                  <SelectWrapper
                    opts={availableDropTypes}
                    label="Drop Type"
                    name="dropType"
                  />
                  <MultiSelectChipsWrapper
                    opts={availableGearAlignments}
                    label="Alignment"
                    name="alignment"
                  />
                  <MultiSelectChipsWrapper
                    opts={availableGearSets}
                    label="Set"
                    name="set"
                  />
                  <MultiSelectChipsWrapper
                    opts={availableGearTypes}
                    label="Type"
                    name="type"
                  />
                  <MultiSelectChipsWrapper
                    opts={availableGearRarities.filter((i) => i !== "Common")}
                    label="Rarity"
                    name="rarity"
                  />

                  {/*{errorMessage && !isSubmitting ? (*/}
                  {/*  <Alert severity="error">{errorMessage}</Alert>*/}
                  {/*) : null}*/}

                  <Box display="flex" justifyContent="end" gap={2}>
                    <Button
                      variant="contained"
                      disabled={props.isSubmitting}
                      onClick={() => handleCheckMatches(props)}
                    >
                      Check matches
                    </Button>
                    <Button
                      onClick={() => handleExecute(props)}
                      variant="contained"
                      disabled={props.isSubmitting}
                    >
                      Execute generation
                    </Button>
                  </Box>
                </Form>
              </Box>
            );
          }}
        </Formik>
        {matches.length > 0 && (
          <Box component={Paper} padding={2} mt={2}>
            <Table sx={{ maxWidth: 600 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Matches</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {matches.map((row) => (
                  <TableRow
                    key={row}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell>{row}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        )}
      </GearFormWrapper>
    </>
  );
};
