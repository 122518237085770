import { useState } from "react";

import { Box, Button, Drawer, Switch, Typography } from "@mui/material";
import { useFormikContext } from "formik";
import { IFormWeapon } from "./types";
import { Perk } from "./components/Perk";
import { Conditions } from "./components/Conditions";

export const PerksAndConditionsDrawer: React.FC = () => {
  const [show, setShow] = useState(false);
  const { values, handleChange } = useFormikContext<IFormWeapon>();

  return (
    <>
      <Button variant="outlined" size="small" onClick={() => setShow(true)}>
        Customize
      </Button>
      <Drawer
        anchor="right"
        open={show}
        onClose={() => setShow(false)}
        title="Perks and Conditions Drawer"
      >
        <Box width={600} padding={4}>
          <Typography variant="h6" gutterBottom component="div" mb={2}>
            Perks and Conditions
          </Typography>
          <Box sx={{display: "flex", alignItems: "center"}}>
          <Switch
              checked={values.isEmptyPerks}
              name={"isEmptyPerks"}
              onChange={handleChange}
            />
          <div>off perks and condtions</div>
          </Box>
          {values.perksSlot.map((_, index) => {
            return <Perk key={index} index={index} />;
          })}

          <Conditions perks={values.conditions} handleChange={handleChange} />
        </Box>
      </Drawer>
    </>
  );
};
