import { gql } from "@apollo/client";

export const queryGetWeaponConfig = gql`
  query GetWeaponConfig($input: SingleWeaponInput!) {
    lookup {
      cgsAdmin {
        weapons {
          single(input: $input) {
            id
            weaponType
            weaponSkin
            nameCounterID
            season
            dropType
            isStandard
            skins {
              id
              name
            }
            name
            rarity
            minMintLevel
            render {
              useSpecificModel
              materials {
                name
                withNormalMap
                withEmissionMap
                withAmbientOcclusion
                useSpecificParams
              }
            }
            randomization {
              perksSlots {
                probability
                perks {
                  name
                  weight
                }
              }
              conditions {
                name
                weight
              }
            }
          }
        }
      }
    }
  }
`;
