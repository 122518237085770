import { useMutation } from "@apollo/client";
import { WeaponConfigUpdateQGL } from "./weaponConfigUpdate.graphql";
import {
  WeaponConfigUpdate,
  WeaponConfigUpdateVariables,
} from "./__generated__/WeaponConfigUpdate";

export const useWeaponConfigUpdate = () => {
  const [weaponUpdateConfig, { loading, error }] = useMutation<
    WeaponConfigUpdate,
    WeaponConfigUpdateVariables
  >(WeaponConfigUpdateQGL);

  return {
    weaponUpdateConfig,
    loading,
    error,
  };
};
