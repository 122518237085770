import { gql } from "@apollo/client";

export const WeaponConfigUpdateQGL = gql`
  mutation WeaponConfigUpdate(
    $id: String!
    $weaponType: String!
    $weaponSkin: String!
    $nameCounterID: String!
    $season: String!
    $minMintLevel: Int!
    $dropType: String!
    $rarity: Int!
    $name: String!
    $skins: [WeaponConfigSkinInput!]
    $render: WeaponConfigRenderInput
    $randomization: WeaponConfigRandomizationInput!
    $isStandard: Boolean!
  ) {
    weaponConfigUpdate(
      input: {
        config: {
          id: $id
          name: $name
          skins: $skins
          weaponType: $weaponType
          weaponSkin: $weaponSkin
          minMintLevel: $minMintLevel
          nameCounterID: $nameCounterID
          season: $season
          dropType: $dropType
          rarity: $rarity
          render: $render
          randomization: $randomization
          isStandard: $isStandard
        }
      }
    ) {
      ok
    }
  }
`;
