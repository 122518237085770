import React, { useState } from "react";
import { Button, Grid } from "@mui/material";
import { useUserProfileContext } from "pages/UserProfile/context";
import { BanModal } from "./components/BanModal";
import { RewardsModal } from "./components/RewardsModal";
import { useMutation } from "@apollo/client";
import { unbanUserMutation } from "pages/UserProfile/mutations/unbanUser.graphql";
import { queryGetUser } from "pages/UserProfile/query/getUser.graphql";
import CircularProgress from "@mui/material/CircularProgress";

export const TopActions = () => {
  const { data } = useUserProfileContext();
  const [unbanUser, { loading: unbanUserLoading }] = useMutation(
    unbanUserMutation,
    {
      variables: {
        userId: data.userId,
      },
      refetchQueries: [{ query: queryGetUser, variables: { id: data.userId } }],
    }
  );
  const isUserBanned = Boolean(data.overview.bans.active);
  const [isBanModalOpen, setIsBanModalOpen] = useState(false);
  const [isRewardsModalOpen, setIsRewardsModalOpen] = useState(false);
  return (
    <Grid spacing={1} container item xs={6} justifyContent="flex-end">
      <Grid item>
        <Button
          onClick={() =>
            window.open(
              `https://faraway.com/miniroyale/players/${data.userId}`,
              "_blank"
            )
          }
          color="secondary"
          variant="contained"
        >
          View social profile
        </Button>
      </Grid>
      <Grid item>
        <Button
          onClick={() => setIsRewardsModalOpen(true)}
          color="secondary"
          variant="contained"
        >
          Send Rewards
        </Button>
      </Grid>
      <Grid item>
        {isUserBanned ? (
          <Button
            startIcon={unbanUserLoading ? <CircularProgress /> : undefined}
            disabled={unbanUserLoading}
            onClick={() => unbanUser()}
            color="secondary"
            variant="contained"
          >
            Unban
          </Button>
        ) : (
          <Button
            onClick={() => setIsBanModalOpen(true)}
            color="secondary"
            variant="contained"
          >
            Ban
          </Button>
        )}
      </Grid>

      {isBanModalOpen && <BanModal onClose={() => setIsBanModalOpen(false)} />}
      {isRewardsModalOpen && (
        <RewardsModal onClose={() => setIsRewardsModalOpen(false)} />
      )}
    </Grid>
  );
};
