import { useState } from "react";
import Button from "@mui/material/Button";
import { useMutation } from "@apollo/client";
import {
  GRAPHIC_STATUS,
  LOCK_PRESET,
} from "mutations/cgsCollections";
import { IPresetHeaderProps } from "./Cgs.types";
import { CustomPopover } from "./components/CustomPopover";

export const PresetHeader = ({
  collectionID,
  presetID,
  isLocked,
}: IPresetHeaderProps) => {
  const [presetStatus, setStatus] = useState<boolean | null>(null);

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickPopoverClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setAnchorEl(event.currentTarget);
  };

  const [getGraphicStatus] = useMutation(GRAPHIC_STATUS);

  const [lockPreset] = useMutation(LOCK_PRESET);

  const handleModalClick = () => {
    lockPreset({
      variables: {
        collectionID,
        id: presetID,
      },
    })
      .then(() => alert("successfull"))
      .catch((e: Error) => alert(e.message));
  };

  return (
    <div>
      <Button
        variant="contained"
        onClick={() =>
          getGraphicStatus({
            variables: {
              presetID,
              collectionID,
            },
          })
            .then(({ data }) => {
              if (data?.cgsAdminGetGraphicsStatus?.errors?.length) {
                setStatus(false);
                alert(
                  data?.cgsAdminGetGraphicsStatus?.errors?.map(
                    (err: string, i: number) => err
                  )
                );
              } else {
                setStatus(true);
                alert("successfull");
              }
            })
            .catch((e: Error) => {
              setStatus(false);
              alert(e.message);
            })
        }
        color={
          presetStatus ? "success" : presetStatus === null ? "primary" : "error"
        }
      >
        validate preset
      </Button>
      <Button
        style={{ margin: "0 30px" }}
        variant="contained"
        color={isLocked ? "success" : "secondary"}
        onClick={handleClickPopoverClick}
      >
        LOCK PRESET
      </Button>
      <CustomPopover
        title="Lock"
        onClick={handleModalClick}
        handleClose={handleClose}
        anchorEl={anchorEl}
      />
    </div>
  );
};
