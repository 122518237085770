import { styled } from "@mui/system";
import DownloadIcon from "@mui/icons-material/Download";
import { TableCell } from "@mui/material";
import { transform } from "typescript";

export const HeaderContanier = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "7px 9px 7px 26px",
  borderBottom: "1px solid #F0F0F0",
});

export const Img = styled("img")({
   width: "150px",
   height: "150px",
});

export const StyledInput = styled("input")(({width}: {width: string}) => ({
  background: "transparent",
  border: "none",
  textOverflow: "ellipsis",
  width: width,
  outline: "none",
  fontSize: "14px",
  "&:before, &:after": {
      display: "none",
}}));

export const DownloadIconStyled = styled(DownloadIcon)({
  cursor: "pointer",
});

export const Box = styled("div")({
  display: "flex",
  justifyContent:"center",
  alignItems: "center",
  flexDirection: "column"
});

export const Cell = styled(TableCell)({
  "&:hover": {
    color: "blue",
    transform: "scale(1.05)",
  }
});

export const ModalContainer = styled("div")({
  padding: "20px",
  borderRadius: "15px",
  backgroundColor: "white", 
  width: "600px",
  height: "600px",
  margin: "200px auto",
});

export const TextureTitle = styled("span")({
  textAlign: "center",
  display: "block",
  marginBottom: "10px",
});

export const List = styled("div")({
  display: "flex",
  "img": {
    marginRight: "10px"
  }
});

export const FlexBox = styled("div")({
  display: "flex",
  alignItems: "center"
});