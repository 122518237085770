import { GetUser_lookup_offchainWallets_allHolderAccounts } from "pages/UserProfile/query/__generated__/GetUser";
import { TableColumn } from "components/SortCell/types";
import React from "react";
import { ActionCell } from "./components/ActionCell";

type GetColumnsParams = {
  onItemSelect: (item: string) => void;
};

export const getColumns = ({
  onItemSelect,
}: GetColumnsParams): TableColumn<GetUser_lookup_offchainWallets_allHolderAccounts>[] => [
  // {
  //   key: "icon",
  //   label: "",
  //   headerCellProps: {
  //     width: 30,
  //   },
  //   renderCell: (row) => (
  //     <Box
  //       width={30}
  //       component="img"
  //       sx={{
  //         borderRadius: 1,
  //       }}
  //       // src={row?.overview?.content?.asset?.icon || ""}
  //     />
  //   ),
  // },
  {
    key: "name",
    label: "Name",
    getOrderBy: (data) => data.type,
    headerCellProps: {
      align: "left",
    },
    rowCellProps: {
      align: "left",
    },
    renderCell: (row) => row.type,
  },
  {
    key: "amount",
    label: "Amount",
    getOrderBy: (data) => data.amount,
    headerCellProps: {
      align: "left",
    },
    rowCellProps: {
      align: "left",
    },
    renderCell: (row) => row.amount,
  },
  {
    key: "action",
    label: "",
    renderCell: (row) => <ActionCell onItemSelect={onItemSelect} row={row} />,
  },
];
