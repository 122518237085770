import React from "react";
import { Grid, Paper, Typography } from "@mui/material";
import { useUserProfileContext } from "pages/UserProfile/context";
import { useNavigate } from "react-router-dom";
import s from "./ClanInfo.module.css";

export const ClanInfo = () => {
  const { data } = useUserProfileContext();
  const nav = useNavigate();

  return (
    <Grid mt={3} item xs={12}>
      <Paper sx={{ p: 2 }}>
        <Grid container spacing={3}>
          <Grid item>
            {data.overview.clan.clan?.id ? (
              <Typography fontSize={16} color="#777777">
                Clan name:{" "}
                <Typography
                  className={s.clanName}
                  color="black"
                  component="span"
                  fontWeight={700}
                  fontSize={16}
                  onClick={() => nav(`/clans/${data.overview.clan.clan?.id}`)}
                >
                  {data.overview.clan.clan.name}
                </Typography>
              </Typography>
            ) : (
              "Clan: not a clan member"
            )}
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};
