import {
  Box,
  Card,
  CardActions,
  CardContent,
  Typography,
  Button,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

interface WeaponCardProps {
  id: string;
  deployWeapons: (variables: any) => Promise<any>;
  isV2: boolean;
  img: string;
}

export const WeaponCard = ({ id, deployWeapons, isV2, img }: WeaponCardProps) => {
  const navigate = useNavigate();
  const server = localStorage.getItem("server")?.split("graphql")[0];

  return (
    <Box width="25%" py={1}>
      <Box px={2}>
        <Card variant="outlined">
          <CardContent sx={{display: "flex", justifyContent: "space-between"}}>
            <Typography variant="body1" component="div">
              {id}
            </Typography>
            <img src={img} alt="icon"  width="60px" height="60px" />
          </CardContent>
          <CardActions
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            <Button size="small" onClick={() => navigate(isV2 ? `v2/${id}` : id)}>
              Edit config
            </Button>
            <Button
              size="small"
              variant="outlined"
              onClick={() =>
                deployWeapons({ variables: { weaponId: id } })
                  .then(() => alert("successfull"))
                  .catch((e: Error) => alert(e.message))
              }
            >
              Deploy
            </Button>
          </CardActions>
        </Card>
      </Box>
    </Box>
  );
};
