import { SvgIconComponent } from "@mui/icons-material";

import DashboardIcon from "@mui/icons-material/Dashboard";
import MilitaryTechIcon from "@mui/icons-material/MilitaryTech";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import LocalPoliceIcon from "@mui/icons-material/LocalPolice";
import CollectionsIcon from "@mui/icons-material/Collections";
import DateRangeIcon from "@mui/icons-material/DateRange";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import SettingsIcon from "@mui/icons-material/Settings";
import AlignHorizontalLeftIcon from "@mui/icons-material/AlignHorizontalLeft";

type TLinks = { path: string; name: string; icon: SvgIconComponent }[];

export const links: TLinks = [
  { path: "/dashboard", name: "Dashboard", icon: DashboardIcon },
  { path: "/rewardUser", name: "Reward User", icon: MilitaryTechIcon },
  { path: "/rewardGroup", name: "Reward Group", icon: EmojiEventsIcon },
  { path: "/weapons", name: "Weapons", icon: AutoAwesomeIcon },
  { path: "/gears", name: "Gears", icon: LocalPoliceIcon },
  {
    path: "/miniroyaleImages",
    name: "MiniroyaleImages",
    icon: CollectionsIcon,
  },
  { path: "/clanEvents", name: "ClanEvents", icon: DateRangeIcon },
  { path: "/cgsService", name: "CgsAdmin", icon: SupervisorAccountIcon },
  { path: "/makeovers", name: "Makeovers", icon: AlignHorizontalLeftIcon },
  { path: "/settings", name: "Settings", icon: SettingsIcon },
];
