import { useEffect, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { CREATE_PRESET, UPDATE_PRESET } from "mutations/cgsCollections";
import { GET_ICONS_ATTEMPTS, GET_RENDERMODE_LIST } from "query/cgsServise";
import Modal from "@mui/material/Modal";
import { useStyles } from "pages/CgsServise/Cgs.style";
import Button from "@mui/material/Button";
import TreeView from "@mui/lab/TreeView";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {
  ITreeData,
  IModalProps,
  IEditedPreset,
  Background,
  ILookupData,
} from "./Cgs.types";
import {
  TextField,
  Autocomplete,
  FormControlLabel,
  Checkbox,
  Box,
} from "@mui/material";
import { CustomSelect } from "./components/Select";

type Icons = { tokenID: string; imgData: string }

export const FileTreeModal = ({
  isOpen,
  setOpen,
  TreeRender,
  filesData,
  collectionId,
  selectedFiles,
  refetchPreset,
  refetchCollections,
  errorsList,
  preset,
  setItems,
  setDataFromPreset,
  setData,
}: IModalProps) => {
  const [isLoading, setLoading] = useState(true);
  const isEdit = !isOpen.isNew;

  useEffect(() => {
    if (!preset?.tokenAmount || collectionId) {
      setLoading(false);
    }
    if (isEdit) {
      setTokenAmount(preset?.tokenAmount);
      setIconTokenID(preset?.iconTokenID);
      setMakeoverTokenId(preset?.makeoverTokenID);
      setName(preset?.name);
      setIconsAttempt(preset?.iconsAttempt);
      setBaseRarity(preset?.baseRarity);
      setMinMintLevel(preset?.minMintLevel);
      setBackground(preset?.background);
      setRenderMode(preset?.renderMode || "");
      setrequireRPM(preset?.RequireRPM);
    }
  }, [preset?.tokenAmount, collectionId]);

  const mappedPreset = preset?.attributes
    ?.map((item, i) => {
      const values = item.values.map((valItem) => ({
        attributeName: item.name,
        attributeValue: valItem.name,
      }));
      return values;
    })
    .flat(2);

  const resetSelectedfields = (items?: ITreeData[]): any => {
    return items?.map((item: ITreeData) => {
      const mappedChildren = item?.children?.length
        ? resetSelectedfields(item?.children)
        : undefined;

      return { ...item, selected: false, children: mappedChildren };
    });
  };

  useEffect(() => {
    if (isEdit) {
      setItems(mappedPreset);
      setDataFromPreset(mappedPreset);
    } else {
      setData(resetSelectedfields(filesData));
    }
  }, [isEdit]);

  const [presetID, setID] = useState(isEdit ? preset?.id : "");
  const [name, setName] = useState(isEdit ? preset?.name : "");
  const [baseRarity, setBaseRarity] = useState(
    isEdit ? preset?.baseRarity : "UNKNOWN"
  );
  const [minMintLevel, setMinMintLevel] = useState(
    isEdit ? preset?.minMintLevel : 0
  );
  const [iconTokenID, setIconTokenID] = useState<Icons | string | undefined>(
    isEdit ? preset?.iconTokenID : ""
  );
  const [makeoverTokenId, setMakeoverTokenId] = useState<Icons | string | undefined>(
    isEdit ? preset?.makeoverTokenID : ""
  );
  const [iconsAttempt, setIconsAttempt] = useState(
    isEdit ? preset?.iconsAttempt : ""
  );
  const [background, setBackground] = useState<Background | string | undefined>(
    isEdit ? preset?.background : "Random"
  );
  const [tokenAmount, setTokenAmount] = useState(
    isEdit ? preset?.tokenAmount : 0
  );
  const [renderMode, setRenderMode] = useState(
    isEdit ? preset?.renderMode : "11"
  );
  const [cleanUp, setCleanUp] = useState(false);

  const [requireRPM, setrequireRPM] = useState(
    isEdit && preset?.RequireRPM
  );

  const classes = useStyles();

  const mappedFiles = selectedFiles?.map((item: ITreeData) => ({
    attributeName: item.attributeName,
    attributeValue: item.attributeValue,
  }));

  const mappedEdit = selectedFiles?.reduce(
    (acc: IEditedPreset[], next: ITreeData, i: number, array: ITreeData[]) => {
      const currName = acc?.find(
        (item: IEditedPreset) => item.name === next.attributeName
      )?.name;
      const currentAttribute = preset?.attributes?.find((attr) =>
        attr.values.find((item) => item.name === array[i].attributeValue)
      );
      const currentValues = currentAttribute?.values.find(
        (item) => item.name === array[i].attributeValue
      );

      if (currName) {
        const idx = acc.findIndex(
          (item: IEditedPreset) => item.name === currName
        );
        acc[idx].values.push(
          currentValues || { name: array[i].attributeValue, weight: 100 }
        );
        acc[idx].probability = currentAttribute?.probability || 1;
      } else {
        acc.push({
          name: array[i].attributeName,
          probability: currentAttribute?.probability || 1,
          values: [
            currentValues || { name: array[i].attributeValue, weight: 100 },
          ],
        });
      }

      return acc;
    },
    []
  );

  const [createCgsPreset] = useMutation(CREATE_PRESET, {
    variables: {
      collectionID: collectionId,
      presetID,
      attributes: mappedFiles,
      name,
      baseRarity,
      minMintLevel,
      renderMode,
      background,
      tokenAmount,
    },
  });

  const { data: iconAttemptsData } = useQuery<ILookupData>(GET_ICONS_ATTEMPTS, {
    fetchPolicy: "no-cache",
    variables: {
      id: collectionId,
    },
  });

  const { data: renderList } = useQuery<ILookupData>(GET_RENDERMODE_LIST);

  const availableRenderModes = renderList?.lookup.cgsAdmin.characters.availableRenderModes || [];

  const iconAttempts =
    iconAttemptsData?.lookup.cgsAdmin.characters?.collection.iconAttempts;

  const [updateCgsPreset, { data }] = useMutation(UPDATE_PRESET, {
    variables: {
      collectionID: collectionId,
      id: presetID,
      name: name,
      baseRarity: baseRarity,
      minMintLevel: minMintLevel,
      renderMode: renderMode,
      iconTokenID: iconTokenID,
      makeoverTokenID: makeoverTokenId,
      iconsAttempt: iconsAttempt,
      background: background,
      tokenAmount: tokenAmount,
      attributes: mappedEdit,
      allowCleanup: cleanUp,
      requireRPM,
    },
  });
  const fetchPreset = isEdit ? updateCgsPreset : createCgsPreset;

  return (
    <Modal
      open={isOpen.status}
      onClose={() => {
        setOpen({ status: false, isNew: false });
        setItems([]);
      }}
      aria-labelledby="child-modal-title"
      aria-describedby="child-modal-description"
    >
      {!isLoading ? (
        <div className={classes.fileModal}>
          <Button
            variant="contained"
            style={{ padding: "15px", marginBottom: "15px" }}
            onClick={() =>
              fetchPreset()
                .then(() => {
                  data?.cgsAdminPresetUpdate?.errors &&
                    alert(
                      data?.cgsAdminPresetUpdate?.errors?.map(
                        (err: string, i: number) => err
                      )
                    );
                  setOpen({ status: false, isNew: false });
                  setItems([]);
                  refetchPreset({
                    variables: { id: collectionId, presetID: preset?.id },
                  });
                  refetchCollections({ variables: { id: collectionId } });
                })
                .catch((e: Error) => {
                  alert(e.message);
                })
            }
          >
            {isEdit ? "Edit Preset" : "Create Preset"}
          </Button>
          {isEdit && (
            <FormControlLabel
              sx={{ ml: 5 }}
              control={
                <Checkbox
                  checked={cleanUp}
                  onChange={() => setCleanUp(!cleanUp)}
                />
              }
              label="Allow CleanUp"
            />
          )}
          {isEdit && (
            <FormControlLabel
              sx={{ ml: 5 }}
              control={
                <Checkbox
                  checked={requireRPM}
                  onChange={() => setrequireRPM(!requireRPM)}
                />
              }
              label="Require RPM"
            />
          )}
          <TextField
            id="outlined-basic"
            label="Preset ID"
            variant="outlined"
            value={presetID}
            disabled={isEdit}
            className={classes.presetEdit}
            onChange={(e) => setID(e.target.value)}
          />
          <div className={classes.editFields}>
            <TextField
              id="outlined-basic"
              label="Name"
              variant="outlined"
              value={name}
              className={classes.presetEdit}
              onChange={(e) => setName(e.target.value)}
            />
            <TextField
              id="outlined-basic"
              label="MintLevel"
              variant="outlined"
              value={minMintLevel}
              className={classes.presetEdit}
              onChange={(e) => setMinMintLevel(+e.target.value)}
            />
            <CustomSelect
              name="renderMode-id"
              label="renderMode"
              opts={availableRenderModes}
              className={classes.presetEdit}
              value={renderMode}
              setFn={setRenderMode}
            />
            {isEdit && <Autocomplete
              disablePortal
              id="makeovertokenid-id"
              options={preset?.icons || []}
              className={classes.presetEdit}
              value={makeoverTokenId}
              renderInput={(params) => <TextField {...params} label="Makeover Token ID" />}
              onChange={(_ , newValue: Icons | string | null) => setMakeoverTokenId((newValue as Icons)?.tokenID || "")} 
              renderOption={(props, option) => (
                <Box
                  component="li"
                  sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                  {...props}
                >
                  <img
                    loading="lazy"
                    width="40"
                    src={(option as Icons).imgData}
                  />
                  {(option as Icons).tokenID}
                </Box>
              )}
            />}
            {isEdit && <Autocomplete
              disablePortal
              id="icontokenid-id"
              options={preset?.icons || []}
              className={classes.presetEdit}
              value={iconTokenID}
              renderInput={(params) => <TextField {...params} label="Icon Token ID" />}
              onChange={(_ , newValue: Icons | string | null) => setIconTokenID((newValue as Icons)?.tokenID || "")} 
              renderOption={(props, option) => (
                <Box
                  component="li"
                  sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                  {...props}
                >
                  <img
                    loading="lazy"
                    width="40"
                    src={(option as Icons).imgData}
                  />
                  {(option as Icons).tokenID}
                </Box>
              )}
            />}
            {isEdit && (
              <Autocomplete
                disablePortal
                id="iconsAttempt-id"
                options={iconAttempts || []}
                className={classes.presetEdit}
                value={iconsAttempt}
                renderInput={(params) => (
                  <TextField {...params} label="Icons Attempt" />
                )}
                onChange={(_, newValue: string | null) =>
                  setIconsAttempt(newValue || "")
                }
              />
            )}
            <CustomSelect
              name="background-id"
              label="background"
              opts={Object.values(Background)}
              className={classes.presetEdit}
              value={background}
              setFn={setBackground}
            />
            <CustomSelect
              name="rarity-id"
              label="Bsse Rarity"
              opts={[
                "UNKNOWN",
                "COMMON",
                "UNCOMMON",
                "RARE",
                "EPIC",
                "LEGENDARY",
              ]}
              className={classes.presetEdit}
              value={baseRarity}
              setFn={setBaseRarity}
            />
            <TextField
              id="outlined-basic"
              label="Token Amount"
              variant="outlined"
              value={tokenAmount}
              className={classes.presetEdit}
              onChange={(e) => setTokenAmount(+e.target.value)}
            />
          </div>
          <TreeView
            aria-label="icon expansion"
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpandIcon={<ChevronRightIcon />}
            defaultExpanded={["сюда id файлов"]}
            sx={{
              height: "60vh",
              overflow: "scroll",
              border: "8px solid grey",
              margin: "20px 0",
            }}
          >
            {TreeRender(filesData)}
          </TreeView>
          {errorsList.map((item, i: number) => (
            <div key={i}>
              <span className={classes.boldTtile}>Path:</span>
              {item?.path}
              {item?.errors?.map((item, l) => (
                <div key={l}>
                  {" "}
                  <span className={classes.errorTitle}>Error:</span> {item}
                </div>
              ))}
            </div>
          ))}
        </div>
      ) : (
        <div>laoding...</div>
      )}
    </Modal>
  );
};
