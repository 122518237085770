import EditIcon from "@mui/icons-material/Edit";
import { Button, Divider, Grid, IconButton, Paper } from "@mui/material";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/system";
import { Gap } from "components/Gap";
import { ClanBaseInfoItem } from "pages/ClanPage/components/ClanBaseInfoItem";
import { OffchainFundsCard } from "pages/ClanPage/components/OffchainFundsCard/OffchainFundsCard";
import { useClanContext } from "pages/ClanPage/context";
import { DialogAddMember } from "pages/ClanPage/dialogs/DialogAddMember";
import { DialogChangeClanField } from "pages/ClanPage/dialogs/DialogChangeClanField";
import { DialogChangeClanLeader } from "pages/ClanPage/dialogs/DialogChangeClanLeader";
import { DialogKickClanMember } from "pages/ClanPage/dialogs/DialogKickClanMember";
import { MembersTable } from "pages/ClanPage/components/MembersTable";
import { WeaponsTable } from "pages/ClanPage/components/WeaponsTable";
import React, { useState } from "react";
import { ClanCurrenciesTable } from "./ClanCurrenciesTable";

type OpenDialogOptions =
  | "add-member"
  | "kick-member"
  | "delete-clan"
  | "change-leader"
  | "change-clan-field";

export const ClanBaseInfo = () => {
  const { data: clan } = useClanContext();
  const isLinks = clan.links?.discord || clan.links?.twitter;
  const [field, setField] = useState("");
  const [openDialog, setOpenDialog] = useState<OpenDialogOptions | null>();
  const closeDialog = () => setOpenDialog(null);

  return (
    <Grid mt={3} item xs={12}>
      <Paper sx={{ p: 2 }}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box alignItems="center" display="flex">
            <Typography fontWeight={700} fontSize={18} mt="3px">
              {clan.name}
            </Typography>
            <IconButton
              onClick={() => {
                setField("name");
                setOpenDialog("change-clan-field");
              }}
              sx={{ ml: 1 }}
            >
              <EditIcon sx={{ color: "#777777" }} />
            </IconButton>
          </Box>

          <Box alignItems="center" gap={2} display="flex">
            <Button onClick={() => setOpenDialog("add-member")}>
              Add member
            </Button>
            <Button onClick={() => setOpenDialog("kick-member")}>
              Kick Member
            </Button>
            <Button
              onClick={() => setOpenDialog("delete-clan")}
              variant="contained"
              color="error"
            >
              Delete Clan
            </Button>
          </Box>
        </Box>
        <Divider sx={{ mt: 2, mb: 2 }} />
        <Grid container spacing={2}>
          <ClanBaseInfoItem label="ClanID" value={clan.id} />
          <ClanBaseInfoItem
            label="Leader"
            value={clan.leader.ident.username + " #" + clan.leader.ident.suffix}
            onEdit={() => setOpenDialog("change-leader")}
          />
          <ClanBaseInfoItem
            label="Description"
            value={clan.description}
            onEdit={() => {
              setField("description");
              setOpenDialog("change-clan-field");
            }}
          />
          <ClanBaseInfoItem label="Join Type" value={clan.joinType} />
          <ClanBaseInfoItem
            label="Slug"
            value={clan.slug}
            onEdit={() => {
              setField("slug");
              setOpenDialog("change-clan-field");
            }}
          />
          <ClanBaseInfoItem
            label="Size / Capacity"
            value={`${clan.size}/${clan.capacity}`}
          />
        </Grid>
      </Paper>

      <Paper sx={{ p: 2, mt: "20px" }}>
        {clan.links?.discord && (
          <>
            Discord:&nbsp;
            <a
              href={"https://discordapp.com/users/" + clan.links?.discord}
              target="_blank"
              rel="noreferrer"
            >
              {clan.links?.discord}
            </a>
          </>
        )}
        <Gap w={10} />
        {clan.links?.twitter && (
          <>
            Twitter:&nbsp;
            <a
              href={"https://twitter.com/" + clan.links?.twitter}
              target="_blank"
              rel="noreferrer"
            >
              {clan.links?.twitter}
            </a>
          </>
        )}
        {!isLinks && "Clan provided no links"}
      </Paper>
      <Paper sx={{ p: 2, mt: "20px" }}>
        <Typography fontWeight={700}>Events</Typography>
      </Paper>

      <MembersTable members={clan?.members || []} />

      <Grid container spacing={2} mt={2}>
        {clan.treasury.balances.mintableTokens && (
          <WeaponsTable items={clan.treasury.balances.mintableTokens} />
        )}
        {clan.treasury.balances.offchainFunds && (
          <OffchainFundsCard
            offchainFunds={clan.treasury.balances.offchainFunds}
          />
        )}
        <ClanCurrenciesTable balances={clan.treasury.balances} />
      </Grid>

      {openDialog === "add-member" && <DialogAddMember onClose={closeDialog} />}
      {openDialog === "kick-member" && (
        <DialogKickClanMember
          onClose={closeDialog}
          members={clan.members || []}
        />
      )}
      {openDialog === "change-leader" && (
        <DialogChangeClanLeader
          onClose={closeDialog}
          members={clan.members || []}
        />
      )}
      {openDialog === "change-clan-field" && (
        <DialogChangeClanField onClose={closeDialog} field={field} />
      )}
    </Grid>
  );
};
