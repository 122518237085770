import React, { useState } from "react";
import { useMutation, useLazyQuery } from "@apollo/client";
import { GET_COLLECTION, GET_PRESET } from "query/cgsServise";
import { useStyles } from "pages/CgsServise/Cgs.style";
import {
  DELETE_COLLECTION,
  DEPLOY_COLLECTIONS,
} from "mutations/cgsCollections";
import Typography from "@mui/material/Typography";
import { FileTreeModal } from "./FileTreeModal";
import Button from "@mui/material/Button";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { CgsModal } from "./CgsModal";
import { Preset } from "./Preset";
import { MarketName } from "./MarketName";
import { ICollection, ICollections, ILookupData } from "./Cgs.types";
import { Accordion, AccordionSummary, AccordionDetails } from "./Accordion";
import CircularProgress from "@mui/material/CircularProgress";
import { HiddenAttributes } from "./HiddenAttributes";
import { CustomPopover } from "./components/CustomPopover";

export function Collections({
  collections,
  TreeRender,
  filesData,
  selectedFiles,
  refetchCollections,
  errorsList,
  setItems,
  setDataFromPreset,
  setData,
}: ICollections) {
  const [expanded, setExpanded] = useState<string | boolean>("");
  const classes = useStyles();
  const [isFilesOpen, setFilesModal] = useState({
    status: false,
    isNew: false,
  });
  const [isEditCollections, setEditCollections] = useState(false);
  const [collection, setCollection] = useState<ICollection | string>("");

  const [isDeletePopup, setDeletePopup] = useState(false);

  const [deleteCollection] = useMutation(DELETE_COLLECTION);

  const [
    getPreset,
    { data: presetData, loading: presetLoading, refetch: refetchPreset },
  ] = useLazyQuery<ILookupData>(GET_PRESET, {
    fetchPolicy: "no-cache",
  });

  const [
    getCollection,
    {
      data: oneCollection,
      loading: collectionLoading,
      refetch: refetchPresets,
    },
  ] = useLazyQuery<ILookupData>(GET_COLLECTION, {
    fetchPolicy: "no-cache",
  });

  const [deployCollections] = useMutation(DEPLOY_COLLECTIONS);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  const handleClick = (collection: ICollection) => {
    setCollection(collection);
    setFilesModal({ status: true, isNew: true });
  };

  const handleEditClick = (collection: ICollection) => {
    setCollection(collection);
    setEditCollections(true);
  };

  const handlePresetEditClick = (collection: ICollection) => {
    setCollection(collection);
    setFilesModal({ status: true, isNew: false });
  };

  const [anchorEl, setAnchorEl] = useState<SVGSVGElement | null>(null);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleAnchorClick = (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
  };


  return (
    <div className={classes.collectionWrapper}>
      {collections?.lookup?.cgsAdmin?.characters?.collections?.map(
        (item: ICollection, i: number) => (
          <Accordion
            TransitionProps={{ unmountOnExit: true }}
            key={i}
            expanded={expanded === `${i}`}
            onChange={handleChange(`${i}`)}
          >
            <AccordionSummary
              onClick={() => getCollection({ variables: { id: item.id } })}
            >
              <Typography>{item.id}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Button
                style={{ margin: "0 20px 15px" }}
                variant="contained"
                onClick={() =>
                  deployCollections({
                    variables: {
                      collectionID: item.id,
                    },
                  })
                    .then(() => alert("successfull"))
                    .catch((e: Error) => alert(e.message))
                }
              >
                Upload Models/Tokens to CDN
              </Button>
              <DeleteIcon
                style={{ cursor: "pointer", marginRight: "30px" }}
                onClick={(e) => handleAnchorClick(e)}
              />
              <CustomPopover
                title="Delete"
                onClick={() =>
                  deleteCollection({
                    variables: {
                      id: item.id,
                    },
                  })
                    .then(() =>
                      refetchCollections()
                    )
                    .catch((e: Error) => alert(e.message))
                    .finally(() => handleClose())
                }
                handleClose={handleClose}
                anchorEl={anchorEl}
              />
              <EditIcon
                className={classes.editIcon}
                onClick={() => handleEditClick(item)}
              />
              <div className={classes.textContainer}>
                <span className={classes.title}>User Text:</span>
                {item.userText}
              </div>
              <div className={classes.textContainer}>
                <span className={classes.title}>Family:</span>
                {item.params.family}
              </div>
              <div className={classes.textContainer}>
                <span className={classes.title}>Name:</span>
                {item.params.name}
              </div>
              <div className={classes.textContainer}>
                <span className={classes.title}>Description:</span>
                {item.params.description}
              </div>
              <div className={classes.textContainer}>
                <span className={classes.title}>Token Name Template:</span>
                {item.params.tokenNameTemplate}
              </div>
              <div className={classes.textContainer}>
                <span className={classes.title}>Name Counter:</span>
                {item.params.nameCounter}
              </div>
              <div className={classes.textContainer}>
                <span className={classes.title}>Season:</span>
                {item.params.season}
              </div>
              <div className={classes.textContainer}>
                <span className={classes.title}>Drop Type:</span>
                {item.params.dropType}
              </div>
              {collectionLoading ? (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <CircularProgress />
                </div>
              ) : (
                <div className={classes.detailsContainer}>
                  <HiddenAttributes
                    hiddenAttributes={
                      oneCollection?.lookup?.cgsAdmin?.characters?.collection
                        ?.hiddenAttributes
                    }
                    refetchPresets={refetchPresets}
                    collectionID={item.id}
                  />
                  <MarketName
                    marketNames={
                      oneCollection?.lookup?.cgsAdmin?.characters?.collection
                        ?.marketingNames
                    }
                    refetchCollections={refetchCollections}
                    collectionID={item.id}
                  />
                  <Button
                    style={{ minWidth: "20px", marginRight: "20px" }}
                    variant="contained"
                    color="success"
                    onClick={() => handleClick(item)}
                  >
                    +
                  </Button>
                  <span className={classes.presetTitle}>Presets:</span>
                  <Preset
                    presets={
                      oneCollection?.lookup?.cgsAdmin?.characters?.collection?.presets
                    }
                    handlePresetEditClick={handlePresetEditClick}
                    collection={item}
                    refetchPresets={refetchPresets}
                    getPreset={getPreset}
                    presetData={presetData?.lookup.cgsAdmin.characters?.collection.preset}
                    refetchPreset={refetchPreset}
                    isLoading={presetLoading}
                  />
                </div>
              )}
            </AccordionDetails>
          </Accordion>
        )
      )}
      <CgsModal
        isOpen={isEditCollections}
        setOpen={setEditCollections}
        collection={collection}
        refetchCollections={refetchCollections}
        setCollection={setCollection}
      />
      {isFilesOpen.status && (
        <FileTreeModal
          isOpen={isFilesOpen}
          setOpen={setFilesModal}
          TreeRender={TreeRender}
          filesData={filesData}
          collectionId={(collection as ICollection)?.id}
          preset={presetData?.lookup.cgsAdmin.characters.collection.preset}
          selectedFiles={selectedFiles}
          refetchPreset={refetchPreset}
          refetchCollections={refetchPresets}
          errorsList={errorsList}
          setItems={setItems}
          setDataFromPreset={setDataFromPreset}
          setData={setData}
        />
      )}
    </div>
  );
}
