export function descendingComparator<T>(a: T, b: T) {
  if (b < a) {
    return -1;
  }
  if (b > a) {
    return 1;
  }
  return 0;
}

export type Order = "asc" | "desc";

export type GetOrderBy<T> = (data: T) => number | string;

export function getComparator<T>(
  order: Order,
  getOrderBy: GetOrderBy<T>
): (a: T, B: T) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(getOrderBy(a), getOrderBy(b))
    : (a, b) => -descendingComparator(getOrderBy(a), getOrderBy(b));
}
