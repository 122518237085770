import { IFormWeapon, IPerk, IPerkSlot } from "../FormWeapon/types";
import { GetWeaponConfig_lookup_cgsAdmin_weapons_single } from "./hooks/useGetWeaponConfig/__generated__/GetWeaponConfig";
import { v4 as uuidv4 } from "uuid";
import {
  GetPerksAndConditions_lookup_cgsAdmin_attributes_defaultWeaponConditions,
  GetPerksAndConditions_lookup_cgsAdmin_attributes_defaultWeaponPerksSlots,
} from "../hooks/useGetPerksAndConditions/__generated__/GetPerksAndConditions";

export const convertToInitData = (
  {
    randomization,
    render,
    skins,
    __typename,
    ...props
  }: GetWeaponConfig_lookup_cgsAdmin_weapons_single,
  marketName: string,
  defaultConditions: GetPerksAndConditions_lookup_cgsAdmin_attributes_defaultWeaponConditions[],
  defaultPerks?: GetPerksAndConditions_lookup_cgsAdmin_attributes_defaultWeaponPerksSlots[]
): IFormWeapon => {
  const perkSlots =
    defaultPerks &&
    defaultPerks.map((perkSlot, index) => {

      const newPerkSlot = { ...perkSlot };
      const maper = randomization.perksSlots.length ? randomization.perksSlots[index] : defaultPerks[index];

      newPerkSlot.probability = maper.probability;

      newPerkSlot.perks = newPerkSlot.perks.map((perk) => {
        let checked = false;

        const included = maper.perks.find((item) => item.name === perk.name);

        if (included) {
          checked = true;
        }

        return { ...perk, checked };
      });

      return newPerkSlot;
    });

  const conditions = defaultConditions.map((perk) => {
    let checked = false;

    const included = randomization.conditions.find(
      (item) => item.name === perk.name
    );

    if (included) {
      checked = true;
    }

    return { ...perk, checked };
  });

  return {
    ...props,
    useSpecificModel: !!render?.useSpecificModel,
    materials:
      render?.materials?.map(({ __typename, ...item }) => {
        return {
          ...item,
          id: uuidv4(),
          textures: [],
        };
      }) ?? [],
    marketNameSkin: marketName,
    perksSlot: perkSlots as unknown as IPerkSlot[],
    conditions: conditions as unknown as IPerk[],
    skins: skins?.map((item) => ({ id: item.id, marketName: item.name })),
    isEmptyPerks: false,
  };
};
