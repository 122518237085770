import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { GetUser_lookup_accounts_resolve_overview_inventory_items as InventoryItem } from "pages/UserProfile/query/__generated__/GetUser";
import { columns } from "./consts";
import { useLocalSort } from "utils/mui/useLocalSort";
import { SortCell } from "components/SortCell";
import TableCell from "@mui/material/TableCell";
import { useSearchByNameAndId } from "pages/UserProfile/components/Inventory/utils";

type ModalTableProps = {
  items: InventoryItem[];
  search: string;
  selectedItem: InventoryItem | null;
  onItemSelect: (newItem: string) => void;
};

export const ModalTable = ({
  items,
  search,
  selectedItem,
  onItemSelect,
}: ModalTableProps) => {
  const { sortedData, handleRequestSort, order, getOrderBy } = useLocalSort({
    items,
  });

  const finalData = useSearchByNameAndId(sortedData, search);

  return (
    <TableContainer sx={{ height: 405 }} component={Paper}>
      <Table size="small" aria-label="simple table">
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <SortCell
                key={column.key}
                column={column}
                getOrderBy={getOrderBy}
                order={order}
                handleRequestSort={handleRequestSort}
              />
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {finalData.map((item) => (
            <TableRow
              onClick={() => onItemSelect(item.id)}
              sx={{
                border:
                  item.id === selectedItem?.id ? "2px solid black" : undefined,
                cursor: "pointer",
              }}
              key={item.id}
            >
              {columns.map((el) => (
                <TableCell {...el.rowCellProps} key={el.key}>
                  {el.renderCell(item)}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
