import { styled } from "@mui/system";
import { Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

export const Container = styled("div")({
  marginLeft: "120px",
  position: "relative",
  marginBottom: "50px",
  maxWidth: "600px",
});

export const StyledTextField = styled("input")({
  "borderRadius": "50px",
  "border": "1px solid #DDDDDD",
  "padding": "12px 12px 12px 46px",
  "width": "600px",
  "boxSizing": "border-box",
  "display": "block",
  "position": "relative",
  "&:focus-visible": {
    border: "1px solid #000",
    outline: "none",
  },
});

export const DropdonwContainer = styled("div")({
  width: "600px",
  overflow: "hidden",
  position: "absolute",
  backgroundColor: "#fff",
  top: 55,
  zIndex: 10,
  padding: "20px 30px",
  boxSizing: "border-box",
  boxShadow: "0px 20px 30px rgba(0, 0, 0, 0.2)",
  borderRadius: "10px",
});

export const Filters = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  paddingBottom: "10px",
  borderBottom: "1px solid  #EDEDED",
  marginBottom: "5px",
});

export const StyledButton = styled(Button)(({ active }: { active: any }) => ({
  "fontSize": "12px",
  "padding": "0 2px",
  "borderRadius": "20px",
  "border": "1px solid #A5A5A5",
  "textTransform": "lowercase",
  "marginLeft": "7px",
  "color": active ? "#fff" : "#A5A5A5",
  "backgroundColor": active && "#A5A5A5",
  "&:hover": {
    backgroundColor: active ? "#A5A5A5" : "#fafafa",
    opacity: 0.8,
  },
}));

export const ListItem = styled("div")({
  "padding": "12px 15px",
  "borderRadius": "10px",
  "cursor": "pointer",
  "display": "flex",
  "alignItems": "center",
  "&:hover": {
    backgroundColor: "#EDEDED",
  },
});

export const StyledCloseBtn = styled(CloseIcon)({
  cursor: "pointer",
  position: "absolute",
  color: "#808080",
  zIndex: 11,
  top: 11,
  right: 15,
});
