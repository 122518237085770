import { Generate } from "pages/Gears/Generate";
import { Route, Routes } from "react-router-dom";
import { EditGearCofing } from "./EditGearConfig";
import { NewGear } from "./NewGear";
import { GearsList } from "./GearsList";

export const Gears = () => (
  <Routes>
    <Route path="/" element={<GearsList />} />
    <Route path="/new" element={<NewGear />} />
    <Route path="/generate" element={<Generate />} />
    <Route path="/:id" element={<EditGearCofing />} />
    <Route path="/duplicate/:id" element={<EditGearCofing />} />
  </Routes>
);
