import * as yup from "yup";
import { IImage, ITexture } from "./components/Uploaders/types";
import { IFormWeaponProps, IWeaponMaterial } from "./types";

function hasWhiteSpace(str?: string) {
  if (!str) return false;

  return !/\s/g.test(str);
}

const testIcon = () => ({
  name: "invalid-icon",
  message: "Icon size should be 1500 X 1500px",
  test(icon: IImage) {
    if (icon && icon.height !== 1500) {
      return false;
    }

    if (icon && icon.width !== 1500) {
      return false;
    }

    return true;
  },
});

export const getSchema = ({ type }: Pick<IFormWeaponProps, "type">) => {
  return yup.object({
    id: yup
      .string()
      .test("without space", "Fill in the field without spaces", hasWhiteSpace),
    weaponType: yup.string().required("Fill the field"),
    // weaponSkin: yup
    //   .string()
    //   .test("without space", "Fill in the field without spaces", hasWhiteSpace),
    // marketNameSkin: yup.string().required("Fill the field"),
    nameCounterID: yup
      .string()
      .test("without space", "Fill in the field without spaces", hasWhiteSpace),
    season: yup
      .string(),
    dropType: yup
      .string()
      .test("without space", "Fill in the field without spaces", hasWhiteSpace),
    rarity: yup.number().required("Fill the field"),
    minMintLevel: yup.number().required("Fill the field"),
    // useSpecificModel: yup.boolean(),
    // materials: yup.array().of(
    //   yup.object({
    //     name: yup
    //       .string()
    //       .test(
    //         "without space",
    //         "Fill in the field without spaces",
    //         hasWhiteSpace
    //       ),
    //     withNormalMap: yup.boolean(),
    //     withEmissionMap: yup.boolean(),
    //     withAmbientOcclusion: yup.boolean(),
    //   })
    // ),
    perksSlot: yup.array().of(
      yup.object({
        probability: yup
          .number()
          .required("You need to fill a probability")
          .min(0, "Min is 0")
          .max(1, "Max is 1"),
        perks: yup.array().of(
          yup.object({
            weight: yup.number().min(0, "Min is 0").max(100, "Max is 100"),
            checked: yup.boolean(),
          })
        ),
      })
    ),
    // conditions: yup.array().of(
    //   yup.object({
    //     weight: yup.number().min(0, "Min is 0").max(100, "Max is 100"),
    //     checked: yup.boolean(),
    //   })
    // ),
  });
};
