import { gql } from "@apollo/client";

export const queryGetAvailableSkin = gql`
  query GetAvailableSkin($input: AvailableWeaponSkinsInput!) {
    lookup {
      cgsAdmin {
        weapons {
          availableSkins(input: $input) {
            filters {
              seasons
              weaponTypes
              models
              skins
            }
            skins {
              id
            }
          }
        }
      }
    }
  }
`;
