import React, { useMemo, useState } from "react";
import { Button, Grid, Paper, Typography } from "@mui/material";
import { useUserProfileContext } from "pages/UserProfile/context";
import { getInventoryColumns } from "./utils";
import { ItemsCard } from "./components/ItemsCard";
import { EditModal } from "./components/EditModal";
import { ShardsCard } from "pages/UserProfile/components/Inventory/components/ShardsCard";
import { CurrenciesCard } from "pages/UserProfile/components/Inventory/components/CurrenciesCard";

export const Inventory = () => {
  const { data, holderBalances } = useUserProfileContext();
  const [isItemModalOpen, setIsItemModalOpen] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState<string | null>(null);
  if (!data.overview?.inventory?.items) {
    return null;
  }

  const handleModalClose = () => {
    setIsItemModalOpen(false);
    setSelectedItemId(null);
  };

  const handleSelectItemFromTable = (itemId: string) => {
    setSelectedItemId(itemId);
    setIsItemModalOpen(true);
  };

  const { gears, weapon, outfit } = getInventoryColumns(
    data.overview?.inventory?.items
  );
  const renderData = [
    { label: "Weapon", items: weapon },
    { label: "Outfit", items: outfit },
    { label: "Gears", items: gears },
  ];

  const shards = holderBalances.shards;
  const currencies = [
    holderBalances.orb,
    holderBalances.enrichedOrb,
    holderBalances.butter,
  ];

  const selectedItem = useMemo(() => {
    return (
      data.overview?.inventory?.items?.find((el) => el.id === selectedItemId) ||
      null
    );
  }, [data, selectedItemId]);

  // console.log(selectedItem?.overview?.progress?.level);
  return (
    <>
      <Grid mt={3} item xs={12}>
        <Paper sx={{ p: 2 }}>
          <Grid
            container
            spacing={3}
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item>
              <Typography fontSize={16}>Inventory</Typography>
            </Grid>
            <Grid item>
              <Button
                onClick={() => setIsItemModalOpen(true)}
                sx={{ background: "#E4E4E4", color: "black" }}
              >
                Edit inventory
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Grid item container spacing={2} xs={12}>
        {renderData.map((el) => (
          <ItemsCard
            onItemSelect={handleSelectItemFromTable}
            key={el.label}
            label={el.label}
            items={el.items}
          />
        ))}
        {shards.length > 0 && (
          <ShardsCard
            label="Shards"
            shards={shards}
            onItemSelect={handleSelectItemFromTable}
          />
        )}
        {currencies.length > 0 && (
          <CurrenciesCard
            label="Currencies"
            currencies={currencies}
            onItemSelect={handleSelectItemFromTable}
          />
        )}
      </Grid>
      {isItemModalOpen && (
        <EditModal
          selectedItem={selectedItem}
          onItemSelect={setSelectedItemId}
          onClose={handleModalClose}
        />
      )}
    </>
  );
};
