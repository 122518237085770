import { gql } from "@apollo/client";

export const rewardPushMutation = gql`
  mutation rewardPush(
    $userId: UUID!
    $reasonId: UUID!
    $rewards: [RewardItemInput!]!
  ) {
    rewardPushReward(
      input: {
        userID: $userId
        reason: { id: $reasonId, description: "Reward" }
        rewards: $rewards
      }
    ) {
      ok
    }
  }
`;
