import React from "react";
import moment from "moment";
import { Typography } from "@mui/material";

type TimelineItemProps = {
  label: string;
  datetime: string;
};

export const TimelineItem = ({ label, datetime }: TimelineItemProps) => {
  const momentDate = moment(datetime);
  const date = momentDate.format("YYYY-MM-DD");
  const time = momentDate.format("HH:mm");
  return (
    <div>
      <Typography fontSize={12}>{label}</Typography>
      <Typography fontSize={14}>
        {date}{" "}
        <Typography
          fontSize={14}
          component="span"
          color="rgba(160, 160, 160, 1)"
        >
          {time}
        </Typography>
      </Typography>
    </div>
  );
};
