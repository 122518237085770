import React from "react";
import { useField } from "formik";
import {
  TextField as MUITextField,
  TextFieldProps as MUITextFieldProps,
} from "@mui/material";

export type TextFieldProps = MUITextFieldProps & {
  name: string;
  label?: string;
};

export const TextField = ({ name, label, ...rest }: TextFieldProps) => {
  const [field, meta] = useField(name);
  return (
    <MUITextField
      id={name}
      name={name}
      label={label}
      value={field.value}
      onChange={field.onChange}
      error={meta.touched && Boolean(meta.error)}
      helperText={meta.touched && meta.error}
      {...rest}
    />
  );
};
