import { useMutation } from "@apollo/client";
import {
  Button,
  Dialog,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/system";
import { useClanContext } from "pages/ClanPage/context";
import { clanChangeLeader } from "pages/ClanPage/mutations/clanChangeLeader";
import { clanMemberChangeRole } from "pages/ClanPage/mutations/clanMemberChangeRole";
import { GetClan_lookup_clans_clan_members } from "pages/ClanPage/query/__generated__/GetClan";
import { useState } from "react";
import { toast } from "react-toastify";
import { getToastPromiseConfig } from "utils/toast";

const options = ["MEMBER", "LEADER", "OFFICER"];
export function DialogChangeRole({
  onClose,
  member,
}: {
  onClose: any;
  member?: GetClan_lookup_clans_clan_members;
}) {
  const [changeRole] = useMutation(clanMemberChangeRole);
  const [changeLeader] = useMutation(clanChangeLeader);
  const { data, refetch } = useClanContext();
  const [role, setRole] = useState("");

  const handleChange = (event: SelectChangeEvent) => {
    setRole(event.target.value as string);
  };

  const onClick = () => {
    if (!member) return;
    if (member.role === "LEADER") {
      toast("You can not change role for leader. Please change leader first.");
      return;
    }

    if (role !== "LEADER")
      toast.promise(
        changeRole({
          variables: { playerID: member?.account.userId, newRole: role },
        }),
        getToastPromiseConfig()
      );
    else
      toast.promise(
        changeLeader({
          variables: {
            clanId: data.id,
            playerId: member?.account.userId,
          },
        }),
        getToastPromiseConfig()
      );
    setTimeout(refetch, 500);
    onClose();
  };

  if (!member) return null;

  return (
    <Dialog
      open={true}
      style={{ backdropFilter: "blur(5px)" }}
      onClose={onClose}
    >
      <Box
        component={Paper}
        p={5}
        display="flex"
        flexDirection="column"
        rowGap={2}
      >
        <Typography>Old Role: {member.role}</Typography>
        <Select label="New Role" onChange={handleChange} value={role}>
          {options
            .filter((o) => o !== member?.role)
            .map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
        </Select>
        <Button variant="contained" onClick={onClick} disabled={!member}>
          Apply
        </Button>

        <Button onClick={onClose}>Cancel</Button>
      </Box>
    </Dialog>
  );
}
