export function downloadFile(content: string | null, filename = "file.json") {
  if (!content) return;
  const a = document.createElement("a");
  const BlobFile = new Blob([content], {
    type: "application/json",
  });
  const url = window.URL.createObjectURL(BlobFile);
  a.setAttribute("href", url);
  a.setAttribute("download", filename);
  a.style.display = "none";
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}
