import { MakeoverStatus } from "graphql-global-types/types";
import { Container, FilterItem } from "./Filters.style";
import { useState } from "react";

interface FiltersProps {
    searchWithFilter: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

export const Filters = ({searchWithFilter}: FiltersProps) => {
    const [active, setActive] = useState("");

    const handleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        setActive((e.target as HTMLElement).innerText);
        searchWithFilter(e);
    };

    return (
    <Container>
        {[...Object.keys(MakeoverStatus), "All"]
        .map((key) => <FilterItem active={active === key} key={key} onClick={handleClick}>{key}</FilterItem>)}
    </Container>
);
};
