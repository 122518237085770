import { useState, ChangeEvent, useCallback, useEffect } from "react";
import { LazyQueryExecFunction, OperationVariables } from "@apollo/client";
import { debounce } from "lodash";

import { StyledTextField, Container, StyledCloseBtn } from "./SearchBar.styles";
import { Search } from "@mui/icons-material";
import { GetMakeovers, GetMakeovers_lookup_ugc_makeovers_search_makeovers } from "pages/Makeovers/query/__generated__/GetMakeovers";
import { Filter } from "./Filters/Filters";
import { defaultState } from "pages/Makeovers/Makeovers";

export const playerSearchableFields = [
  "name",
  "makeoverId",
  "authorId",
  "modelIds",
  "statuses",
];
export type FiltersSelection = { [key: string]: boolean }; // actually, only one field will be true at a time

// e.g. ["id", "name", "email"] => {id: false, name: false, email: false}
function getEmptySelection(fields: string[]): FiltersSelection {
  const selection: FiltersSelection = {};
  fields.forEach((f, idx) =>
    idx === 0 ? (selection[f] = true) : (selection[f] = false)
  );
  return selection;
}

export interface State {
  makeoverId: string;
  authorId: string;
  modelIds: never[];
  name: string;
  statuses: string[];
  limit: number;
  pageToken: string;
}

interface SearchProps {
  getData: LazyQueryExecFunction<GetMakeovers, OperationVariables>,
  setMakeovers: React.Dispatch<React.SetStateAction<(GetMakeovers_lookup_ugc_makeovers_search_makeovers | null)[]>>,
  setState: React.Dispatch<React.SetStateAction<State>>,
  state: State,
}

export const SearchField = ({ getData, setMakeovers, setState, state }: SearchProps) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [isActive, setFocusActive] = useState(false); // used to hide dropdown when clicking outside of it


  const [playerFiltersSelection, setPlayerFiltersSelection] =
    useState<FiltersSelection>(() => getEmptySelection(playerSearchableFields));

  const getUsersWithSelectedFilter = (search: string, state: State) => {
    const variables: { [key: string]: string | string[] | number[] } = {};
    playerSearchableFields.forEach((field) => {
      switch (field) {
        case "modelIds":
        case "statuses":
          variables[field] = playerFiltersSelection[field] ? [search] : state[field];
          break;
      
        default:
          variables[field] = playerFiltersSelection[field] ? search : 
          search ? (defaultState as Record<string, any>)[field] : (state as Record<string, any>)[field];
      }
    });
    const newVariables = {...variables, limit: 40};

    setState(newVariables as State);
    getData({ variables: newVariables }).then((res) => setMakeovers(() => res.data?.lookup.ugc?.makeovers?.search.makeovers as (GetMakeovers_lookup_ugc_makeovers_search_makeovers | null)[]));
  };

  const fetch = useCallback(
    debounce((val: string, state: State) => {
      getUsersWithSelectedFilter(val, state);
    }, 500),
    [playerFiltersSelection]
  );

  useEffect(() => {
    if (!searchQuery) return;
    getUsersWithSelectedFilter(searchQuery, state);
  }, [playerFiltersSelection]);

  return (
    <Container>
      <Search
        sx={{
          position: "absolute",
          top: 10,
          left: 15,
          pointerEvents: "none",
          zIndex: 11,
        }}
      />
      <StyledTextField
        value={searchQuery}
        id="search"
        autoComplete="off"
        placeholder="search"
        onFocus={() => setFocusActive(true)}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          setSearchQuery(e.target.value);
          fetch(e.target.value, state);
        }}
      />
      {searchQuery && <StyledCloseBtn onClick={() => setSearchQuery("")} />}
      {isActive && (<Filter 
      playerSearchableFields={playerSearchableFields} 
      setPlayerFiltersSelection={setPlayerFiltersSelection} 
      playerFiltersSelection={playerFiltersSelection}
      setFocusActive={setFocusActive}
      />
      )}
    </Container>
  );
};
