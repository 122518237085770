import { useCallback, useEffect, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { useDropzone } from "react-dropzone";
import { useStyles } from "pages/Events/ClanEvents.style";
import { CREATE_EVENT, CREATE_YML_QUEST } from "mutations/event";
import { GET_EVENT } from "query/event";
import Button from "@mui/material/Button";
import { UpdateClanEvents } from "./UpdateCalnEvent";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import { downloadFile as downloadFileStr } from "utils/downloadFile";
import ReactJson from "react-json-view";
import CircularProgress from "@mui/material/CircularProgress";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { Gap } from "components/Gap";
import { Box } from "components/Box";
import { Link } from "react-router-dom";

export const ClanEvents = ({ icon }: any) => {
  const [viewAsJson, setViewAsJson] = useState(false);
  const [File, setJson] = useState(null as any);
  const [isYml, setYml] = useState(false);

  console.log(File);

  const classes = useStyles(!!icon);

  const [sendFile, { data: d2, error }] = useMutation(CREATE_EVENT, {
    variables: {
      input: File,
    },
  });

  useEffect(() => {
    if (d2?.clanEventCreateEvent) {
      const newFile: { event: JSON; eventID: string } = {
        event: File.event,
        eventID: d2.clanEventCreateEvent.id,
      };
      downloadFile({ ...newFile });
    }
  }, [d2]);

  const [sendYmlFile, { data: d1, error: e2 }] = useMutation(CREATE_YML_QUEST, {
    variables: {
      file: File,
    },
  });

  const { data, loading } = useQuery(GET_EVENT, {
    fetchPolicy: "no-cache",
  });

  const downloadFile = (file: { event: JSON; eventID: string }) => {
    const BlobFile = new Blob([JSON.stringify(file)], {
      type: "application/json",
    });
    const url = window.URL.createObjectURL(BlobFile);
    const a = document.createElement("a");
    a.style.display = "none";
    a.href = url;
    // the filename you want
    a.download = "updated.json";
    document.body.appendChild(a);
    a.click();
  };

  const onDrop = useCallback((acceptedFiles: File[]) => {
    const reader = new FileReader();
    if (acceptedFiles[0].type !== "application/json") setYml(true);

    reader.onload = function (e) {
      ///from yml to string
      if (acceptedFiles[0].type !== "application/json") {
        // const ymlString = Buffer.from(e.target?.result as string, "base64").toString("ascii");

        setJson(e.target?.result);
      } else {
        fetch(e.target?.result as string)
          .then((response) => response.json())
          .then((data) => {
            console.log("data", data);

            setJson({
              startAt: data?.startAt,
              endAt: data?.endAt,
              event: {
                name: data?.name,
                bannerActiveUrl: data?.bannerActiveUrl,
                bannerInactiveUrl: data?.bannerInactiveUrl,
                mainPageBannerHorizontal: data?.mainPageBannerHorizontal,
                mainPageBannerVertical: data?.mainPageBannerVertical,
                visibleAfter: data?.visibleAfter,
                raidLevels: data?.raidLevels,
                rewards: data?.rewards,
                individualRewards: data?.individualRewards,
                missionProgressBoosts: data?.missionProgressBoosts,
              },
              worldDomination: data?.worldDomination,
              raidDurationSeconds: data?.raidDurationSeconds,
              raidMinRewardPercent: data?.raidMinRewardPercent,
            });
          });
      }
    };
    reader.readAsDataURL(acceptedFiles[0]);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({ onDrop });
  console.log("data", data);

  return (
    <>
      <div className={classes.wrapper}>
        <div className={classes.container}>
          <p>Create Event/Quest</p>
          <div {...getRootProps()} className={classes.dropZoneContainer}>
            <input {...getInputProps()} />
            <p>Drag n drop files here, or click to select files</p>
          </div>
          <div style={{ fontSize: "20px", marginBottom: "10px" }}>
            {(d1?.questsSubmitConfigYaml?.ok && "success") ||
              (data?.clanEventCreateEvent?.id && "success") ||
              e2?.message ||
              error?.message}
          </div>
          <Button
            variant="contained"
            onClick={() => (isYml ? sendYmlFile() : sendFile())}
          >
            CREATE
          </Button>
        </div>
        <UpdateClanEvents />

        <div className={classes.flex1} />
        <Box flexDirection="column" justifyContent="space-between">
          <Link to="/changeEventPeriod"> Change Event Period</Link>
          <div className={classes.saveToFileBtn}>
            <Button
              disabled={loading}
              startIcon={<SaveAltIcon />}
              variant="contained"
              onClick={() => downloadFileStr(JSON.stringify(data, null, 2))}
            >
              Save to File
            </Button>
            <Gap w={20} />
            <ToggleButtonGroup
              color="primary"
              value={viewAsJson}
              exclusive
              onChange={(e, n) => setViewAsJson(n)}
              aria-label="Platform"
            >
              <ToggleButton value={false}>Text</ToggleButton>
              <ToggleButton value={true}>JSON</ToggleButton>
            </ToggleButtonGroup>
          </div>
        </Box>
      </div>
      {loading && (
        <Box h="50vh" fullWidth center>
          <CircularProgress />
        </Box>
      )}
      {!viewAsJson && data?.lookup?.clanEvents && (
        <div
          style={{
            height: "750px",
            overflow: "scroll",
            border: "1px solid black",
          }}
        >
          <pre>{JSON.stringify(data?.lookup?.clanEvents?.events, null, 3)}</pre>
        </div>
      )}
      {viewAsJson && data?.lookup?.clanEvents && (
        <ReactJson
          src={data?.lookup?.clanEvents?.events}
          collapsed={true}
          displayObjectSize={false}
          displayDataTypes={false}
        />
      )}
    </>
  );
};
