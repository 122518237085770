import { Box, Grid, TableSortLabel, Typography } from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import { visuallyHidden } from "@mui/utils";
import { TableColumn } from "components/SortCell/types";
import { GetClan_lookup_clans_clan_treasury_balances_mintableTokens } from "pages/ClanPage/query/__generated__/GetClan";
import React, { useMemo, useState } from "react";
import { GetOrderBy, Order } from "utils/mui/sort";
import { useLocalSort } from "utils/mui/useLocalSort";

type ItemsTableProps = {
  items: GetClan_lookup_clans_clan_treasury_balances_mintableTokens[];
  onItemSelect?: (item: string) => void;
};

export const WeaponsTable = ({ items, onItemSelect }: ItemsTableProps) => {
  const [search, setSearch] = useState("");
  const columns = getColumns({ onItemSelect });
  const { sortedData, handleRequestSort, order, getOrderBy } = useLocalSort({
    items,
  });

  const finalData = useSearchByName(sortedData, search);

  return (
    <Grid item xs={6}>
      <Paper sx={{ p: 1 }}>
        <Grid container alignItems="center">
          <Grid item xs={6}>
            <Typography align="center" color="rgba(119, 119, 119, 1)">
              Mintable Tokens ({items.length})
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <TextField
              size="small"
              label="Search"
              variant="outlined"
              fullWidth
              onChange={(e) => setSearch(e.target.value)}
            />
          </Grid>
          <Grid mt={2} item xs={12}>
            <TableContainer sx={{ height: 405 }} component={Paper}>
              <Table size="small" aria-label="simple table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <SortCell
                        key={column.key}
                        column={column}
                        getOrderBy={getOrderBy}
                        order={order}
                        handleRequestSort={handleRequestSort}
                      />
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {finalData.map((item) => (
                    <TableRow key={String(item.configID) + String(item.level)}>
                      {columns.map((el) => (
                        <TableCell {...el.rowCellProps} key={el.key}>
                          {el.renderCell(item)}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

type GetColumnsParams = {
  onItemSelect?: (item: string) => void;
};

export const getColumns = ({
  onItemSelect,
}: GetColumnsParams): TableColumn<GetClan_lookup_clans_clan_treasury_balances_mintableTokens>[] => [
  {
    key: "icon",
    label: "",
    headerCellProps: {
      width: 30,
    },
    renderCell: (row) => (
      <Box
        width={30}
        component="img"
        sx={{
          borderRadius: 1,
        }}
        src={row?.configID || ""}
      />
    ),
  },
  {
    key: "name",
    label: "Name",
    getOrderBy: (data) => data.configID,
    headerCellProps: {
      align: "left",
    },
    rowCellProps: {
      align: "left",
    },
    renderCell: (row) => row.configID,
  },
  {
    key: "level",
    label: "Level",
    getOrderBy: (data) => data.level,
    headerCellProps: {
      align: "left",
    },
    rowCellProps: {
      align: "left",
    },
    renderCell: (row) => row.level,
  },
  {
    key: "quantity",
    label: "Quantity",
    getOrderBy: (data) => data.quantity,
    headerCellProps: {
      align: "left",
    },
    rowCellProps: {
      align: "left",
    },
    renderCell: (data) => data.quantity,
  },
  // {
  //   key: "action",
  //   label: "",
  //   renderCell: (row) => <ActionCell onItemSelect={onItemSelect} row={row} />,
  // },
];

type SortCellProps<T> = {
  column: TableColumn<T>;
  getOrderBy: GetOrderBy<T> | null;
  order: Order;
  handleRequestSort: (newGetOrderBy: GetOrderBy<T>) => void;
};

export const SortCell = <T,>({
  column,
  getOrderBy,
  order,
  handleRequestSort,
}: SortCellProps<T>) => {
  return (
    <TableCell
      sortDirection={
        getOrderBy && column.getOrderBy === getOrderBy ? order : false
      }
      {...column.headerCellProps}
    >
      {column.getOrderBy ? (
        <TableSortLabel
          active={getOrderBy === column.getOrderBy}
          direction={getOrderBy === column.getOrderBy ? order : "asc"}
          onClick={() => handleRequestSort(column.getOrderBy!)}
        >
          {column.label}
          {column.getOrderBy === getOrderBy ? (
            <Box component="span" sx={visuallyHidden}>
              {order === "desc" ? "sorted descending" : "sorted ascending"}
            </Box>
          ) : null}
        </TableSortLabel>
      ) : (
        column.label
      )}
    </TableCell>
  );
};

export const useSearchByName = (
  items: GetClan_lookup_clans_clan_treasury_balances_mintableTokens[],
  search: string
) => {
  const result = useMemo(() => {
    if (search === "") {
      return items;
    }
    return items.filter((el) => {
      const nameMatch = el.configID
        .toLowerCase()
        .includes(search.toLowerCase());
      return nameMatch;
    });
  }, [search, items]);
  return result;
};
