import { useQuery } from "@apollo/client";
import { queryGetAvailableData } from "./availableGearsData.graphql";
import { GetGearAvailableData } from "./__generated__/GetGearAvailableData";

export const useGetGearAvailableData = () => {
  const { data, loading, error } = useQuery<GetGearAvailableData>(
    queryGetAvailableData
  );

  return {
    availableSeasons: data?.lookup.cgsAdmin?.attributes?.availableSeasons ?? [],
    availableGearTypes: data?.lookup.cgsAdmin?.attributes?.availableGearTypes ?? [],
    availableGearRarities: data?.lookup.cgsAdmin?.attributes?.availableGearRarities ?? [],
    availableGearAlignments: data?.lookup.cgsAdmin?.attributes?.availableGearAlignments ?? [],
    availableGearStatNames: data?.lookup.cgsAdmin?.attributes?.availableGearStatNames ?? [],
    availableDropTypes: data?.lookup.cgsAdmin?.attributes?.availableDropTypes ?? [],
    availableGearAllegiances: data?.lookup.cgsAdmin?.attributes?.availableGearAllegiances ?? [],
    availableGearSets: data?.lookup.cgsAdmin?.attributes?.availableGearSets ?? [],
    loading,
    error,
  };
};
