import React, { ReactNode } from "react";
import { Button, Grid, Typography } from "@mui/material";
import { TextField } from "components/formik/TextField";
import { useFormikContext } from "formik";

type ReachFieldProps = {
  icon: ReactNode;
  label: string;
  subLabel: ReactNode;
  name: string;
  initialValue: number;
};

export const ReachField = ({
  icon,
  subLabel,
  label,
  name,
  initialValue,
}: ReachFieldProps) => {
  const { setFieldValue } = useFormikContext();
  const handleReset = () => {
    setFieldValue(name, initialValue);
  };
  return (
    <Grid item container direction="column" spacing={1}>
      <Grid item container justifyContent="space-between">
        <Grid container xs={5} item alignItems="center">
          {icon}
          <Typography component="span">{label}</Typography>
        </Grid>
        <Grid item>{subLabel}</Grid>
      </Grid>

      <Grid item>
        <TextField type="number" fullWidth name={name} />
      </Grid>

      <Grid item>
        <Button size="small" onClick={handleReset}>
          Reset
        </Button>
      </Grid>
    </Grid>
  );
};
