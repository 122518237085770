import { gql } from "@apollo/client";

export const queryGetGearConfig = gql`
  query GetGearConfig($input: SingleGearInput!) {
    lookup {
      cgsAdmin {
        gear {
          single(input: $input) {
            id
            name
            nameCounterID
            season
            dropType
            minMintLevel
            randomization {
              rarity {
                variations {
                name
                weight
              }
              }
              gearType {
                variations {
                name
                weight
              }
              }
              setType {
                variations {
                name
                weight
              }
              }
              alignment {
                variations {
                name
                weight
              }
              }
              allegiance {
                variations {
                name
                weight
              }
              }
              primaryStat {
                name {
                    variations {
                    name
                    weight
                  }
                }
              }
              secondaryStats {
                name {
                  variations {
                    name
                    weight
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
