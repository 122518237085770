import styled from "@emotion/styled";

export const WeaponFormWrapper = styled.div`
  width: 600px;
  margin: 0 auto;
  padding: 20px;

  form {
    & > div {
      margin-bottom: 20px;
    }
  }
`;

export const BtnsContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;