import { useState } from "react";

import { Alert, Box, Button, CircularProgress, TextField } from "@mui/material";
import { CustomSelect } from "../../CgsServise/components/Select";

import { useGetListMintableItemConfig } from "./hooks";
import { useGetAvailableData } from "../FormWeapon/hooks";
import { InView } from "react-intersection-observer";
import { WeaponCard } from "./WeaponCard";
import { useDebounce } from "use-debounce";
import { useMutation } from "@apollo/client";
import { DEPLOY_WEAPONS } from "mutations/weapons";
import { useNavigate } from "react-router-dom";

const LIMIT = 32;

export const WeaponList: React.FC = () => {
  const [query, setQuery] = useState("");
  const [debouncedQuery] = useDebounce(query, 500);
  const navigate = useNavigate();
  const [isLast, setIsLast] = useState(false);
  const [season, setSeason] = useState("Season4");
  const { data, loading, error, fetchMore } = useGetListMintableItemConfig({
    query: debouncedQuery,
    limit: LIMIT,
    season,
    pageToken: "",
  });

  const { availableSeasons } = useGetAvailableData();

  const [deployWeapons] = useMutation(DEPLOY_WEAPONS);

  const fetchNextData = () => {
    fetchMore({
      variables: {
        query: "",
        limit: LIMIT,
        pageToken: data.nextPageToken,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;

        const oldItems = prev.lookup.cgsAdmin?.weapons?.search.configs || [];
        const newItems = fetchMoreResult.lookup.cgsAdmin?.weapons?.search.configs || [];

        if (!data?.nextPageToken) {
          setIsLast(true);
        }

        return {
          lookup: {
            __typename: "Lookup",
            cgsAdmin: {
              __typename: "CGSAdmin",
              weapons: {
                __typename: "WeaponsLookup",
                search: {
                  __typename: "SearchWeaponsResult",
                  configs: oldItems.concat(newItems),
                  nextPageToken:
                    fetchMoreResult.lookup.cgsAdmin?.weapons?.search.nextPageToken || "",
                },
              },
            }
          },
        };
      },
    });
  };
  const clallBackInview = async (inView: boolean) => {
    if (isLast || !data.nextPageToken) {
      return;
    }

    if (inView && !error && !loading) {
      fetchNextData();
    }
  };

  return (
    <div>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <TextField
          value={query}
          onChange={(ev) => setQuery(ev.target.value)}
          label="Config name"
          sx={{ width: 300 }}
        />
        <Box>
          <CustomSelect
            name="season-id"
            label="season"
            opts={availableSeasons}
            sx={{ width: 150 }}
            value={season}
            setFn={setSeason}
          />
        </Box>
        <Button
          onClick={() =>
            deployWeapons({ variables: { season } })
              .then(() => alert("successfull"))
              .catch((e: Error) => alert(e.message))
          }
          variant="contained"
        >
          Deploy Weapons
        </Button>
        <Button onClick={() => navigate("newv2")} variant="contained">
          New config v2
        </Button>
        <Button onClick={() => navigate("new")} variant="contained">
          New config
        </Button>
      </Box>

      <Box display="flex" flexWrap="wrap" mx={-2} mt={2}>
        {data?.configs?.map((item) => {
          return (
            <WeaponCard
              id={item.id}
              img={item.skinImageUrl}
              isV2={!!item?.skins?.length}
              key={item.id}
              deployWeapons={deployWeapons}
            />
          );
        })}
      </Box>
      <InView onChange={clallBackInview}>
        {(props) => {
          let content = null;

          if (loading) {
            content = (
              <Box display="flex" justifyContent="center">
                <CircularProgress />
              </Box>
            );
          }

          if (error) {
            content = (
              <Alert severity="error">
                Error! Сause: <b>{error.message}</b>.{" "}
                <Button onClick={fetchNextData}>Refetch</Button>
              </Alert>
            );
          }

          if (isLast && !error) {
            content = <Alert severity="success">Configs are all loaded</Alert>;
          }

          return <Box ref={props.ref}>{content}</Box>;
        }}
      </InView>
    </div>
  );
};
