import { gql } from "@apollo/client";

export const GET_FILESTREE = gql`
  {
    lookup {
      cgsAdmin {
        characters {
          files {
            isPart
            name
            attributeName
            attributeValue
            errors
            children {
              isPart
              name
              attributeName
              attributeValue
              errors
              children {
                isPart
                name
                attributeName
                attributeValue
                errors
                children {
                  isPart
                  name
                  attributeName
                  attributeValue
                  errors
                  children {
                    isPart
                    name
                    attributeName
                    attributeValue
                    errors
                    children {
                      isPart
                      name
                      attributeName
                      attributeValue
                      errors
                      children {
                        isPart
                        name
                        attributeName
                        attributeValue
                        errors
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_COLLECTIONS = gql`
  {
    lookup {
      cgsAdmin {
        characters {
          collections {
            id
            userText
            params {
              name
              family
              description
              tokenNameTemplate
              tokenNameTemplate
              season
              nameCounter
              dropType
            }
          }
        }
      }
    }
  }
`;
/// check for RPM
export const GET_COLLECTION = gql`
  query search($id: String!) {
    lookup {
      cgsAdmin {
        characters {
          collection(input: { id: $id }) {
            presets {
              id
              tokenAmount
              name
              currentTokenAmount
              RequireRPM 
            }
            marketingNames {
              attributeName
              attributeValue
              marketingName
            }
            hiddenAttributes {
              name
              probability
              values {
                name
                weight
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_TASK_STATUS = gql`
  query {
    lookup {
      cgsAdmin {
        tasks {
          currentTaskProgress {
            task {
              id
              number
              description
              status
              error
              progressNow
              progressMax
              nftContentSha
              duration
            }
            totalTasksInQueue
          }
        }
      }
    }
  }
`;

export const GET_TASKS = gql`
  query search($offset: Int!, $limit: Int!) {
    lookup {
      cgsAdmin {
        tasks {
          tasks(input: { offset: $offset, limit: $limit }) {
            id
            number
            description
            error
            progressNow
            progressMax
            createDate
            status
            nftContentSha
            duration
          }
        }
      }
    }
  }
`;

export const GET_PRESET = gql`
  query search($id: String!, $presetID: String!) {
    lookup {
      cgsAdmin {
        characters {
          collection(input: { id: $id }) {
            preset(input: { presetID: $presetID }) {
              id
              collectionID
              name
              baseRarity
              minMintLevel
              iconTokenID
              background
              tokenAmount
              iconsAttempt
              renderMode
              makeoverTokenID
              generationLock
              RequireRPM
              icons {
                tokenID
                imgData
              }
              currentTokenAmount
              attributes {
                name
                probability
                values {
                  name
                  weight
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_ICONS_ATTEMPTS = gql`
  query search($id: String!) {
    lookup {
      cgsAdmin {
        characters {
          collection(input: { id: $id }) {
            iconAttempts
          }
        }
      }
    }
  }
`;

export const GET_RENDERMODE_LIST = gql`
  query {
    lookup {
      cgsAdmin {
        characters {
          availableRenderModes
        }
      }
    }
  }
`;