import { styled } from "@mui/system";

export const Container = styled("div")({
  "display": "flex",
  "backgroundColor": "#fff",
  "alignItems": "center",
  "&:first-child": {
    marginRight: "69px",
  },
});
