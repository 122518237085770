import React from "react";
import { useMutation } from "@apollo/client";
import { inventoryDeleteAccountItem } from "pages/UserProfile/mutations/inventoryDeleteAccountItem.graphql";
import { queryGetUser } from "pages/UserProfile/query/getUser.graphql";
import {
  GetUser_lookup_accounts_resolve_overview_inventory_items as InventoryItem,
  GetUser_lookup_accounts_resolve_overview_inventory_items,
} from "pages/UserProfile/query/__generated__/GetUser";
import { useUserProfileContext } from "pages/UserProfile/context";
import { CircularProgress, IconButton, Menu, MenuItem } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";

type ActionCellProps = {
  row: GetUser_lookup_accounts_resolve_overview_inventory_items;
  onItemSelect: (item: string) => void;
};

export const ActionCell = ({ row, onItemSelect }: ActionCellProps) => {
  const { data } = useUserProfileContext();
  const [deleteItem, { loading }] = useMutation(inventoryDeleteAccountItem, {
    refetchQueries: [{ query: queryGetUser, variables: { id: data.userId } }],
    variables: {
      userId: data.userId,
      itemId: row.id,
    },
  });
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = () => {
    deleteItem().then(handleClose);
  };

  const handleItemSelect = (item: InventoryItem) => {
    onItemSelect(item.id);
    handleClose();
  };
  return (
    <>
      <IconButton onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      {open && (
        <Menu id="basic-menu" anchorEl={anchorEl} open onClose={handleClose}>
          {loading ? (
            <CircularProgress sx={{ m: 2 }} />
          ) : (
            [
              <MenuItem key="edit" onClick={() => handleItemSelect(row)}>
                Edit
              </MenuItem>,
              <MenuItem
                sx={{ color: "#f44336" }}
                key="delete"
                onClick={handleDelete}
              >
                Delete
              </MenuItem>,
            ]
          )}
        </Menu>
      )}
    </>
  );
};
