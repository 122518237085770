import ReactDOM from "react-dom/client";
import { HashRouter } from "react-router-dom";
import "index.css";
import App from "./App";
import { ApolloProvider } from "@apollo/client";
import { ThemeProvider } from "@mui/material";
import { theme } from "./theme";
import { client } from "./apolloClient";

import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const container = document.getElementById("root");
const root = ReactDOM.createRoot(container as HTMLElement);

root.render(
  <HashRouter>
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <ApolloProvider client={client}>
        <ThemeProvider theme={theme}>
          <ToastContainer />
          <App />
        </ThemeProvider>
      </ApolloProvider>
    </LocalizationProvider>
  </HashRouter>
);
