import React, { useMemo, useState } from "react";
import { Button, Dialog, DialogContent, Grid } from "@mui/material";
import { DialogTitleWithCross } from "components/DialogTitleWithCross";
import TextField from "@mui/material/TextField";
import { ModalTable } from "./components/ModalTable";
import { ModalCard } from "./components/ModalCard";
import { getInventoryColumns, TableType, typeMapper } from "../../utils";
import { useUserProfileContext } from "pages/UserProfile/context";
import { GetUser_lookup_accounts_resolve_overview_inventory_items as InventoryItem } from "pages/UserProfile/query/__generated__/GetUser";

type EditModalProps = {
  onClose: () => void;
  selectedItem: InventoryItem | null;
  onItemSelect: (newItem: string | null) => void;
};

const filtersList = [
  { label: "Weapons", type: TableType.weapon },
  { label: "Outfit", type: TableType.outfit },
  { label: "Gears", type: TableType.gears },
];

const getDefaultTableType = (item: InventoryItem | null) => {
  if (!item) {
    return TableType.weapon;
  }
  if (typeMapper[TableType.outfit].includes(item.overview.type)) {
    return TableType.outfit;
  }
  if (typeMapper[TableType.weapon].includes(item.overview.type)) {
    return TableType.weapon;
  }
  return TableType.gears;
};

export const EditModal = ({
  onClose,
  onItemSelect,
  selectedItem,
}: EditModalProps) => {
  const [currentTableType, setCurrentTableType] = useState<TableType>(() =>
    getDefaultTableType(selectedItem)
  );
  const [search, setSearch] = useState("");
  const { data } = useUserProfileContext();
  const inventoryColumns = getInventoryColumns(
    data.overview!.inventory!.items!
  );
  const currentTableItems = useMemo(() => {
    switch (currentTableType) {
    case TableType.gears:
      return inventoryColumns.gears;
    case TableType.outfit:
      return inventoryColumns.outfit;
    case TableType.weapon:
      return inventoryColumns.weapon;
    }
  }, [inventoryColumns]);
  return (
    <Dialog fullWidth maxWidth="lg" open onClose={onClose}>
      <DialogTitleWithCross onClose={onClose}>
        Edit inventory
      </DialogTitleWithCross>
      <DialogContent>
        <Grid container spacing={3}>
          <Grid item container xs={7} spacing={2}>
            {filtersList.map((el) => (
              <Grid item xs={4} key={el.label}>
                <Button
                  variant="contained"
                  fullWidth
                  color={el.type === currentTableType ? "primary" : "secondary"}
                  onClick={() => setCurrentTableType(el.type)}
                >
                  {el.label}
                </Button>
              </Grid>
            ))}
            <Grid item xs={12}>
              <TextField
                size="small"
                label="Item name or ID"
                variant="outlined"
                fullWidth
                onChange={(e) => setSearch(e.target.value)}
              />
            </Grid>

            <Grid item xs={12}>
              <ModalTable
                selectedItem={selectedItem}
                onItemSelect={onItemSelect}
                items={currentTableItems}
                search={search}
              />
            </Grid>
          </Grid>

          <ModalCard
            onItemSelect={onItemSelect}
            key={selectedItem?.id}
            item={selectedItem}
          />
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
