import { gql } from "@apollo/client";

export const gameAccountSetExperienceMutation = gql`
  mutation gameAccountSetExperience($userId: UUID!, $experience: Int!) {
    gameAccountSetExperience(
      input: { userID: $userId, experience: $experience }
    ) {
      ok
    }
  }
`;
