import styled from "@emotion/styled";

export const GearFormWrapper = styled.div`
  width: 600px;
  margin: 0 auto;
  padding: 20px;
  position: relative;
  form {
    & > div {
      margin-bottom: 20px;
    }
  }
`;
