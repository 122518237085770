import { GetClan_lookup_clans_clan_treasury_balances_offchainFunds } from "pages/ClanPage/query/__generated__/GetClan";
import { TableColumn } from "components/SortCell/types";
import { ActionCell } from "./ActionCell";

export const getColumns =
  (): TableColumn<GetClan_lookup_clans_clan_treasury_balances_offchainFunds>[] => [
    {
      key: "accountType",
      label: "Name",
      getOrderBy: (data) => data.accountType,
      headerCellProps: { align: "left" },
      rowCellProps: { align: "left" },
      renderCell: (row) => row.accountType,
    },
    {
      key: "quantity",
      label: "Amount",
      getOrderBy: (data) => data.quantity,
      headerCellProps: { align: "left" },
      rowCellProps: { align: "left" },
      renderCell: (row) => row.quantity,
    },
    {
      key: "action",
      label: "",
      renderCell: (row) => <ActionCell row={row} />,
    },
  ];
