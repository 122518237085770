import { gql } from "@apollo/client";

export const queryGetPerksAndConditions = gql`
  query GetPerksAndConditions {
    lookup {
      cgsAdmin {
        attributes {
          defaultWeaponPerksSlots {
            probability
            perks {
              name
              weight
            }
          }
          defaultWeaponConditions {
            name
            weight
          }
        }
      }
    }
  }
`;
