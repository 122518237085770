import CircleIcon from "@mui/icons-material/Circle";
import EditIcon from "@mui/icons-material/Edit";
import { Grid, IconButton } from "@mui/material";
import Typography from "@mui/material/Typography";
import { Wrapper } from "pages/UserProfile/components/BaseInfo/components/BaseInfoItem/BaseInfoItem.styles";
import React from "react";
import s from "../ClanPage.module.css";

export type BaseInfoItemProps = {
  label: string;
  value: string;
  onEdit?: React.MouseEventHandler;
};
export const ClanBaseInfoItem = ({
  label,
  value,
  onEdit,
}: BaseInfoItemProps) => {
  return (
    <Grid key={label} item xs={6}>
      <Wrapper sx={{ display: "flex" }}>
        <CircleIcon sx={{ color: "#D9D9D9", mr: 1, mt: 1 }} />
        <div className={s.baseItem}>
          <Typography fontSize={12} color="rgba(119, 119, 119, 1)">
            {label}
          </Typography>
          <Typography fontSize={14}>{value}</Typography>
        </div>
        {onEdit && (
          <div>
            <IconButton onClick={onEdit} sx={{ ml: 1, alignSelf: "top" }}>
              <EditIcon sx={{ color: "#777777" }} />
            </IconButton>
          </div>
        )}
      </Wrapper>
    </Grid>
  );
};
