import {
  Typography,
  Button,
  Box,
  CircularProgress,
  Alert,
} from "@mui/material";
import { CheckboxWrapper } from "components/CheckboxWrapper";
import { WeaponFormWrapper } from "./styles";
import { Formik, Form } from "formik";

import { SelectRarity, TextFieldWrapper } from "./components";
import { SelectWrapper } from "./components/SelectWrapper";
import {
  formatConditionsToFileds,
  formatPerksToFileds,
  initForm,
} from "./contstants";

import { MaterialDrawer } from "./MaterialDrawer";
import { SkinsDrawer } from "./SkinsDrawer";
import { PerksAndConditionsDrawer } from "./PerksAndConditionsDrawer";
import { getSchema } from "./validation";
import { useGetAvailableData } from "./hooks";

import { IFormWeaponProps } from "./types";
import { useGetPerksAndConditions } from "../hooks";

export const FormWeapon: React.FC<IFormWeaponProps> = ({
  type = "new",
  onSubmit,
  errorMessage,
  initConfigWeaponProps,
}) => {
  const { defaultWeaponConditions, defaultWeaponPerksSlots, loading } =
    useGetPerksAndConditions();
  const {
    availableWeaponTypes,
    availableSeasons,
    availableDropTypes,
    availableNameCounters,
  } = useGetAvailableData();

  if (
    loading &&
    !defaultWeaponConditions.length &&
    !defaultWeaponPerksSlots.length
  ) {
    return (
      <WeaponFormWrapper>
        <Box display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      </WeaponFormWrapper>
    );
  }

  const newInitValue = {
    ...initForm,
    perksSlot: formatPerksToFileds(defaultWeaponPerksSlots),
    conditions: formatConditionsToFileds(defaultWeaponConditions),
  };

  const isV2 = window.location.href.includes("v2");
  return (
    <Formik
      initialValues={initConfigWeaponProps ?? newInitValue}
      onSubmit={onSubmit}
      validationSchema={getSchema({ type })}
    >
      {({ isSubmitting }) => {
        return (
          <WeaponFormWrapper>
            <Form>
              <TextFieldWrapper
                name="id"
                label="Id"
                disabled={type === "edit"}
              />
              {type !== "new" && isV2 && (
                <TextFieldWrapper name="name" label="Name" />
              )}
              <SelectWrapper
                opts={availableWeaponTypes}
                label="Weapon type"
                name="weaponType"
              />
              {type !== "newv2" && isV2 && (
                <TextFieldWrapper name="weaponSkin" label="Weapon Skin" />
              )}
              {type !== "newv2" && !isV2 && (
                <TextFieldWrapper
                  name="marketNameSkin"
                  label="Market Name Skin"
                />
              )}
              <TextFieldWrapper name="minMintLevel" label="Min Mint Level" />

              <SelectWrapper
                opts={availableNameCounters}
                label="Name CounterID"
                name="nameCounterID"
              />
              <SelectWrapper
                opts={[...availableSeasons, "Season5"]}
                label="Season"
                name="season"
              />
              <SelectWrapper
                opts={availableDropTypes}
                label="Drop Type"
                name="dropType"
              />
              <SelectRarity />
              <CheckboxWrapper name="isStandard" label="Is standard" />
              {type === "newv2" || isV2 ? (
                <SkinsDrawer
                  availableSeasons={availableSeasons}
                  availableWeaponTypes={availableWeaponTypes}
                />
              ) : (
                <MaterialDrawer />
              )}
              <Box display="flex" justifyContent="space-between">
                <Typography variant="h6" gutterBottom component="div">
                  Perks & Conditions
                </Typography>
                <PerksAndConditionsDrawer />
              </Box>

              {errorMessage && !isSubmitting ? (
                <Alert severity="error">{errorMessage}</Alert>
              ) : null}

              <Box display="flex" justifyContent="end">
                <Button
                  type="submit"
                  variant="contained"
                  disabled={isSubmitting}
                >
                  Send
                </Button>
              </Box>
            </Form>
          </WeaponFormWrapper>
        );
      }}
    </Formik>
  );
};
