import { useCallback, useEffect } from "react";
import { FiltersSelection } from "../SearchBar";
import { Filters, StyledButton, DropdonwContainer } from "../SearchBar.styles";

interface FilterProps {
    playerSearchableFields: string[];
    setPlayerFiltersSelection: any;
    playerFiltersSelection: FiltersSelection;
    setFocusActive: any;
}

function selectGivenField(
    selection: FiltersSelection,
    fieldToSelect: string
  ): FiltersSelection {
    const result: FiltersSelection = {};
    Object.keys(selection).forEach((field: string) => {
      result[field] = field === fieldToSelect;
    });
    return result;
  }

export const Filter = ({playerSearchableFields, playerFiltersSelection, setPlayerFiltersSelection, setFocusActive  }: FilterProps) => {

    const onClick = useCallback((event: MouseEvent) => {
        const path =
          (event as any).path || (event.composedPath && event.composedPath());
        const isInsideClick = path.find(
          (item: Element) => item?.id === "search"
        )?.id;
        if (!isInsideClick) {
          setFocusActive(false);
        }
      }, []);
    
      useEffect(() => {
        document.addEventListener("click", onClick);
        return () => {
          document.removeEventListener("click", onClick);
        };
      }, []);

    return (<DropdonwContainer id="search">
    <Filters>
    <div>Filters</div>
    <div>
      {playerSearchableFields.map((item) => (
        <StyledButton
          key={item}
          active={playerFiltersSelection[item]}
          onClick={() => setPlayerFiltersSelection((prev: any) => selectGivenField(prev, item))}
        >
          {item}
        </StyledButton>
      ))}
    </div>
  </Filters>
  </DropdonwContainer>);
};