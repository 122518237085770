import { useNavigate } from "react-router-dom";
import { editDataBeforeRequest } from "../EditGearConfig/constants";
import { FormGear } from "../GearForm";

import { GearForm } from "../GearForm/types";
import { useGearConfigCreate } from "./hooks";
import { toast } from "react-toastify";
import { getToastPromiseConfig } from "utils/toast";

export const NewGear = () => {
  const { gearConfigCreate } = useGearConfigCreate();

  const navigate = useNavigate();

  const onSubmit = (values: GearForm) => {
    const data = editDataBeforeRequest(values);
    toast
      .promise(
        gearConfigCreate({
          variables: data,
        }),
        getToastPromiseConfig()
      )
      .then(() => navigate(".."));
  };

  return (
    <FormGear type="new" onSubmit={onSubmit} />
  );
};
