import { useEffect, useState } from "react";
import { Button, Chip, Divider, Grid, Paper } from "@mui/material";
import { useQuery } from "hooks/useQuery";
import Typography from "@mui/material/Typography";
import EditIcon from "@mui/icons-material/Edit";
import { BaseInfoItem } from "./components/BaseInfoItem";
import { TimelineItem } from "./components/TimelineItem";
import { PlayerEditModal } from "./components/PlayerEditModal";
import { useUserProfileContext } from "../../context";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import TagIcon from "@mui/icons-material/Tag";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import HeadsetMicIcon from "@mui/icons-material/HeadsetMic";

export const BaseInfo = () => {
  const query = useQuery();
  const isEditModalOpenByDefault = !!query.get("edit");

  const [isPlayerEditModal, setPlayerEditModal] = useState(
    isEditModalOpenByDefault
  );
  const { data: userInfo } = useUserProfileContext();

  const baseInfoData = [
    {
      label: "Email",
      value: userInfo.personalInfo.email,
      icon: AlternateEmailIcon,
    },
    {
      label: "UserID",
      value: userInfo.userId,
      icon: PersonOutlineIcon,
    },
    {
      label: "Suffix",
      value: userInfo.ident.suffix,
      icon: TagIcon,
    },
    {
      label: "Wallet address",
      value: userInfo.overview.wallet?.pubKey,
      icon: AccountBalanceWalletIcon,
    },
    {
      label: "Discord name",
      value: userInfo.overview.discord?.username,
      icon: HeadsetMicIcon,
    },
  ];
  const getTimelineData = () => {
    const res = [
      {
        label: "Created at",
        datetime: userInfo.personalInfo.timeline.createdAt,
      },
      {
        label: "Verified status",
        datetime: userInfo.personalInfo.timeline.verifiedAt,
      },
    ];

    if (userInfo.overview.bans.active) {
      res.push({
        label: "Banned From",
        datetime: userInfo.overview.bans.active.startsAt,
      });
    }
    if (userInfo.overview.bans.active) {
      res.push({
        label: "Banned till",
        datetime: userInfo.overview.bans.active.expiresAt,
      });
    }

    return res;
  };

  const timelineData = getTimelineData();

  return (
    <Grid mt={3} item xs={12}>
      <Paper sx={{ p: 2 }}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Typography fontWeight={700} fontSize={18}>
              {userInfo.ident.username}
            </Typography>
          </Grid>
          <Grid item>
            <Button
              onClick={() => setPlayerEditModal(true)}
              color="secondary"
              variant="contained"
              startIcon={<EditIcon />}
            >
              Edit player
            </Button>
          </Grid>
        </Grid>
        <Divider sx={{ mt: 2, mb: 2 }} />
        <Grid container spacing={2}>
          {baseInfoData.map((el) => (
            <Grid key={el.label} item xs={6}>
              <BaseInfoItem label={el.label} value={el.value} icon={el.icon} />
            </Grid>
          ))}
        </Grid>

        <Grid
          p={2}
          mt={2}
          alignItems="center"
          container
          sx={{ background: "#F4F4F4" }}
        >
          {timelineData.map((el) => (
            <Grid xs={2} pl={2} item key={el.label}>
              <TimelineItem label={el.label} datetime={el.datetime} />
            </Grid>
          ))}
          <Grid item xs={2} />

          {userInfo.overview.bans.active && (
            <Grid display="flex" item xs={2} justifyContent="flex-end">
              <Chip label="Banned" />
            </Grid>
          )}
        </Grid>
      </Paper>
      {isPlayerEditModal && (
        <PlayerEditModal
          onClose={() => {
            if (isEditModalOpenByDefault)
              window.location.href = window.location.href.replace(
                "?edit=true",
                ""
              );
            setPlayerEditModal(false);
          }}
        />
      )}
    </Grid>
  );
};
