import React, { useState } from "react";
import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import { toast } from "react-toastify";
import { getToastPromiseConfig } from "utils/toast";

const formatter = new Intl.NumberFormat("ru-RU");

interface MinorSendFieldProps {
  label: string;
  currentValue?: number;
  sendReward: (value: number) => Promise<unknown>;
}

export const MinorSendField = ({
  label,
  currentValue,
  sendReward,
}: MinorSendFieldProps) => {
  const [inputValue, setInputValue] = useState<string>("");
  const handleClick = () => {
    toast.promise(sendReward(+inputValue || 0), getToastPromiseConfig());
  };
  return (
    <Grid item xs={6}>
      <Typography color="rgba(119, 119, 119, 1)" mb={1}>
        Current {label}:{" "}
        <Typography color="black" component="span">
          {currentValue ? formatter.format(currentValue) : "не число"}
        </Typography>
      </Typography>
      <Box display="flex" sx={{ background: "#F4F4F4" }}>
        <TextField
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          sx={{ width: "80%", background: "white" }}
          label={`Send ${label}`}
          variant="outlined"
        />
        <Button
          disabled={!inputValue}
          onClick={handleClick}
          sx={{ width: "20%", height: "56px" }}
          size="large"
        >
          Send
        </Button>
      </Box>
    </Grid>
  );
};
