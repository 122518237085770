import { useLazyQuery, useMutation } from "@apollo/client";
import { Button, Dialog, Paper, TextField } from "@mui/material";
import { Box } from "@mui/system";
import { GetUsers } from "components/SearchBar/query/__generated__/GetUsers";
import { GET_ALL_USERS } from "components/SearchBar/query/getUsers.graphql";
import { useClanContext } from "pages/ClanPage/context";
import { clanAddMember } from "pages/ClanPage/mutations/clanAddMember";
import { useState } from "react";
import { toast } from "react-toastify";
import { getToastPromiseConfig } from "utils/toast";
import s from "../ClanPage.module.css";

export function DialogAddMember({ onClose }: { onClose: () => void }) {
  const [addMember] = useMutation(clanAddMember);
  const { data, refetch } = useClanContext();
  const [member, setMember] = useState("");
  const [getPlayers, { data: users }] = useLazyQuery<GetUsers>(GET_ALL_USERS);

  const handleTextChange = (val: string) => {
    getPlayers({ variables: { username: val } });
    setMember(val);
  };

  const onApply = () => {
    if (!member) return;
    toast.promise(
      addMember({
        variables: { id: member, clanId: data.id },
      }),
      getToastPromiseConfig()
    );
    setTimeout(refetch, 500);
    onClose();
  };

  const handleSelect = (val: string) => {
    setMember(val);
  };
  return (
    <Dialog
      open={true}
      style={{ backdropFilter: "blur(5px)" }}
      onClose={onClose}
    >
      <Box
        component={Paper}
        p={5}
        display="flex"
        flexDirection="column"
        rowGap={2}
      >
        <TextField
          style={{ width: 400 }}
          value={member}
          onChange={(e) => handleTextChange(e.target.value)}
          label="User ID"
          variant="standard"
        />

        {users?.lookup.accounts.search?.items.slice(0, 6).map((user) => {
          return (
            <div
              className={s.pointer}
              key={user.userId}
              onClick={() => handleSelect(user.userId)}
            >
              {user.ident.username + " " + user.ident.suffix}
            </div>
          );
        })}
        <Button variant="contained" onClick={onApply} disabled={!member}>
          Apply
        </Button>

        <Button onClick={onClose}>Cancel</Button>
      </Box>
    </Dialog>
  );
}
