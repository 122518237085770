import { useQuery } from "@apollo/client";
import { getListGearsConfig } from "./getListGearsConfig.graphql";
import {
  GetListGearsConfig,
  GetListGearsConfigVariables,
} from "./__generated__/GetListGearsConfig";

export const useGetListGearsConfig = (
  input: GetListGearsConfigVariables
) => {
  const { data, loading, error, fetchMore, networkStatus } = useQuery<
  GetListGearsConfig,
  GetListGearsConfigVariables
  >(getListGearsConfig, {
    variables: {
      ...input,
    },
    notifyOnNetworkStatusChange: true,
  });

  return {
    data: data?.lookup.cgsAdmin?.gear?.search || { configs: [], nextPageToken: "" },
    loading,
    error,
    fetchMore,
    networkStatus,
  };
};
