import { gql } from "@apollo/client";

export const getAllShardsQuery = gql`
  query getAllShards {
    lookup {
      shards {
        allRecipes {
          id
          configID
          initialLevel
          paymentDetails {
            amount
            currency
          }
          shardsRequired
        }
      }
    }
  }
`;
