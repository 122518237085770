import { SelectWrapper } from "./components/SelectWrapper";
import { TextFieldWrapper } from "./components";
import { useGetAvailableSkinData } from "./hooks/useGetAvailableSkinData";
import { Box } from "@mui/material";

export const SkinsView = ({ index, availableSeasons, availableWeaponTypes }: {index: number, availableWeaponTypes: any[], availableSeasons: any[]}) => {
  const { availableModels, availableSkins, availableSkinIds } = useGetAvailableSkinData(index);
  return (
    <>
      <Box 
        display="flex" 
        flexDirection="column"
        gap={1}
        marginBottom="20px"
        padding="20px"
        border="1px solid black"
        borderRadius="20px"
      >
        <SelectWrapper
          opts={availableSeasons}
          disabled
          label="Season"
          name="season"
        />
        <SelectWrapper
          opts={availableWeaponTypes}
          disabled
          label="Weapon type"
          name="weaponType"
        />
        <SelectWrapper
          opts={availableModels}
          label="Model"
          name={`skins.${index}.model`}
        />
        <SelectWrapper
          opts={availableSkins}
          label="Skin"
          name={`skins.${index}.skin`}
        />
        <SelectWrapper
          opts={availableSkinIds.map(item => item.id)}
          label="Current skin ID"
          name={`skins.${index}.id`}
        />
        <TextFieldWrapper
          label="Market name"
          name={`skins.${index}.marketName`}
        />
      </Box>
    </>
  );
};