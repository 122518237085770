import { createContext, useContext } from "react";
import { GetClan_lookup_clans_clan } from "pages/ClanPage/query/__generated__/GetClan";

export interface IClanInfoContext {
  data: GetClan_lookup_clans_clan;
  refetch: any;
}

export const ClanInfoContext = createContext<IClanInfoContext | null>(null);

export const useClanContext = (): IClanInfoContext => {
  const contextValue = useContext(ClanInfoContext);
  if (!contextValue) {
    throw new Error("UserProfileContext.Provider is not found in tree");
  }
  return contextValue;
};
