import { useMutation } from "@apollo/client";
import { Button, Dialog, Paper, TextField } from "@mui/material";
import { Box } from "@mui/system";
import { useClanContext } from "pages/ClanPage/context";
import { clanUpdateFields } from "pages/ClanPage/mutations/clanUpdateFields";
import { useState } from "react";
import { toast } from "react-toastify";
import { getToastPromiseConfig } from "utils/toast";

export function DialogChangeClanField({
  onClose,
  field,
}: {
  onClose: () => void;
  field: string;
}) {
  const [mutation] = useMutation(clanUpdateFields);
  const { data, refetch } = useClanContext();
  const [fieldValue, setFieldValue] = useState("");

  const onClick = () => {
    if (!fieldValue) return;
    toast.promise(
      mutation({
        variables: { clanID: data.id, [field]: fieldValue },
      }),
      getToastPromiseConfig()
    );
    setTimeout(refetch, 500);
    onClose();
  };

  return (
    <Dialog
      open={true}
      style={{ backdropFilter: "blur(5px)" }}
      onClose={onClose}
    >
      <Box
        component={Paper}
        p={5}
        display="flex"
        flexDirection="column"
        rowGap={2}
      >
        <TextField
          value={fieldValue}
          label={`New clan ${field}`}
          variant="standard"
          onChange={(e) => setFieldValue(e.target.value)}
        />
        <Button variant="contained" onClick={onClick} disabled={!fieldValue}>
          Apply
        </Button>

        <Button onClick={onClose}>Cancel</Button>
      </Box>
    </Dialog>
  );
}
