import { createTheme } from "@mui/material";

export const theme = createTheme({
  palette: {
    primary: {
      main: "#000000",
    },
    secondary: {
      main: "#ffffff",
    },
  },
  typography: {
    fontFamily: "Product Sans, sans-serif",
    button: {
      textTransform: "none",
    },
  },
  components: {
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: "6px",
        },
      },
    },
  },
});
