import { useMutation } from "@apollo/client";
import {
  Button,
  Grid,
  Input,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import { useClanContext } from "pages/ClanPage/context";
import { clanChangeBalance } from "pages/ClanPage/mutations/clanChangeBalance";
import { HeaderContanier } from "pages/Dashboard/components/PlayersTable/PlayersTable.styles";
import { useState } from "react";
import { toast } from "react-toastify";
import { numberWithCommas } from "utils/numberWithComas";
import { getToastPromiseConfig } from "utils/toast";
import { GetClan_lookup_clans_clan_treasury_balances } from "../query/__generated__/GetClan";
import { v4 as uuidv4 } from "uuid";

type IAvailableCurrencies = "BUTTER" | "ENRICHED_ORB";
export function ClanCurrenciesTable({
  balances,
}: {
  balances: GetClan_lookup_clans_clan_treasury_balances;
}) {
  const { data: clan, refetch } = useClanContext();
  const [mutation] = useMutation(clanChangeBalance);

  const onApply = (type: IAvailableCurrencies, newValue: number) => {
    const prevAmmout =
      type === "ENRICHED_ORB"
        ? clan.treasury.balances.enrichedOrbs
        : clan.treasury.balances.butter;
    if (
      type === "ENRICHED_ORB" &&
      clan.treasury.balances.enrichedOrbs === newValue
    )
      return;
    if (type === "BUTTER" && clan.treasury.balances.butter === newValue) return;
    toast.promise(
      mutation({
        variables: {
          clanID: clan.id,
          accountType: type,
          amount: newValue - prevAmmout,
          idempotencyKey: uuidv4(),
        },
      }),
      getToastPromiseConfig()
    );
    setTimeout(refetch, 500);
  };

  return (
    <Grid item sx={{ mt: 2 }}>
      <TableContainer component={Paper} sx={{ p: 1, boxSizing: "border-box" }}>
        <HeaderContanier>
          <Typography align="center" color="rgba(119, 119, 119, 1)">
            Currencies
          </Typography>
        </HeaderContanier>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Amount</TableCell>
              <TableCell>New Amount</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell>Enriched orbs</TableCell>
              <TableCell sx={{ textAlign: "end" }}>
                {numberWithCommas(balances.enrichedOrbs)}
              </TableCell>
              <ActionCell type="ENRICHED_ORB" onApply={onApply} />
            </TableRow>
            <TableRow
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell>Butter</TableCell>
              <TableCell sx={{ textAlign: "end" }}>
                {numberWithCommas(balances.butter)}
              </TableCell>
              <ActionCell type="BUTTER" onApply={onApply} />
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
}

export const ActionCell = ({
  onApply,
  type,
}: {
  onApply: (type: IAvailableCurrencies, delta: number) => void;
  type: IAvailableCurrencies;
}) => {
  const [number, setNumber] = useState(0);
  return (
    <TableCell sx={{ display: "flex", gap: 1 }}>
      <Input
        type="number"
        value={number}
        onChange={(e: any) => setNumber(e.target.value)}
        inputProps={{ min: 0 }}
        sx={{ width: 100 }}
      />
      <Button variant="contained" onClick={() => onApply(type, +number)}>
        Set
      </Button>
    </TableCell>
  );
};
