import { useState, ChangeEvent, useCallback, useEffect } from "react";
import { useLazyQuery } from "@apollo/client";
import { GET_ALL_USERS } from "components/SearchBar/query/getUsers.graphql";
import { debounce } from "lodash";

import { StyledTextField, Container, StyledCloseBtn } from "./SearchBar.styles";
import { GetUsers } from "components/SearchBar/query/__generated__/GetUsers";
import { DropDownMenu } from "./DropDownMenu";
import { SEARCH_CLANS } from "components/SearchBar/query/getClans.graphql";
import { SearchClans } from "components/SearchBar/query/__generated__/SearchClans";
import { Search } from "@mui/icons-material";

export const playerSearchableFields = [
  "username",
  "wallet",
  "email",
  "hashtagSuffix",
  "discord",
  "userId",
];
export const clanSearchableFields = ["name", "id"];
export type FiltersSelection = { [key: string]: boolean }; // actually, only one field will be true at a time

// e.g. ["id", "name", "email"] => {id: false, name: false, email: false}
function getEmptySelection(fields: string[]): FiltersSelection {
  const selection: FiltersSelection = {};
  fields.forEach((f, idx) =>
    idx === 0 ? (selection[f] = true) : (selection[f] = false)
  );
  return selection;
}

// GQL: am I a joke to you?
function filterClans(
  unfilteredClans: SearchClans,
  searchQuery: string,
  filtersSelection: FiltersSelection
): SearchClans {
  if (!unfilteredClans?.lookup.clans.search) return unfilteredClans;
  const neededField = filtersSelection.name ? "name" : "id";
  const searchQueryLowerCase = searchQuery.toLowerCase();

  const clansArray = unfilteredClans.lookup.clans.search;
  const clansArrayFiltered = clansArray.filter((clan: any) =>
    clan[neededField]?.toLowerCase()?.includes(searchQueryLowerCase)
  );
  return {
    lookup: {
      clans: { search: clansArrayFiltered, __typename: "ClansLookup" },
      __typename: "Lookup",
    },
  };
}

export const SearchBar = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [isActive, setFocusActive] = useState(false); // used to hide dropdown when clicking outside of it

  const [playerFiltersSelection, setPlayerFiltersSelection] =
    useState<FiltersSelection>(() => getEmptySelection(playerSearchableFields));
  const [clanFiltersSelection, setClanFiltersSelection] =
    useState<FiltersSelection>(() => getEmptySelection(clanSearchableFields));

  const [getPlayers, { data: users, loading }] =
    useLazyQuery<GetUsers>(GET_ALL_USERS);
  const [getUnfilteredClans, { data: unfilteredClans, loading: clansLoading }] =
    useLazyQuery(SEARCH_CLANS);

  const getUsersWithSelectedFilter = (search: string) => {
    const variables: { [key: string]: string } = {};
    playerSearchableFields.forEach((field) => {
      variables[field] = playerFiltersSelection[field] ? search : "";
    });
    console.log("val", variables );
    getPlayers({ variables });
  };

  const getClansWithSelectedFilter = (search: string) => {
    // we're trying to get filtered clans (like we're getting filtered users just fine), but our
    // backend can't into filtering clans, so we have to filter them ourselves with filterClans()
    getUnfilteredClans({ variables: { keyword: search } });
  };

  const clans = filterClans(unfilteredClans, searchQuery, clanFiltersSelection);
  console.log("playerFiltersSelection", playerFiltersSelection);

  const fetch = useCallback(
    debounce((val: string) => {
      getUsersWithSelectedFilter(val);
      getClansWithSelectedFilter(val);
    }, 500),
    [playerFiltersSelection]
  );

  useEffect(() => {
    if (!searchQuery) return;
    getUsersWithSelectedFilter(searchQuery);
    getClansWithSelectedFilter(searchQuery);
  }, [playerFiltersSelection]);

  return (
    <Container>
      <Search
        sx={{
          position: "absolute",
          top: 10,
          left: 15,
          pointerEvents: "none",
          zIndex: 11,
        }}
      />
      <StyledTextField
        value={searchQuery}
        id="search"
        autoComplete="off"
        placeholder="search"
        onFocus={() => setFocusActive(true)}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          setSearchQuery(e.target.value);
          e.target.value && fetch(e.target.value);
        }}
      />
      {searchQuery && <StyledCloseBtn onClick={() => setSearchQuery("")} />}
      {isActive && (
        <DropDownMenu
          {...{
            playerFiltersSelection,
            clanFiltersSelection,
            setPlayerFiltersSelection,
            setClanFiltersSelection,
            setFocusActive,
          }}
          currentSearch={searchQuery}
          setSearch={setSearchQuery}
          loading={loading || clansLoading}
          playersSearchResult={users?.lookup.accounts.search?.items}
          clansSearchResult={clans?.lookup?.clans.search}
        />
      )}
    </Container>
  );
};
