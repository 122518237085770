import { useMutation } from "@apollo/client";
import { Autocomplete, Button, Dialog, Paper, TextField } from "@mui/material";
import { Box } from "@mui/system";
import { useClanContext } from "pages/ClanPage/context";
import { clanKickMember } from "pages/ClanPage/mutations/clanKickMember";
import { GetClan_lookup_clans_clan_members } from "pages/ClanPage/query/__generated__/GetClan";
import { useState } from "react";
import { toast } from "react-toastify";
import { getToastPromiseConfig } from "utils/toast";

export function DialogKickClanMember({
  onClose,
  members,
}: {
  onClose: () => void;
  members: GetClan_lookup_clans_clan_members[];
}) {
  const [kickMember] = useMutation(clanKickMember);
  const { refetch } = useClanContext();
  const [member, setMember] =
    useState<GetClan_lookup_clans_clan_members | null>(null);

  const onClick = () => {
    if (!member) return;
    toast.promise(
      kickMember({
        variables: { id: member?.account.userId },
      }),
      getToastPromiseConfig()
    );
    setTimeout(refetch, 500);
    onClose();
  };
  return (
    <Dialog
      open={true}
      style={{ backdropFilter: "blur(5px)" }}
      onClose={onClose}
    >
      <Box
        component={Paper}
        p={5}
        display="flex"
        flexDirection="column"
        rowGap={2}
      >
        <Autocomplete
          style={{ width: 400 }}
          value={member}
          options={members}
          onChange={(e: any, value) => setMember(value)}
          getOptionLabel={(option: GetClan_lookup_clans_clan_members) =>
            option.account.ident.username + "#" + option.account.ident.suffix
          }
          renderInput={(params) => (
            <TextField
              {...params}
              label="Select clan member"
              variant="standard"
            />
          )}
        />
        <Button variant="contained" onClick={onClick} disabled={!member}>
          Apply
        </Button>

        <Button onClick={onClose}>Cancel</Button>
      </Box>
    </Dialog>
  );
}
