import { useMutation } from "@apollo/client";
import { GearConfigCreateQGL } from "./gearConfigCreate.graphql";
import {
  GearConfigCreateVariables,
  GearConfigCreate_gearConfigCreate,
} from "./__generated__/GearConfigCreate";

export const useGearConfigCreate = () => {
  const [gearConfigCreate, { loading, error, data }] = useMutation<
  GearConfigCreate_gearConfigCreate,
  GearConfigCreateVariables
  >(GearConfigCreateQGL);

  return {
    gearConfigCreate,
    loading,
    data,
    error,
  };
};
