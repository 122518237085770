import { gql } from "@apollo/client";

export const clanChangeBalance = gql`
  mutation offchainWalletsChangeBalance(
    $clanID: UUID!
    $idempotencyKey: UUID! # random uuid
    $amount: Int! # may be negative
    $accountType: String! #ORB, ENRICHED_ORB, BUTTER
  ) {
    offchainWalletsChangeBalance(
      input: {
        holderID: $clanID
        idempotencyKey: $idempotencyKey
        amount: $amount
        accountType: $accountType
      }
    ) {
      id
      name
    }
  }
`;
