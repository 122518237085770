import { gql } from "@apollo/client";

export const inventoryDeleteAccountItem = gql`
  mutation InventoryDeleteAccountItem($itemId: UUID!, $userId: UUID!) {
    inventoryDeleteAccountItem(input: { itemID: $itemId, userID: $userId }) {
      ... on GenericResult {
        ok
      }
    }
  }
`;
