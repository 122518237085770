import React, { useState } from "react";
import { DialogTitleWithCross } from "components/DialogTitleWithCross";
import { Button, Dialog, DialogContent, Grid } from "@mui/material";
import { FormikProvider, useFormik } from "formik";
import { TextField } from "components/formik/TextField";
import { DatePicker } from "components/formik/DatePicker";
import { useMutation } from "@apollo/client";
import { banUserMutation } from "pages/UserProfile/mutations/banUser.graphql";
import { queryGetUser } from "pages/UserProfile/query/getUser.graphql";
import { useUserProfileContext } from "pages/UserProfile/context";
import moment, { Moment } from "moment";
import { FeedbackModal } from "components/FeedbackModal";
import * as Yup from "yup";

type BanModalProps = {
  onClose: () => void;
};

type FormValues = {
  reason: string;
  displayReason?: string;
  startsAt: Moment;
  expiresAt: Moment;
};

const banValidationSchema = Yup.object().shape({
  reason: Yup.string().min(2, "Too Short!").required("Required"),
  displayReason: Yup.string().min(2, "Too Short!").required("Required"),
});

export const BanModal = ({ onClose }: BanModalProps) => {
  const { data } = useUserProfileContext();

  const [banUser, { loading: banUserLoading }] = useMutation(banUserMutation, {
    refetchQueries: [{ query: queryGetUser, variables: { id: data.userId } }],
  });
  const { profileObj } = JSON.parse(sessionStorage.getItem("auth") || "{}");

  const [savedSuccessfully, setSavedSuccessfully] = useState(false);
  const handleSubmit = (values: FormValues) => {
    banUser({
      variables: {
        adminId: profileObj?.email,
        userId: data.userId,
        reason: values.reason,
        displayReason: values.displayReason,
        startsAt: values.startsAt,
        expiresAt: values.expiresAt,
        isAuto: false,
      },
    }).then(() => {
      setSavedSuccessfully(true);
    });
  };

  const formik = useFormik({
    validationSchema: banValidationSchema,
    onSubmit: handleSubmit,
    initialValues: {
      startsAt: moment(),
      expiresAt: moment(),
      reason: "",
    },
  });

  if (savedSuccessfully) {
    return (
      <FeedbackModal
        onClose={onClose}
        label="Successfully saved"
        buttonLabel="Ok"
      />
    );
  }

  return (
    <Dialog fullWidth maxWidth="sm" open onClose={onClose}>
      <DialogTitleWithCross onClose={onClose}>Ban player</DialogTitleWithCross>
      <DialogContent>
        <FormikProvider value={formik}>
          <form onSubmit={formik.handleSubmit}>
            <Grid container direction="column" spacing={2}>
              <Grid item container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    margin="dense"
                    name="reason"
                    label="Reason"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    margin="dense"
                    name="displayReason"
                    label="Displayed reason"
                    fullWidth
                  />
                </Grid>

                <Grid item xs={6}>
                  <DatePicker
                    textFieldProps={{ fullWidth: true }}
                    name="startsAt"
                    label="Starts at"
                  />
                </Grid>

                <Grid item xs={6}>
                  <DatePicker
                    textFieldProps={{ fullWidth: true }}
                    name="expiresAt"
                    label="Expires at"
                  />
                </Grid>
              </Grid>
              <Grid item container justifyContent="flex-end">
                <Button
                  type="submit"
                  color="secondary"
                  size="large"
                  variant="contained"
                >
                  Ban player
                </Button>
              </Grid>
            </Grid>
          </form>
        </FormikProvider>
      </DialogContent>
    </Dialog>
  );
};
