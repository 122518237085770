import { gql } from "@apollo/client";

export const GET_MAKEOVERS = gql`
  query GetMakeovers($makeoverId: UUID, $authorId: UUID, $modelIds: [String!], $name: String, $statuses: [MakeoverStatus!], $limit: Int, $pageToken: String) {
    lookup {
      ugc {
        makeovers {
          search(input: {
            filter: { makeoverId: $makeoverId, authorId: $authorId, modelIds: $modelIds, name: $name, statuses: $statuses }
            pagination: { limit: $limit, pageToken: $pageToken }
          }) {
            makeovers {
              id
              authorId
              modelId
              name
              status
              price
              createdAt
              invalidityReason
              previewModel
              previewIcon 
              resources {
                address
                name
              }
            }
            nextPageToken
          }
        }
      }
    }
  }
`;
