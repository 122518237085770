import { gql } from "@apollo/client";

export const sgsSetTokenLevel = gql`
  mutation sgsSetTokenLevel($tokenId: String!, $level: Int!) {
    cgsSetTokenLevel(input: { tokenID: $tokenId, level: $level }) {
      ... on CGSTokenLevelInfo {
        level
        points
      }
      ... on ValidationErrors {
        errors {
          message
          field
        }
      }
    }
  }
`;
