import { gql } from "@apollo/client";

export const banUserMutation = gql`
  mutation bansBanUser(
    $adminId: String!
    $userId: UUID!
    $reason: String!
    $displayReason: String!
    $startsAt: Time!
    $expiresAt: Time!
    $isAuto: Boolean!
  ) {
    bansBanUser(
      input: {
        adminID: $adminId
        userID: $userId
        reason: $reason
        displayReason: $displayReason
        isAuto: $isAuto
        expiresAt: $expiresAt
        startsAt: $startsAt
      }
    ) {
      ok
    }
  }
`;
