import React from "react";
import { Grid, Paper, Typography } from "@mui/material";
import { GetUser_lookup_offchainWallets_allHolderAccounts } from "pages/UserProfile/query/__generated__/GetUser";
import { CurrenciesTable } from "pages/UserProfile/components/Inventory/components/CurrenciesTable";
import { Box } from "components/Box";

type ShardsCardProps = {
  label: string;
  currencies: GetUser_lookup_offchainWallets_allHolderAccounts[];
  onItemSelect: (item: string) => void;
};

export const CurrenciesCard = ({
  label,
  currencies,
  onItemSelect,
}: ShardsCardProps) => {
  return (
    <Grid key={label} mt={3} item xs={7}>
      <Paper sx={{ p: 1 }}>
        <Grid container alignItems="center">
          <Grid item xs={4}>
            <Typography align="center" color="rgba(119, 119, 119, 1)">
              {label}{" "}
              <Typography color="black" fontWeight={700} component="span">
                {" "}
                ( {currencies.length} )
              </Typography>
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Box h={40} />
          </Grid>
          <Grid mt={2} item xs={12}>
            <CurrenciesTable
              onItemSelect={onItemSelect}
              currencies={currencies}
            />
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};
