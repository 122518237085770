import { gql } from "@apollo/client";

export const gearsGenerationCheckMatches = gql`
  mutation gearsGenerationCheckMatches(
    $nameCounterID: String!
    $season: String!
    $dropType: String!
    $minMintLevel: Int!
    $alignmentValues: [String!]!
    $setValues: [String!]!
    $typeValues: [String!]!
    $rarityValues: [String!]!
  ) {
    gearsGenerationCheckMatches(
      input: {
        nameCounterID: $nameCounterID
        season: $season
        dropType: $dropType
        minMintLevel: $minMintLevel
        alignmentValues: $alignmentValues
        setValues: $setValues
        typeValues: $typeValues
        rarityValues: $rarityValues
      }
    ) {
      configIDs
    }
  }
`;
