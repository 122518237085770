import { gql } from "@apollo/client";

export const SEARCH_CLANS = gql`
  query SearchClans($keyword: String!) {
    lookup {
      clans {
        search(input: { limit: 10, keyword: $keyword }) {
          id
          name
          slug
          description
          size
          capacity
          xp {
            daily
            seasonal
          }
          links {
            discord
            twitter
          }
          joinType
        }
      }
    }
  }
`;
