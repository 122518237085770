import React from "react";
import { useField } from "formik";
import {
  DateTimePicker as MUIDateTimePicker,
  DateTimePickerProps as MUIDateTimePickerProps,
} from "@mui/x-date-pickers/DateTimePicker";
import TextField from "@mui/material/TextField";
import { TextFieldProps } from "@mui/material/TextField/TextField";

export type DatePickerProps = Omit<
  MUIDateTimePickerProps<any, any>,
  "onChange" | "renderInput" | "value"
> & {
  name: string;
  label?: string;
  textFieldProps?: TextFieldProps;
};

export const DatePicker = ({
  name,
  label,
  textFieldProps,
  ...rest
}: DatePickerProps) => {
  const [field, , { setValue }] = useField(name);
  return (
    <MUIDateTimePicker
      ampm={false}
      {...rest}
      label={label}
      onChange={(value) => {
        setValue(value);
      }}
      value={field.value}
      renderInput={(params) => <TextField {...params} {...textFieldProps} />}
    />
  );
};
