import {
  Box,
  Button,
  Drawer,
  FormHelperText,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { generateSkinData } from "./contstants";
import { SkinsView } from "./SkinsView";
import { FieldArray, useFormikContext, useField } from "formik";
import { SelectWrapper } from "./components/SelectWrapper";
import { TextFieldWrapper } from "./components";
import { IFormWeapon } from "./types";
import { useGetAvailableSkinData } from "./hooks/useGetAvailableSkinData";
import { BtnsContainer } from "./styles";

export const SkinsDrawer = ({ availableSeasons, availableWeaponTypes }: any) => {
  const [show, setShow] = useState(false);
  const { values, handleChange } = useFormikContext<IFormWeapon>();
  const [, materialsMeta] = useField("materials");

  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="start">
        <div>
          <Typography variant="h6" gutterBottom component="div">
            Skins
          </Typography>
          {materialsMeta.error?.length ? (
            <FormHelperText error={Boolean(materialsMeta.error?.length)}>
              Fill in the required fields
            </FormHelperText>
          ) : null}
        </div>
        <Button variant="outlined" size="small" onClick={() => setShow(true)}>
          Customize skins
        </Button>
      </Box>

      <Drawer
        anchor="right"
        open={show}
        onClose={() => setShow(false)}
        title="Weapon Config Render"
      >
        <Box width={600} padding={4}>
          <Typography variant="h6" gutterBottom component="div" mb={2}>
            Skins
          </Typography>

          <FieldArray
            name="skins"
            render={({ remove, push, pop }) => {
              return (
                <div>
                  <Typography variant="body2" gutterBottom>
                    Skins:
                  </Typography>
                  {values?.skins?.map((_, index) => {
                    return <SkinsView key={index} index={index} availableSeasons={availableSeasons} availableWeaponTypes={availableWeaponTypes} />;
                  })}
                  <BtnsContainer>
                    <Button
                      variant="contained"
                      size="small"
                      onClick={() => push(generateSkinData())}
                    >
                      Add Skin
                    </Button>
                    <Button
                      variant="contained"
                      size="small"
                      onClick={() => pop()}
                    >
                      Remove Skin
                    </Button>
                  </BtnsContainer>
                </div>
              );
            }}
          />
        </Box>
      </Drawer>
    </>
  );
};
