import { useMutation } from "@apollo/client";
import { GearConfigUpdateQGL } from "./gearConfigUpdate.graphql";
import {
  GearConfigUpdate,
  GearConfigUpdateVariables,
} from "./__generated__/GearConfigUpdate";

export const useGearConfigUpdate = () => {
  const [gearUpdateConfig, { loading, error }] = useMutation<
  GearConfigUpdate,
  GearConfigUpdateVariables
  >(GearConfigUpdateQGL);

  return {
    gearUpdateConfig,
    loading,
    error,
  };
};
