import { GetUser_lookup_accounts_resolve_overview_inventory_items as InventoryItem } from "pages/UserProfile/query/__generated__/GetUser";
import { TableColumn } from "components/SortCell/types";
import { Box } from "@mui/material";
import React from "react";
import { ActionCell } from "./components/ActionCell";

type GetColumnsParams = {
  onItemSelect: (item: string) => void;
};

export const getColumns = ({
  onItemSelect,
}: GetColumnsParams): TableColumn<InventoryItem>[] => [
  {
    key: "icon",
    label: "",
    headerCellProps: {
      width: 30,
    },
    renderCell: (row) => (
      <Box
        width={30}
        component="img"
        sx={{
          borderRadius: 1,
        }}
        src={row?.overview?.content?.asset?.icon || ""}
      />
    ),
  },
  {
    key: "name",
    label: "Name",
    getOrderBy: (data) => data.overview.name,
    headerCellProps: {
      align: "left",
    },
    rowCellProps: {
      align: "left",
    },
    renderCell: (row) => row.overview.name,
  },
  {
    key: "level",
    label: "Level",
    getOrderBy: (data) => data.overview.progress?.level || -1,
    headerCellProps: {
      align: "left",
    },
    rowCellProps: {
      align: "left",
    },
    renderCell: (row) => row.overview.progress?.level,
  },
  {
    key: "action",
    label: "",
    renderCell: (row) => <ActionCell onItemSelect={onItemSelect} row={row} />,
  },
];
