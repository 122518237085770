import { gql } from "@apollo/client";

export const getListMintableItemConfigGraphql = gql`
  query GetListMintableItemConfigGraphql(
    $query: String
    $limit: Int
    $pageToken: String
    $season: String
  ) {
    lookup {
      cgsAdmin {
        weapons {
          search(
            input: {
              query: $query
              season: $season
              pagination: { limit: $limit, pageToken: $pageToken }
            }
          ) {
            configs {
              id
              skinImageUrl
              skins {
                id
                name
              }
            }
            nextPageToken
          }
        }
      }
    }
  }
`;
