import React from "react";
import { ReachField } from "../ReachField";
import MilitaryTechIcon from "@mui/icons-material/MilitaryTech";
import { Button, Grid, Typography } from "@mui/material";
import { useUserProfileContext } from "pages/UserProfile/context";
import { useFormik, FormikProvider } from "formik";
import { useMutation } from "@apollo/client";
import { gameAccountSetExperienceMutation } from "pages/UserProfile/mutations/gameAccountSetExperience.graphql";
import { queryGetUser } from "pages/UserProfile/query/getUser.graphql";
import { toast } from "react-toastify";
import { getToastPromiseConfig } from "utils/toast";

interface FormFields {
  xp: number;
}

interface ExperienceFormProps {
  onSuccess: () => void;
}

export const ExperienceForm = ({ onSuccess }: ExperienceFormProps) => {
  const { data } = useUserProfileContext();
  const [updateUser] = useMutation(gameAccountSetExperienceMutation, {
    refetchQueries: [{ query: queryGetUser, variables: { id: data.userId } }],
  });
  const handleSubmit = async (values: FormFields) => {
    toast
      .promise(
        updateUser({
          variables: {
            userId: data.userId,
            experience: values.xp,
          },
        }),
        getToastPromiseConfig()
      )
      .then(onSuccess);
  };

  const formik = useFormik({
    onSubmit: handleSubmit,
    initialValues: {
      xp: data.overview.gameAccount.xp,
    },
  });
  return (
    <FormikProvider value={formik}>
      <form onSubmit={formik.handleSubmit}>
        <ReachField
          name="xp"
          label="EXPERIENCE"
          icon={<MilitaryTechIcon />}
          subLabel={
            <Typography fontSize={14} color="rgba(119, 119, 119, 1)">
              Current experience {data.overview.gameAccount.xp}{" "}
              <Typography
                fontSize={14}
                color="black"
                fontWeight={700}
                component="span"
              >
                (Level: {data.overview.gameAccount.level})
              </Typography>
            </Typography>
          }
          initialValue={data.overview.gameAccount.xp}
        />

        <Grid item container justifyContent="flex-end" spacing={1}>
          <Grid item>
            <Button
              type="submit"
              color="secondary"
              size="large"
              variant="contained"
            >
              Save experience
            </Button>
          </Grid>
        </Grid>
      </form>
    </FormikProvider>
  );
};
