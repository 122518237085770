import React, { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { GetUser_lookup_offchainWallets_allHolderAccounts } from "pages/UserProfile/query/__generated__/GetUser";
import { getColumns } from "./consts";
import { useLocalSort } from "utils/mui/useLocalSort";
import { SortCell } from "components/SortCell";
import TableCell from "@mui/material/TableCell";

type ICurrenciesTable = {
  currencies: GetUser_lookup_offchainWallets_allHolderAccounts[];
  onItemSelect: (item: string) => void;
};

export const CurrenciesTable = ({ currencies }: ICurrenciesTable) => {
  const [amount, setAmount] = useState({ ORB: 0, ENRICHED_ORB: 0, BUTTER: 0 });
  const handleSetAmount = (type: string, number: number) => {
    setAmount({ ...amount, [type]: number });
  };

  const columns = getColumns({ amount, handleSetAmount });
  const { sortedData, handleRequestSort, order, getOrderBy } = useLocalSort({
    items: currencies,
  });
  console.log("sortedData", sortedData);

  return (
    <TableContainer sx={{ height: 405 }} component={Paper}>
      <Table size="small" aria-label="simple table">
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <SortCell
                key={column.key}
                column={column}
                getOrderBy={getOrderBy}
                order={order}
                handleRequestSort={handleRequestSort}
              />
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedData.map(
            (item) =>
              item?.id && (
                <TableRow key={item.id}>
                  {columns.map((el) => (
                    <TableCell {...el.rowCellProps} key={el.key}>
                      {el.renderCell(item)}
                    </TableCell>
                  ))}
                </TableRow>
              )
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
