import { Translate } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import { transpile } from "typescript";

export const useStyles = makeStyles({
  collectionItem: {
    "padding": "8px",
    "marginBottom": "5px",
    "backgroundColor": "#009999",
    "display": "flex",
    "justifyContent": "space-between",
    "width": "500px",
    "cursor": "pointer",

    "&:hover": {
      backgroundColor: "#33CCCC",
    },
  },
  collectionTitle: {
    display: "flex",
    justifyContent: "space-between",
    width: "260px",
  },
  continer: {
    "&:first-child": {
      marginRight: "20px",
    },
  },
  input: {
    "width": "200px",
    "&.MuiTextField-root": {
      marginRight: "10px",
      marginBottom: "10px",
    },
  },
  imageItem: {
    padding: "5px",
    marginBottom: "5px",
    backgroundColor: "lightblue",
    position: "relative",
  },
  imageTitle: {
    padding: "4px",
  },
  delIcon: {
    position: "absolute",
    right: "7px",
    top: "10px",
    cursor: "pointer",
  },
  editIcon: {
    position: "absolute",
    right: "40px",
    top: "10px",
    cursor: "pointer",
  },
  modalContainer: {
    position: "fixed",
    top: "50px",
    left: "35%",
    width: "400px",
    backgroundColor: "#fff",
    border: "1px solid black",
    padding: "35px",
    height: "600px",
    overflowY: "scroll",
  },
  modalInput: {
    "&.MuiTextField-root": {
      width: "100%",
      marginBottom: "15px",
    },
  },
});
