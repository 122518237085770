import { client } from "apolloClient";
import {
  getConfigs as GetConfigsRes,
  getConfigs_lookup_itemConfigs_resolve_MintableItemConfig,
} from "pages/UserProfile/query/__generated__/getConfigs";
import { getConfigsQuery } from "pages/UserProfile/query/getConfigs.graphql";
import { ItemType } from "graphql-global-types/types";

export const useGetLoadWeapons = () => {
  return async (search: string) => {
    const { data } = await client.query<GetConfigsRes>({
      query: getConfigsQuery,
      variables: {
        query: search,
        itemType: ItemType.CONFIGS, ///здесь было WEAPONS
      },
    });
    const res = data.lookup.itemConfigs.resolve!.map((el) => {
      const typedEl =
        el as getConfigs_lookup_itemConfigs_resolve_MintableItemConfig;
      return {
        value: typedEl.configID,
        label: typedEl.configID,
      };
    });
    return res;
  };
};
