import moment, { Moment } from "moment";

export function formatDateForServer(unix: number) {
  const m = moment(unix * 1000).utc();
  return m.format("YYYY-MM-DDTHH:mm:00.0Z");
}

export function validateRange(unixStart: number, unixEnd: number) {
  const unixNow = +new Date() / 1000;
  if (unixEnd < unixStart) return "Start date must precede end date";
  if (unixEnd < unixNow) return "End date cannot be in the past";
  return null;
}

export function getInitialDate() {
  let unix = +new Date() / 1000;
  unix = unix - (unix % 60); // chop off seconds
  return unix;
}

export function formatPeriod(start: Moment, end: Moment) {
  const sameYear = start.get("year") === end.get("year");
  const conditionalYear = sameYear ? "" : "YYYY ";
  return (
    start.utc().format("YYYY MMM DD HH:mm") +
    " – " +
    end.utc().format(conditionalYear + "MMM DD HH:mm")
  );
}
