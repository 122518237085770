import { useLazyQuery } from "@apollo/client";
import { GET_MAKEOVERS } from "pages/Makeovers/query/getMakeovers.graphql";
import { useEffect, useState } from "react";
import { MakeoversTable } from "./components/MakeoversTable";
import { SearchField, State } from "./components/SearchField/SearchBar";
import { GetMakeovers, GetMakeovers_lookup_ugc_makeovers_search_makeovers } from "./query/__generated__/GetMakeovers";
import InfiniteScroll from "react-infinite-scroll-component";
import { Filters } from "./components/FIlters/Filters";
import { MakeoverStatus } from "graphql-global-types/types";

const queryLimit = 40;

export const defaultState = {
    makeoverId: "00000000-0000-0000-0000-000000000000",
    authorId: "00000000-0000-0000-0000-000000000000",
    modelIds: [],
    name: "",
    statuses: [],
    limit: queryLimit,
    pageToken: ""
};

export const Makeovers = () => {

    const [state, setState] = useState<State>(defaultState);

    const [getMakeovers, { data }] = useLazyQuery<GetMakeovers>(GET_MAKEOVERS);

    const makeovers = data?.lookup.ugc?.makeovers?.search.makeovers;

    const [makeoversList, setMakeovers] = useState(makeovers || []);
    const [isNext, setNext] = useState("");

    useEffect(() => {
        if(data?.lookup.ugc?.makeovers?.search.nextPageToken){
            if(data?.lookup.ugc?.makeovers?.search.nextPageToken === isNext){
                setNext("");
            } else {
                if(makeovers && makeovers?.length < queryLimit){
                    setNext("");
                }else {
                    setNext(data?.lookup.ugc?.makeovers?.search.nextPageToken);
                }
            }
        }
      }, [data?.lookup.ugc?.makeovers?.search.nextPageToken]);

    const loadMoreMakeovers = () => {
        getMakeovers({
            variables: {...state, pageToken: data?.lookup.ugc?.makeovers?.search.nextPageToken}
          }).then(({data}) => setMakeovers((prev) => [ ...prev, ...data?.lookup.ugc?.makeovers?.search.makeovers as GetMakeovers_lookup_ugc_makeovers_search_makeovers[]]));
    };

    useEffect(() => {
        getMakeovers({ variables: state })
        .then(({data}) => setMakeovers((prev) => [ ...prev, ...data?.lookup.ugc?.makeovers?.search.makeovers as GetMakeovers_lookup_ugc_makeovers_search_makeovers[] || []]));
    }, []);

    const searchWithFilter = (e: React.MouseEvent<HTMLDivElement>) => {
        const currentFilter = (e.target as HTMLElement).innerText;
        const selectedStatuses = currentFilter === "All" ? Object.keys(MakeoverStatus)
        .filter( item => item !== MakeoverStatus.Rejected && item !== MakeoverStatus.Unknown) : [currentFilter];

        setState((prev) => ({...prev, statuses: selectedStatuses}));
        getMakeovers({ variables: {...state, statuses: selectedStatuses} })
        .then(({data}) => setMakeovers(() => data?.lookup.ugc?.makeovers?.search.makeovers as GetMakeovers_lookup_ugc_makeovers_search_makeovers[]));
    };


    return (<>
            <SearchField
                getData={getMakeovers}
                setMakeovers={setMakeovers}
                setState={setState}
                state={state}
            />
            <Filters searchWithFilter={searchWithFilter} />
            <InfiniteScroll
                dataLength={makeoversList?.length}
                next={() => loadMoreMakeovers()}
                hasMore={!!isNext}
                loader={<h4>empty</h4>}
                endMessage={
                    <p style={{ textAlign: "center" }}>
                      You have seen it all
                    </p>
                  }
                >
             <MakeoversTable data={makeoversList} />
            </InfiniteScroll>
        </>)
    ;
};